import type { ICampaignPreview } from "../types/campaign";
import type { IDocumentData, IDocumentNode } from "./type";

export const hasContents = (documentData?: IDocumentData | null) => {
  if (!documentData || !documentData.documents) {
    return false;
  }

  return documentData.documents.some((doc) => hasContent(doc));
};

export const hasPreviews = (
  documentData?: IDocumentData | null,
  preview?: ICampaignPreview | null
) => {
  if (
    !documentData ||
    !documentData.documents ||
    !preview ||
    !preview.documentImages
  ) {
    return false;
  }

  return documentData.documents.some(
    (doc) =>
      hasContent(doc) &&
      preview.documentImages?.some((img) => img.id === doc.id)
  );
};

export const getPreviews = (
  documentData?: IDocumentData | null,
  preview?: ICampaignPreview | null
) => {
  if (!hasPreviews(documentData, preview)) {
    return [];
  }

  const documents = documentData?.documents.filter((doc) => hasContent(doc));

  return (
    preview?.documentImages?.filter((img) =>
      documents?.some((doc) => doc.id === img.id)
    ) || []
  ).sort((a, b) => (a.device === "DESKTOP" && b.device === "MOBILE" ? 1 : -1));
};

export const hasContent = (
  document?: IDocumentNode | null
): document is IDocumentNode => {
  if (!document) {
    return false;
  }

  return document.children && document.children.length > 0;
};
