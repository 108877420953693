import shortUUID from "short-uuid";
import type { IVideoNode, PartialNode } from "../type";

const createVideoNode = (data?: PartialNode<IVideoNode>): IVideoNode => {
  return {
    // 기본값
    type: "VIDEO",
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    position: { type: "RELATIVE" },
    reactions: [],
    name: "비디오 영역",

    width: {
      value: 100,
      unit: "%",
    },

    locked: false,
    visible: true,

    strokes: [],
    strokeWeight: 0,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],
    cornerRadius: {
      bl: { unit: "px", value: 0 },
      br: { unit: "px", value: 0 },
      tl: { unit: "px", value: 0 },
      tr: { unit: "px", value: 0 },
    },

    fills: [],

    effects: [],
    alignHorizontal: "LEFT",

    videoInfo: {
      type: "VIDEO",
      fileName: "",
      originalFileName: "",
      originalFileType: "",
      path: "",
      thumbnailPath: "",
      thumbnailType: "",
    },
    muted: true,
    loop: false,
    controls: false,
    autoplay: true,

    ...data,
  };
};

export default createVideoNode;
