import { gql } from "@apollo/client";

export const ORGANIZATION_FIELDS = gql`
  fragment OrganizationFields on Organization {
    id
    slug
    name
    plan
    planStartAt
    planEndAt
    planOption
    billingCycle
    firstBilledAt
    nextBilledAt
    scheduledChangePlan
    scheduledChangeAt
    scheduledChangeBillingCycle
    paymentManager
    subscribeStatus
    subscribeStatusAt
    status
    billingFailCount
    onboardStep
    createdAt
    updatedAt
  }
`;

export const GET_ORGANIZATIONS = gql`
  ${ORGANIZATION_FIELDS}
  query GetOrganizations($organizationSlug: [String]) {
    organizations(organizationSlug: $organizationSlug) {
      ...OrganizationFields
      userRole
    }
  }
`;

export const GET_ORGANIZATION = gql`
  ${ORGANIZATION_FIELDS}
  query GetOrganization($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      ...OrganizationFields
      userRole
    }
  }
`;

export const GET_ORGANIZATION_WITH_USERS = gql`
  ${ORGANIZATION_FIELDS}
  query GetOrganizationWithUsers($organizationId: ID!, $userLimit: Int) {
    organization(organizationId: $organizationId) {
      ...OrganizationFields
      userRole
      userCount
      organizationUsers(limit: $userLimit) {
        user {
          id
          name
          email
          image
        }
        role
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_FIRST_OWNER = gql`
  query FirstOwner($organizationId: ID!) {
    firstOwner(organizationId: $organizationId) {
      name
      email
      phone
    }
  }
`;

export const GET_REFERRAL_CODE_USERS = gql`
  query ReferralCodeUsers($masking: Boolean = true) {
    referralCodeUsers(masking: $masking) {
      name
      slug
      userEmail
      userName
      plan
      billingCycle
      amount
      firstBilledAt
      billingDuration
      settlementAmount
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  ${ORGANIZATION_FIELDS}
  mutation CreateOrganization($object: OrganizationInput!) {
    createOrganization(object: $object) {
      ...OrganizationFields
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  ${ORGANIZATION_FIELDS}
  mutation UpdateOrganization(
    $organizationId: ID!
    $object: OrganizationInput!
  ) {
    updateOrganization(organizationId: $organizationId, object: $object) {
      ...OrganizationFields
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($organizationId: ID!) {
    deleteOrganization(organizationId: $organizationId) {
      id
    }
  }
`;

export const ORGANIZATION_MONTHLY_USER = gql`
  query OrganizationMonthlyUser($organizationId: ID!) {
    organizationMonthlyUser(organizationId: $organizationId) {
      firstEventAt
      count
      collectPeriod
    }
    organizationUsage(organizationId: $organizationId) {
      siteCount
      userCount
      campaignPopupCount
      campaignGroupPopupCount
      campaignBannerCount
      campaignInstagramCount
      campaignTotalCount
      formEventCount
    }
  }
`;

export const ORGANIZATION_USAGE = gql`
  query OrganizationUsage($organizationId: ID!) {
    organizationUsage(organizationId: $organizationId) {
      siteCount
      userCount
      campaignPopupCount
      campaignGroupPopupCount
      campaignBannerCount
      campaignInstagramCount
      campaignTotalCount
      formEventCount
    }
  }
`;
