import React from "react";

interface IXIconProps extends React.SVGAttributes<SVGElement> {
  width?: number;
  height?: number;
}

const V2XIcon: React.FC<IXIconProps> = ({
  width = 16,
  height = 16,
  fill = "#E3E5E1",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1866 2.81343C12.9262 2.55308 12.5041 2.55308 12.2438 2.81343L8.00023 7.05697L3.75708 2.81381C3.49673 2.55346 3.07462 2.55346 2.81427 2.81381C2.55392 3.07416 2.55392 3.49627 2.81427 3.75662L7.05742 7.99978L2.81391 12.2433C2.55356 12.5036 2.55357 12.9257 2.81391 13.1861C3.07426 13.4464 3.49638 13.4464 3.75672 13.1861L8.00023 8.94259L12.2434 13.1858C12.5037 13.4461 12.9259 13.4461 13.1862 13.1858C13.4466 12.9254 13.4466 12.5033 13.1862 12.2429L8.94304 7.99978L13.1866 3.75623C13.4469 3.49589 13.4469 3.07378 13.1866 2.81343Z"
        fill={fill}
      />
    </svg>
  );
};

export default React.memo(V2XIcon);
