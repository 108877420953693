import {
  createRecentVersionData,
  createSceneNode,
} from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { IFrameNode, PartialNode } from "../type";

const createFrameNode = (data?: PartialNode<IFrameNode>): IFrameNode => {
  return {
    // 기본값
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    position: { type: "RELATIVE" },
    reactions: [],
    name: "",
    expanded: false,
    width: {
      value: 100,
      unit: "%",
    },
    padding: {
      top: {
        value: 15,
        unit: "px",
      },
      bottom: {
        value: 15,
        unit: "px",
      },
      left: {
        value: 15,
        unit: "px",
      },
      right: {
        value: 15,
        unit: "px",
      },
    },

    locked: false,
    visible: true,

    strokes: [],
    strokeWeight: 0,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    fills: [],

    effects: [],
    alignHorizontal: "LEFT",

    layoutMode: "VERTICAL",
    primaryAxisSizingMode: "AUTO",
    counterAxisSizingMode: "AUTO",

    // 인자값
    ...createRecentVersionData(data),
    children: data?.children?.map(createSceneNode) ?? [],

    // 필수값
    type: "FRAME",
  };
};

export default createFrameNode;
