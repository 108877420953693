import React from "react";

interface IMakeshopIconProps {
  width?: number;
  height?: number;
}

const MakeshopIcon: React.FC<IMakeshopIconProps> = ({
  width = 109,
  height = 23,
}) => {
  return (
    <img
      src="/images/makeshop_logo.png"
      width={width}
      height={height}
      alt="makeshop"
    />
  );
};

export default MakeshopIcon;
