export const DEFUALT_OG_IMAGE_URL = `https://image.codenbutter.com/og/og-placeholder-2.jpg`;

export const DEFAULT_PRIVACY_POLICY =
  "<p>[개인정보보호법] 제15조 법규에 의거하여 OO 주식회사는 고객님의 개인정보 수집 및 이용에 동의를 받고 있습니다. </p><p><br></p><p>개인정보 수집 및 이용에 동의해주세요.</p><p>동의한 분에 한해 이벤트 추첨이 진행됩니다.</p><p><br></p><p><strong>1. 개인정보의 수집 및 이용 목적</strong></p><p>당사가 수집한 개인정보는 다음 목적을 위해 활용합니다.</p><p>&nbsp; 가. 고객 관리: 효율적인 고객 관리를 위한 본인 확인, 개인 식별, 고지사항 전달 등의 업무</p><p>&nbsp; 나. 신규 서비스 개발 및 마케팅, 광고 활용: 신규 서비스 (제품 포함) 및 이벤트 정보 안내, 경품 전달</p><p><strong>2. 개인정보 수집 항목</strong>: 이름(필수), 전화번호(필수)</p><p><strong>3. 개인정보 보유 및 이용 기간</strong>: 참여일로부터 6개월까지</p><p><br></p><p>작성일: 2024.OO.OO</p><p>담당자: OOO</p>";

export const DEFAULT_EVENT_OG_DESCRIPTION = "이벤트를 확인하세요!";
export const DEFAULT_PROMOTION_OG_DESCRIPTION = "클릭하여 이벤트로 이동하세요.";
export const DEFAULT_SURVEY_OG_DESCRIPTION = "설문조사를 확인하세요!";

export const SURVEY_RESULT_COLOR_ARRAY = [
  "#609EFF",
  "#FF6060",
  "#FFC82A",
  "#54DD68",
  "#606775",
  "#A5ACB7",
];
