import { useEffect } from "react";

const kakaoPixelId = process.env.NEXT_PUBLIC_KAKAO_PIXEL_ID || "";

const useKakaoPixel = () => {
  useEffect(() => {
    if (!kakaoPixel) return;
    kakaoPixel(kakaoPixelId)?.pageView();
  }, []);

  const sendKakaoPixelPageView = (tagName?: string) => {
    if (!kakaoPixel) return;
    kakaoPixel(kakaoPixelId)?.pageView(tagName);
  };

  const sendKakaoPixelParticipation = (tagName: string) => {
    if (!kakaoPixel) return;
    kakaoPixel(kakaoPixelId)?.participation(tagName);
  };

  return { sendKakaoPixelPageView, sendKakaoPixelParticipation };
};

export default useKakaoPixel;
