import LoadingButton from "@/components/common/form/loading-button";
import SimpleButton from "@/components/common/form/simple-button";
import Tab from "@/components/common/ui/tab";
import SiteTypeIcon from "@/components/site/site-type-icon";
import ExcelamationMark from "@/components/v2/icon/v2-excelamation-mark";
import { useOrgAndsiteContext } from "@/context";
import { GET_ORGANIZATIONS, UPDATE_ORGANIZATION } from "@/gql/organization";
import { REQUEST_INSTALL_SCRIPT } from "@/gql/site";
import { builderCommonScriptGuideLink, getSiteTypeName } from "@/utils/common";
import { useMutation } from "@apollo/client";
import {
  IRequestInstallScriptMutation,
  IRequestInstallScriptMutationVariables,
  IUpdateOrganizationMutation,
  IUpdateOrganizationMutationVariables,
} from "@shared/common/types/api-private/generated/graphql";
import { Form, Input } from "antd";
import { Copy, ExternalLink } from "lucide-react";
import React, { useCallback, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import FormLabel from "../../auth/form-label";
import V2Tooltip from "../../common/v2-tooltip";
import OnboardingMessage from "./onboarding-message";

interface IWordpressOnboardingScriptProps {
  prefixGtmId: string;
  publicId: string;
  onFinish: () => void;
  initialSiteUrl?: string | null;
  nextText?: string;
  isTeamOnboarding?: boolean;
}

interface INeedsHelpFormValues {
  siteUrl: string;
  id: string;
  password: string;
}

const WordpressOnboardingScript = ({
  publicId,
  onFinish,
  initialSiteUrl,
  nextText = "확인",
  prefixGtmId,
  isTeamOnboarding,
}: IWordpressOnboardingScriptProps) => {
  const [tab, setTab] = useState(1);

  const [form] = Form.useForm<INeedsHelpFormValues>();
  const { currentOrganization } = useOrgAndsiteContext();
  const { currentSite } = useOrgAndsiteContext();

  const [requestInstall, { loading }] = useMutation<
    IRequestInstallScriptMutation,
    IRequestInstallScriptMutationVariables
  >(REQUEST_INSTALL_SCRIPT, {
    onError: (err) => {
      toast(
        err.message ||
          "요청을 보내는 중 오류가 발생했습니다. 잠시 후에 다시 시도해주세요."
      );
    },
  });

  const [updateTeam] = useMutation<
    IUpdateOrganizationMutation,
    IUpdateOrganizationMutationVariables
  >(UPDATE_ORGANIZATION, { refetchQueries: [GET_ORGANIZATIONS] });

  const handleFormFinish = async (val: INeedsHelpFormValues) => {
    const message = `
사이트 주소: ${val.siteUrl}
아이디: ${val.id}
비밀번호: ${val.password}`;
    const ret = await requestInstall({
      variables: { sitePublicId: publicId, message },
    });

    if (ret.data?.requestInstallScript && currentOrganization) {
      toast("설치를 요청하였습니다.");
      if (isTeamOnboarding) {
        await updateTeam({
          variables: {
            organizationId: currentOrganization.id,
            object: { onboardStep: 70 },
          },
        });
      }
      onFinish();
    }
  };

  const handleRequestInstall = useCallback(async () => {
    form.submit();
  }, [form]);

  const NeedsHelpRender = () => {
    return (
      <div className="text-center">
        <div className="flex items-center justify-center gap-1">
          <span className="font-semibold">
            어려우신가요? 코드앤버터에게 맡겨주세요!
          </span>
          <V2Tooltip
            title="스크립트 설치 지원 용도로만 사용되며 모든 계정 정보는 보안을 위해 암호화 처리되어 저장됩니다. (사용 후 파기됩니다)"
            zIndex={99999}
          >
            <div>
              <ExcelamationMark />
            </div>
          </V2Tooltip>
        </div>
        <div className="mt-1 text-cb-gray-500">
          고객님의 워드프레스 운영자 계정을 기입해주시면
          <br />
          근무일 기준 2-3일 내로 설치가 완료됩니다.
        </div>
        <div className="mt-8 text-left">
          <Form
            form={form}
            requiredMark={false}
            layout="vertical"
            initialValues={{ siteUrl: initialSiteUrl, id: "", password: "" }}
            onFinish={handleFormFinish}
          >
            <Form.Item
              name="siteUrl"
              label={<FormLabel label="사이트 주소" required />}
              className="mb-6"
              rules={[
                { required: true, message: "사이트 주소를 입력해주세요." },
              ]}
            >
              <Input
                placeholder="아이디를 입력해주세요."
                className="w-full text-base text-black"
              />
            </Form.Item>
            <Form.Item
              name="id"
              label={<FormLabel label="아이디" required />}
              className="mb-6"
              rules={[
                {
                  required: true,
                  message: "아이디를 입력해주세요.",
                },
              ]}
            >
              <Input
                placeholder="아이디를 입력해주세요."
                className="w-full text-base text-black"
              />
            </Form.Item>

            <Form.Item
              name="password"
              label={<FormLabel label="비밀번호" required />}
              className="mb-6"
              rules={[
                {
                  required: true,
                  message: "비밀번호를 입력해주세요.",
                },
              ]}
            >
              <Input
                placeholder="비밀번호를 입력해주세요."
                className="w-full text-base text-black"
                type="password"
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  return (
    <div className="w-104">
      <OnboardingMessage
        message={
          <div>
            <div className="text-center">사이트에 스크립트를 추가하여</div>
            <div className="text-center">
              팝업 또는 배너를 추가하고 고객 데이터를 분석합니다.
            </div>
          </div>
        }
      />
      <div className="mt-8">
        <Tab fullWidth={true}>
          <Tab.Label selected={tab === 0} onClick={() => setTab(0)}>
            맡겨주세요
          </Tab.Label>
          <Tab.Label selected={tab === 1} onClick={() => setTab(1)}>
            <div className="relative">
              <div
                className={twMerge(
                  "absolute left-0 right-0 mx-auto w-fit bottom-8 hidden",
                  tab === 0 && "block"
                )}
              >
                <div className="relative flex items-center gap-2 py-1 px-2 bg-black opacity-[0.88] rounded-sm shadow-lg z-50">
                  <p className="text-sm text-cb-brand">생각보다 간단해요!</p>
                </div>
                <div className="absolute w-0 h-0 border-t-[8px] border-x-[8px] border-x-transparent border-t-black border-opacity-[0.88] -bottom-[7px] left-0 right-0 mx-auto" />
              </div>
              혼자 해볼래요
            </div>
          </Tab.Label>
        </Tab>

        <div className="flex flex-col p-8 mt-1 text-center center-center bg-cb-gray-50">
          {tab === 0 ? (
            <NeedsHelpRender />
          ) : tab === 1 ? (
            <>
              <div className="flex my-8 center-center">
                <SiteTypeIcon siteType="WORDPRESS" width={80} height={80} />
              </div>

              <a
                href={builderCommonScriptGuideLink}
                target="_blank"
                data-gtm-id={`${prefixGtmId}_script_guide`}
                className="inline-flex w-fit gap-2 px-4 border rounded-md center-center h-9 border-cb-gray-900 min-w-[209px]"
              >
                {getSiteTypeName("WORDPRESS")} 가이드 바로가기
                <ExternalLink className="w-4 h-4" />
              </a>

              <div className="mb-3 mt-9 text-cb-gray-700 break-keep">
                워드프레스 플러그인 옵션 페이지에서{" "}
                <span className="font-bold">사이트 ID</span> 항목에 아래 사이트
                ID를 입력하신 후,{" "}
                <span className="font-bold">변경사항 저장</span> 버튼을 클릭해
                주세요.
              </div>

              <div className="flex gap-2.5 center-center">
                <span className="text-base font-bold text-cb-gray-800">
                  {currentSite?.publicId}
                </span>

                <CopyToClipboard
                  text={currentSite?.publicId || ""}
                  onCopy={() => {
                    toast("사이트 ID를 복사했어요.");
                  }}
                >
                  <SimpleButton
                    icon={<Copy width={16} className="text-black" />}
                  />
                </CopyToClipboard>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="mt-8">
        {tab === 0 ? (
          <>
            <LoadingButton
              theme="primary"
              block
              loading={loading}
              onClick={handleRequestInstall}
              data-gtm-id={`${prefixGtmId}_script_request_install_script`}
            >
              설치 요청
            </LoadingButton>
            <LoadingButton
              theme="text"
              className="mt-1"
              block
              size="small"
              onClick={onFinish}
              data-gtm-id={`${prefixGtmId}_script_later_install_script`}
            >
              다음에 설치하기
            </LoadingButton>
          </>
        ) : (
          <LoadingButton
            theme="primary"
            block
            onClick={onFinish}
            data-gtm-id={`${prefixGtmId}_script_self_install_script_next`}
          >
            {nextText}
          </LoadingButton>
        )}
      </div>
    </div>
  );
};

export default React.memo(WordpressOnboardingScript);
