import Loader from "@/components/common/ui/loader";
import { GET_ORGANIZATIONS } from "@/gql/organization";
import { GET_SITES_BY_ORG_SLUG } from "@/gql/site";
import { IOrganization } from "@/types/organization";
import { getStoredSelectedSite, getStoredSelectedTeam } from "@/utils/common";
import { handleApolloError } from "@/utils/handle-form";
import { useQuery } from "@apollo/client";
import {
  IGetOrganizationsQuery,
  IGetOrganizationsQueryVariables,
  IGetSitesByOrgSlugQuery,
  IGetSitesByOrgSlugQueryVariables,
  IGetSitesQuery,
} from "@shared/common/types/api-private/generated/graphql";
import { ExternalSiteType, ISite } from "@shared/common/types/site";
import { useSession } from "next-auth/react";
import { NextRouter, useRouter } from "next/router";
import React, { ReactNode, useContext, useEffect, useMemo } from "react";

const convertSite = (site: IGetSitesQuery["sites"][0] | undefined): ISite => {
  if (!site) {
    throw new Error("Site not found");
  }

  return {
    id: site.id,
    publicId: site.publicId,
    organizationId: site.organizationId,
    name: site.name,
    createdAt: site.createdAt,
    updatedAt: site.updatedAt,
    icon: site.icon ?? "",
    url: site.url ?? "",
    siteType: site.siteType ? (site.siteType as ExternalSiteType) : undefined,
    siteInfo: site.siteInfo,
    // plan: site.plan ? site.plan as ,
    option: site.option,
    isInstalled: site.isInstalled,
    installedAt: site.installedAt ?? "",
    isPublished: site.isPublished,
    publishedAt: site.publishedAt ?? "",
    siteIntegration:
      site.siteIntegration?.map((integration) => ({
        createdAt: "",
        id: 0,
        sk: "",
        type: integration?.type ?? 0,
        updatedAt: "",
        siteId: "",
        tokenId: integration?.tokenId ?? "",
      })) ?? [],
    attribution: site.attribution,
    setting: site.setting,
  };
};

const convertOrganization = (
  org: IGetOrganizationsQuery["organizations"][0]
): IOrganization => {
  if (!org) {
    throw new Error("Organization not found");
  }

  return {
    id: org.id,
    slug: org.slug ?? "",
    name: org.name ?? "",
    plan: org.plan ?? "",
    planStartAt: org.planStartAt ?? "",
    planEndAt: org.planEndAt ?? "",
    planOption: org.planOption,
    billingCycle: org.billingCycle ?? "",
    firstBilledAt: org.firstBilledAt ?? "",
    nextBilledAt: org.nextBilledAt ?? "",
    scheduledChangePlan: org.scheduledChangePlan ?? "",
    scheduledChangeAt: org.scheduledChangeAt ?? "",
    scheduledChangeBillingCycle: org.scheduledChangeBillingCycle ?? "",
    createdAt: org.createdAt ?? "",
    updatedAt: org.updatedAt ?? "",
    // TODO: 서버로부터 채워넣기
    organizationUser: undefined,
    userRole: (org.userRole as "MEMBER") ?? "MEMBER",
    paymentManager: org.paymentManager,
    status: org.status ?? 1,
    billingFailCount: org.billingFailCount ?? 0,
    onboardStep: org.onboardStep ?? 10,
  };
};

type State =
  | {
      type: "loading" | "has_no_team" | "unknown";
    }
  | {
      type: "no_session";
    }
  | {
      type: "no_need_team";
      organizations: IOrganization[];
    }
  | {
      type: "wrong_team";
      organizations: IOrganization[];
      wrongOrganizationSlug: string;
    }
  | {
      type: "right_team_has_no_site";
      organizations: IOrganization[];
      currentOrganization: IOrganization;
    }
  | {
      type: "right_team_wrong_site";
      sites: ISite[];
      wrongSitePublicId: string;
      organizations: IOrganization[];
      currentOrganization: IOrganization;
    }
  | {
      type: "right_team_no_need_site";
      sites: ISite[];
      organizations: IOrganization[];
      currentOrganization: IOrganization;
    }
  | {
      type: "right_team_right_site";
      organizations: IOrganization[];
      currentOrganization: IOrganization;
      sites: ISite[];
      currentSite: ISite;
    };

type ContextState =
  | { type: "no_session" }
  | { type: "no_need_team"; organizations: IOrganization[] }
  | {
      type: "right_team_no_need_site";
      sites: ISite[];
      organizations: IOrganization[];
      currentOrganization: IOrganization;
    }
  | {
      type: "right_team_right_site";
      organizations: IOrganization[];
      currentOrganization: IOrganization;
      sites: ISite[];
      currentSite: ISite;
    };

interface IOrgAndSiteContext {
  /** 현재 조직. org_slug 없을 경우 lastSeenOrganization >> organizations[0] 순으로 Fallback 됩니다 */
  currentOrganization: IOrganization | null;
  /** 현재 사이트. site_public_id 없을 경우 lastSeenSite >> sites[0] 순으로 Fallback 됩니다 */
  currentSite: ISite | null;
  organizations: IOrganization[];
  sites: ISite[];
  /** 현 상태. org_slug, site_public_id 에 따라 변경되며 Fallback 되지 않습니다. */
  state: ContextState;
}

const context = React.createContext<IOrgAndSiteContext>({
  organizations: [],
  sites: [],
  currentOrganization: null,
  currentSite: null,
  state: { type: "no_session" },
});

export function useOrgAndsiteContext() {
  return useContext(context);
}

/** org_slug 가 있는 경로에서 사용할 수 있습니다 */
export function useCurrentOrg() {
  const { currentOrganization } = useContext(context);
  if (!currentOrganization) {
    throw new Error("Invalid state");
  }
  return currentOrganization;
}

/** org_slug 와 team_public_id 에서 사용할 수 있는 Context 입니다. */
export function useCurrentOrgAndSite() {
  const { state } = useContext(context);
  if (state.type !== "right_team_right_site") {
    throw new Error("Invalid state");
  }
  return {
    currentOrganization: state.currentOrganization,
    currentSite: state.currentSite,
  };
}

interface IOrganizationProviderProps {
  children: ReactNode;
}

const getOrgSlugFromRouter = (router: NextRouter) => {
  const slug = router.query.org_slug;
  if (typeof slug === "string") {
    return slug;
  }
  return null;
};

const getSitePublicIdFromRouter = (router: NextRouter) => {
  const sitePublicId = router.query.site_public_id;
  if (typeof sitePublicId === "string") {
    return sitePublicId;
  }
  return null;
};

const emptyOrganizations: IGetOrganizationsQuery["organizations"] = [];
const emptySites: IGetSitesQuery["sites"] = [];

const OrgAndSiteProvider: React.FC<IOrganizationProviderProps> = ({
  children,
}) => {
  const router = useRouter();
  const { data: session, status: sessionStatus } = useSession();
  const orgSlug = getOrgSlugFromRouter(router);
  const sitePublicId = getSitePublicIdFromRouter(router);

  const {
    data: orgsData,
    previousData: orgsPreviousData,
    loading: orgsEveryLoading,
  } = useQuery<IGetOrganizationsQuery, IGetOrganizationsQueryVariables>(
    GET_ORGANIZATIONS,
    {
      skip: !session,
      variables: {
        organizationSlug: [orgSlug],
      },
      onError: handleApolloError,
    }
  );

  const orgs =
    orgsData?.organizations ??
    orgsPreviousData?.organizations ??
    emptyOrganizations;
  const orgsLoading = !orgsPreviousData ? orgsEveryLoading : false;

  const lastSeenTeamSlug = getStoredSelectedTeam();
  const lastSeenTeamFound =
    orgs.find((org) => org?.slug === lastSeenTeamSlug) ?? null;
  const firstTeam = orgs[0];
  const currentOrganizationFallback = lastSeenTeamFound
    ? convertOrganization(lastSeenTeamFound)
    : firstTeam
    ? convertOrganization(firstTeam)
    : null;

  const {
    data: sitesData,
    previousData: sitesPreviousData,
    loading: sitesEveryLoading,
  } = useQuery<IGetSitesByOrgSlugQuery, IGetSitesByOrgSlugQueryVariables>(
    GET_SITES_BY_ORG_SLUG,
    {
      skip: !(
        session && Boolean(orgSlug ?? currentOrganizationFallback?.slug ?? "")
      ),
      variables: {
        organizationSlug: orgSlug ?? currentOrganizationFallback?.slug ?? "",
      },
      onError: handleApolloError,
    }
  );

  const sites =
    sitesData?.sitesByOrgSlug ??
    sitesPreviousData?.sitesByOrgSlug ??
    emptySites;
  const sitesLoading = !sitesPreviousData ? sitesEveryLoading : false;

  const lastSeenSitePublicId = getStoredSelectedSite();
  const lastSeenSiteFound =
    sites.find((site) => site.publicId === lastSeenSitePublicId) ?? null;
  const firstSite = sites[0];

  const currentSiteFallback = lastSeenSiteFound
    ? convertSite(lastSeenSiteFound)
    : firstSite
    ? convertSite(firstSite)
    : null;

  const state = useMemo((): State => {
    if (sessionStatus === "loading") {
      return {
        type: "loading",
      };
    }

    if (!session) {
      return {
        type: "no_session",
      };
    }

    // 팀 목록은 무조건 가져와야 함. 로딩 시 화면 보여주기.
    if (orgsLoading) {
      return {
        type: "loading",
      };
    }

    if (!orgSlug) {
      // 주소에 orgSlug 가 없을 때
      return {
        type: "no_need_team",
        organizations: orgs.map(convertOrganization),
      };
    }

    // 사이트가 로딩중일 때
    if (sitesLoading) {
      return {
        type: "loading",
      };
    }

    if (orgs.length === 0) {
      return { type: "has_no_team" };
    }

    const orgFound = orgs.find((org) => org?.slug === orgSlug);
    if (!orgFound) {
      return {
        type: "wrong_team",
        wrongOrganizationSlug: orgSlug,
        organizations: orgs.map(convertOrganization),
      };
    }

    const siteFound = sites.find((site) => site.publicId === sitePublicId);
    const hasSite = sites.length > 0;
    const siteState =
      // sitePublicId 가 없으면 일단 사이트 개념이 필요 없는 페이지임
      !sitePublicId
        ? "no_need_site"
        : // 사이트가 아예 없는 상태 (사이트를 새로 만들어야 함)
        !hasSite
        ? "has_no_site"
        : // 가지고 있는 사이트가 있지만, 해당하는 사이트가 없는 상태 (sitePublicId 가 잘못된 상태)
        hasSite && !siteFound
        ? "wrong_site"
        : // 해당하는 사이트가 있는 상태
        siteFound
        ? "right_site"
        : "unknown";

    switch (siteState) {
      case "has_no_site":
        return {
          type: `right_team_${siteState}`,
          organizations: orgs.map(convertOrganization),
          currentOrganization: convertOrganization(orgFound),
        };
      case "no_need_site":
        return {
          type: `right_team_${siteState}`,
          sites: sites.map(convertSite),
          organizations: orgs.map(convertOrganization),
          currentOrganization: convertOrganization(orgFound),
        };
      case "wrong_site":
        return {
          type: `right_team_${siteState}`,
          wrongSitePublicId: sitePublicId ?? "",
          sites: sites.map(convertSite),
          organizations: orgs.map(convertOrganization),
          currentOrganization: convertOrganization(orgFound),
        };

      case "right_site": {
        return {
          type: "right_team_right_site",
          organizations: orgs.map(convertOrganization),
          currentOrganization: convertOrganization(orgFound),
          sites: sites.map(convertSite),
          currentSite: convertSite(siteFound),
        };
      }
      case "unknown": {
        return {
          type: "unknown",
        };
      }
    }
  }, [
    orgSlug,
    orgs,
    orgsLoading,
    session,
    sessionStatus,
    sitePublicId,
    sites,
    sitesLoading,
  ]);

  // 잘못된 경로에 따른 리다이렉트 (Fallback Router 역할 대체)
  useEffect(() => {
    switch (state.type) {
      case "has_no_team": {
        if (router.pathname !== `/v2/welcome`) {
          router.replace("/v2/welcome");
        }
        return;
      }
      case "wrong_team": {
        router.replace("/v2");
        return;
      }
      case "right_team_has_no_site":
        if (router.pathname !== `/v2/[org_slug]/no-site`) {
          router.replace(`/v2/${state.currentOrganization.slug}/no-site`);
        }
        return;
      case "right_team_wrong_site": {
        router.replace(`/v2/${state.currentOrganization.slug}`);
        return;
      }
      // 사이트 id가 필요 없는 경우라도 팀이 하나도 없으면 팀을 만드는 쪽으로 보내기
      case "right_team_no_need_site": {
        if (
          state.sites.length === 0 &&
          router.pathname !== "/v2/[org_slug]/no-site"
        ) {
          router.replace(`/v2/${state.currentOrganization.slug}/no-site`);
        }
        return;
      }

      case "unknown":
      case "loading":
      case "no_session":
      case "no_need_team":
      case "right_team_right_site":
        return;
    }
  }, [router, state]);

  switch (state.type) {
    case "loading":
    case "unknown":
    case "wrong_team":
    case "right_team_wrong_site":
    case "has_no_team":
    case "right_team_has_no_site":
      return <Loader size="large" />;
    case "no_session":
      return (
        <context.Provider
          value={{
            currentOrganization: null,
            currentSite: null,
            organizations: [],
            sites: [],
            state,
          }}
        >
          {children}
        </context.Provider>
      );
    case "no_need_team": {
      return (
        <context.Provider
          value={{
            currentOrganization: currentOrganizationFallback,
            currentSite: currentSiteFallback,
            organizations: state.organizations,
            sites: sites.map(convertSite),
            state,
          }}
        >
          {children}
        </context.Provider>
      );
    }

    case "right_team_no_need_site": {
      return (
        <context.Provider
          value={{
            currentOrganization: state.currentOrganization,
            currentSite: currentSiteFallback,
            organizations: state.organizations,
            sites: state.sites,
            state,
          }}
        >
          {children}
        </context.Provider>
      );
    }
    case "right_team_right_site": {
      return (
        <context.Provider
          value={{
            currentOrganization: state.currentOrganization,
            currentSite: state.currentSite,
            organizations: state.organizations,
            sites: state.sites,
            state,
          }}
        >
          {children}
        </context.Provider>
      );
    }
  }
};

export default OrgAndSiteProvider;
