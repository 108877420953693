// AD 관련 트래킹 컴포넌트

import { useRouter } from "next/router";
import { useEffect } from "react";
import useKakaoPixel from "./kakao/pixel";

interface ITrackingScript {}

const TrackingScript: React.FC<ITrackingScript> = () => {
  const router = useRouter();
  const { sendKakaoPixelPageView } = useKakaoPixel();

  useEffect(() => {
    const handleRouteChange = () => {
      sendKakaoPixelPageView();
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.events]);

  return null;
};

export default TrackingScript;
