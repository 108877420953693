import LoadingButton from "@/components/common/form/loading-button";
import React from "react";
import { ISupporter } from ".";
import V2GuideLinkIcon from "../icon/v2-guide-link-icon";

interface IApplicationCounselingGuide {
  supporter?: ISupporter | null;
  onChangeStep: (step: number) => void;
  handleShowCommonScriptGuideClick: () => void;
}

const ApplicationCounselingGuide: React.FC<IApplicationCounselingGuide> = ({
  supporter,
  onChangeStep,
  handleShowCommonScriptGuideClick,
}) => {
  return (
    <>
      <div className="flex flex-col gap-4 p-4 border rounded border-cb-stroke-default">
        <div>CODENBUTTER 전담 상담사</div>
        <div className="flex items-center gap-4">
          {supporter?.icon}
          <div>
            <div className="font-semibold">{supporter?.name}</div>
            <div className="text-cb-gray-700">{supporter?.email}</div>
          </div>
        </div>
      </div>

      <p className="mt-6 font-semibold">
        CODENBUTTER 전담 상담사와의 무료 상담을 예약하세요.
      </p>

      <div className="mt-4">
        <p>코드앤버터를 잘 활용하는 방법을 알려드려요.</p>
        <ul className="mt-2 text-cb-gray-700 custom-disc">
          <li>팝업을 재방문 고객에게만 노출하는 방법</li>
          <li>이벤트폼과 프로모션을 연동하는 방법</li>
          <li>배너를 통한 최적의 마케팅 사례</li>
        </ul>
      </div>

      <div
        className="flex items-center gap-1 group px-[5px] py-1.5 cursor-pointer"
        onClick={handleShowCommonScriptGuideClick}
        data-gtm-id="consulting_common_script_guide"
      >
        <span className="text-sm underline text-cb-gray-500">
          혹시 아직 스크립트를 추가하지 않으셨나요?
        </span>
        <span className="transition-all duration-300 group-hover:translate-x-1">
          <V2GuideLinkIcon />
        </span>
      </div>

      <div className="flex items-center justify-end gap-4 mt-8">
        <LoadingButton
          theme="primary"
          onClick={() => onChangeStep(2)}
          className="min-w-[6rem]"
          block
          data-gtm-id="consulting_booking_next_btn"
        >
          상담 예약하기
        </LoadingButton>
      </div>
    </>
  );
};

export default React.memo(ApplicationCounselingGuide);
