import { Loader2 } from "lucide-react";
import React from "react";
import { twMerge } from "tailwind-merge";
import styles from "./loader.module.css";

interface ILoaderProps {
  size?: "small" | "medium" | "large" | "xlarge";
  className?: string;
  block?: boolean; // block은 영역, 그외엔 화면 가운데에 표시
}

const Loader = ({ size, className, block }: ILoaderProps) => {
  const spinner = (
    <Loader2
      className={twMerge(styles.loader, className)}
      width={
        size === "small"
          ? "0.875rem"
          : size === "xlarge"
          ? "4rem"
          : size === "large"
          ? "2rem"
          : "1rem"
      }
      height={
        size === "small"
          ? "0.875rem"
          : size === "xlarge"
          ? "4rem"
          : size === "large"
          ? "2rem"
          : "1rem"
      }
    />
  );

  if (block) {
    return spinner;
  }

  return <div className={styles["loader-center-layer"]}>{spinner}</div>;
};

export default React.memo(Loader);
