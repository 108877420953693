import LoadingButton from "@/components/common/form/loading-button";
import { X } from "lucide-react";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { twMerge } from "tailwind-merge";
import styles from "./modal.module.css";

interface IModalProps {
  open: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  headerRight?: React.ReactNode;
  children?: React.ReactNode;
  onOk?: () => void | Promise<void>;
  onOkWithoutClose?: () => void | Promise<void>;
  okText?: string;
  okLoading?: boolean;
  okDisabled?: boolean;
  okGtmId?: string;
  closeGtmId?: string;
  onCancel?: () => void | Promise<void>;
  onCancelWithoutClose?: () => void | Promise<void>;
  cancelText?: string;
  hideCloseButton?: boolean;
  hideCancelButton?: boolean;
  bodyClassName?: string;
  useHeaderButton?: boolean;
  hideHeader?: boolean;
  hideFooter?: boolean;
  size?: "small" | "large";
  headerSize?: "small" | "large"; // 큰 모달의 경우 py-8은 너무 뚱뚱해보이는 경우가 있어서 py-6으로 사용하기 위해 사용
  backdrop?: boolean;
}

const Portal = ({ children }: { children: ReactNode }) => {
  return createPortal(children, document.body);
};

const Modal: React.FC<IModalProps> = ({
  open,
  onClose,
  title,
  headerRight,
  children,
  onOk,
  onOkWithoutClose,
  okText = "확인",
  okLoading,
  okDisabled,
  okGtmId,
  closeGtmId,
  onCancel,
  onCancelWithoutClose,
  cancelText = "취소",
  hideCloseButton,
  hideCancelButton,
  bodyClassName,
  useHeaderButton,
  hideHeader,
  hideFooter,
  size,
  headerSize,
  backdrop,
}) => {
  const [isRender, setIsRender] = useState(false);
  const [opacity, setOpacity] = useState(false);
  const timeoutRef = useRef<number | null>(null);
  const closeImmediately = useRef(false);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (open) {
      setIsRender(true);
      setTimeout(() => setOpacity(true), 10);
    } else {
      setOpacity(false);

      if (closeImmediately.current) {
        // 이미 delay 후 상태가 변경되었으므로 바로 닫기
        setIsRender(false);
        closeImmediately.current = false;
      } else {
        timeoutRef.current = window.setTimeout(() => setIsRender(false), 200);
      }
    }
  }, [open]);

  const handleClose = () => {
    setOpacity(false);
    closeImmediately.current = true;
    setTimeout(() => onClose?.(), 200);
  };

  const handleOk = async () => {
    if (onOk) {
      const onOkRes = onOk();
      if (onOkRes && onOkRes instanceof Promise) {
        onOkRes.finally(handleClose);
        return;
      }

      handleClose();
    } else if (onOkWithoutClose) {
      // antd form.onFinish처럼 async를 지원하지 않는 경우 별도로 close 함수 호출
      await onOkWithoutClose();
    } else {
      // 기본 동작
      handleClose();
    }
  };

  const handleCancel = async () => {
    if (onCancel) {
      const onCancelRes = onCancel?.();
      if (onCancelRes && onCancelRes instanceof Promise) {
        onCancelRes.finally(handleClose);
        return;
      }

      handleClose();
    } else if (onCancelWithoutClose) {
      // antd form.onFinish처럼 async를 지원하지 않는 경우 별도로 close 함수 호출
      await onCancelWithoutClose();
    } else {
      // 기본동작
      handleClose();
    }
  };

  if (!isRender) {
    return null;
  }

  return (
    <Portal>
      <div
        className={twMerge(
          "v2override",
          styles.modal,
          backdrop && "backdrop-blur",
          opacity ? "opacity-100" : "opacity-0"
        )}
      >
        <div className="max-w-full max-h-full">
          <div className="p-8">
            <div className="relative bg-white rounded shadow">
              {!hideHeader && (
                <div
                  className={twMerge(
                    "flex items-center justify-between px-8 pt-8",
                    headerSize === "small" && "pt-6"
                  )}
                >
                  {title && (
                    <h3 className="text-lg font-semibold leading-none text-cb-gray-900">
                      {title}
                    </h3>
                  )}
                  {headerRight && <div>{headerRight}</div>}
                  {useHeaderButton && (
                    <div className="flex items-center justify-end gap-4 -my-3">
                      <LoadingButton
                        theme="minimal"
                        onClick={handleCancel}
                        className={twMerge(
                          "min-w-[6rem]",
                          hideCancelButton && "hidden"
                        )}
                      >
                        {cancelText}
                      </LoadingButton>
                      <LoadingButton
                        theme="primary"
                        disabled={okDisabled}
                        onClick={handleOk}
                        loading={okLoading}
                        className="min-w-[6rem]"
                        data-gtm-id={okGtmId}
                      >
                        {okText}
                      </LoadingButton>
                    </div>
                  )}
                  {hideCloseButton === true ? (
                    <></>
                  ) : (
                    <X
                      className="p-2 -my-2 -mr-2 transition-all rounded-md cursor-pointer w-9 h-9 text-cb-gray-900 hover:bg-cb-gray-100"
                      onClick={handleClose}
                      data-gtm-id={closeGtmId}
                    />
                  )}
                </div>
              )}
              <div
                className={twMerge(
                  "p-8",
                  size === "small" && "w-104",
                  size === "large" && "w-126",
                  headerSize === "small" && "pt-6",
                  bodyClassName
                )}
              >
                <div>{children}</div>

                {!hideFooter && !useHeaderButton && (
                  <div className="flex items-center justify-end gap-2 mt-8">
                    <LoadingButton
                      theme="outline"
                      onClick={handleCancel}
                      className={twMerge(
                        "min-w-[6rem]",
                        hideCancelButton && "hidden"
                      )}
                    >
                      {cancelText}
                    </LoadingButton>
                    <LoadingButton
                      theme="primary"
                      disabled={okDisabled}
                      onClick={handleOk}
                      loading={okLoading}
                      className="min-w-[6rem]"
                      data-gtm-id={okGtmId}
                    >
                      {okText}
                    </LoadingButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default React.memo(Modal);
