import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface IFormLabelProps {
  label: ReactNode;
  required?: boolean;
  className?: string;
}

const FormLabel = ({ label, required, className }: IFormLabelProps) => {
  return (
    <span className={twMerge("flex items-start text-neut-40 ", className)}>
      <span>{label}</span>
      {required && (
        <div className="mt-px w-[4px] h-[4px] rounded-full bg-chartPurple-2 ml-1"></div>
      )}
    </span>
  );
};

export default FormLabel;
