import { createPosition } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { ISwiperPaginationNode, PartialNode } from "../type";

const createSwiperPaginationNode = (
  data: PartialNode<ISwiperPaginationNode> = {}
): ISwiperPaginationNode => {
  const result: ISwiperPaginationNode = {
    // 기본값
    type: "SWIPER_PAGINATION",
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },

    reactions: [],
    name: "슬라이드 페이지네이션",
    locked: false,
    visible: true,

    strokes: [],
    strokeWeight: 0,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],
    primaryAxisAlignItems: "CENTER",
    counterAxisAlignItems: "CENTER",

    fills: [{ type: "SOLID", color: { r: 0, g: 0, b: 0, a: 0 }, opacity: 0 }],
    backgrounds: [],

    effects: [],
    alignHorizontal: "LEFT",

    // 인자값
    ...data,

    // 필수값
    style: createPaginationStyle(data.style),
    position: createPosition(data.position),
  };

  return result;
};

export const createPaginationBulletStyle = (
  data?: PartialNode<ISwiperPaginationNode["style"]>
): Extract<ISwiperPaginationNode["style"], { type: "BULLET" }> => {
  const result: Extract<ISwiperPaginationNode["style"], { type: "BULLET" }> = {
    // 기본값
    activeColor: { r: 23, g: 23, b: 23, a: 1 },
    color: { r: 237, g: 237, b: 237, a: 1 },
    gap: 10,
    // 지름 (px)
    size: 14,

    // 인자값
    ...data,

    // 필수값
    type: "BULLET",
  };

  return result;
};

export const createPaginationFractionStyle = (
  data?: PartialNode<ISwiperPaginationNode["style"]>
): Extract<ISwiperPaginationNode["style"], { type: "FRACTION" }> => {
  const result: Extract<ISwiperPaginationNode["style"], { type: "FRACTION" }> =
    {
      // 기본값
      color: { r: 237, g: 237, b: 237, a: 1 },
      fontSize: { value: 14, unit: "px" },
      fontName: { family: "InterRegular-ascii", style: "normal" },

      // 인자값
      ...data,

      // 필수값
      type: "FRACTION",
    };

  return result;
};

export const createPaginationStyle = (
  data?: PartialNode<ISwiperPaginationNode["style"]>
): ISwiperPaginationNode["style"] => {
  switch (data?.type) {
    case "BULLET":
      return createPaginationBulletStyle(data);
    case "FRACTION":
      return createPaginationFractionStyle(data);
    default:
      return createPaginationBulletStyle(data);
  }
};

export default createSwiperPaginationNode;
