import { createPosition } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { IImageNode, PartialNode } from "../type";

const createImageNode = (data?: PartialNode<IImageNode>): IImageNode => {
  return {
    /** 기본값 */
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [
        {
          type: "image_content",
          name: "이미지",
        },
      ],
    },
    reactions: [],
    position: createPosition(data?.position),

    name: "",

    width: { value: 100, unit: "%" },
    alignHorizontal: "LEFT",

    locked: false,
    visible: true,

    strokes: [],
    strokeWeight: 0,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    fills: [],
    videoInfo: {
      type: "VIDEO",
    },

    effects: [],
    autoplay: true,
    muted: true,
    loop: false,
    controls: false,

    /** 인자값 */
    ...data,

    /** 필수값 */
    type: "IMAGE",
  };
};

export default createImageNode;
