import { createSceneNode } from "@shared/common/utils/create";
import rfdc from "rfdc";
import shortUUID from "short-uuid";
import type { IDocumentNode, PartialNode } from "../type";

const deepclone = rfdc();

const createDocumentNode = (
  data?: PartialNode<IDocumentNode>
): IDocumentNode => {
  return {
    // 기본값
    id: shortUUID.generate(),
    pluginData: {},
    name: "",
    backgrounds: [],
    offset: { x: { value: 0 }, y: { value: 0 } },
    position: "MIDDLE_CENTER",
    device: "MOBILE",
    templates: [],

    // 인자값
    ...deepclone(data),

    // 필수값
    children: data?.children?.map(createSceneNode) ?? [],
    type: "DOCUMENT",
  };
};

export default createDocumentNode;
