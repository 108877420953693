export const getHasuraEndpoint = (): string => {
  return process.env.HASURA_ENDPOINT || "";
};

export const getHasuraAdminSecretHeaderKey = (): string => {
  return "x-hasura-admin-secret";
};

export const getHasuraAdminSecret = (): string => {
  return process.env.HASURA_ADMIN_SECRET || "";
};

export const getAnalyticsEndpoint = (): string => {
  return process.env.ANALYTICS_API_ENDPOINT || "https://a.test.buttr.dev";
};

export const getAnalyticsApiKey = (): string => {
  return process.env.ANALYTICS_API_KEY || "1234";
};

export const getInstagramEndpoint = (): string => {
  return process.env.INSTAGRAM_SERVICE_ENDPOINT || "";
};

export const getPublicAppURL = (): string =>
  process.env.NEXT_PUBLIC_APP_DOMAIN
    ? `https://${process.env.NEXT_PUBLIC_APP_DOMAIN}`
    : "https://codenbutter-app.test.purple.io";

export const getCafe24AuthRedirectURL = (): string =>
  process.env.CAFE24_AUTH_REDIRECT_URL || "";

export const getCafe24ClientId = (): string =>
  process.env.CAFE24_CLIENT_ID || "";

export const getCafe24ClientSecret = (): string =>
  process.env.CAFE24_CLIENT_SECRET || "";

export const getCafe24WebHookApiKey = (): string =>
  process.env.CAFE24_WEB_HOOK_API_KEY || "";

export const getScriptDomain = (): string => process.env.SCRIPT_DOMAIN || "";

export const getNhnAuthRedirectURL = (): string =>
  process.env.NHN_AUTH_REDIRECT_URL || "";

export const getNhnClientId = (): string => process.env.NHN_CLIENT_ID || "";

export const getNhnClientSecret = (): string =>
  process.env.NHN_CLIENT_SECRET || "";

export const getAIUserApiEndpoint = (): string =>
  process.env.AI_USER_API_ENDPOINT || "";

export const getAIAdminApiEndpoint = (): string =>
  process.env.AI_ADMIN_API_ENDPOINT || "";

export const getAihubApiKey = (): string => process.env.AI_HUB_API_KEY || "";

export const getInstagramApiEndpoint = (): string =>
  process.env.NEXT_PUBLIC_INSTAGRAM_API_ENDPOINT || "";

export const getEventPageBaseUrl = (): string =>
  process.env.NEXT_PUBLIC_EVENT_PAGE_BASE_URL || "";

export const getSurveyPageBaseUrl = (): string =>
  process.env.NEXT_PUBLIC_SURVEY_PAGE_BASE_URL || "";

export const getPromotionPageBaseUrl = (): string =>
  process.env.NEXT_PUBLIC_PROMOTION_PAGE_BASE_URL || "";

export const getDynamoDBSubmissionTableName = (): string =>
  process.env.DYNAMODB_SUBMISSION_TABLE_NAME || "";

export const getTosspaymentsApiUrl = (): string =>
  process.env.NEXT_PUBLIC_TOSS_API_URL || "https://api.tosspayments.com/v1";
