import { ApolloError } from "@apollo/client";
import { notification } from "antd";

export const handleApolloError = (error: ApolloError): void => {
  notification["error"]({
    description: error.message,
    duration: 2.5,
    message: "error",
    top: 50,
  });
};

export const handleSubmitComplete = (msg: string) => {
  notification["success"]({
    description: msg,
    duration: 2.5,
    message: "success",
    top: 50,
  });
};
