import Link, { LinkProps } from "next/link";
import { twMerge } from "tailwind-merge";

interface ISimpleButtonProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> {
  icon: React.ReactNode;
  theme?:
    | "add"
    | "delete"
    | "default"
    | "primary"
    | "secondary"
    | "close"
    | "transparent";
  disabled?: boolean;
  size?: "default" | "large" | "small";
  href?: LinkProps["href"];
  wrapperClassName?: string;
}

const SimpleButton: React.FC<ISimpleButtonProps> = ({
  icon,
  theme = "default",
  disabled,
  size = "default",
  href,
  className,
  wrapperClassName,
  ...props
}) => {
  const AnchorComponent = (
    <a
      className={twMerge(
        "flex flex-shrink-0 items-center justify-center w-8 h-8",
        theme === "add" ? "text-success-400 hover:text-success-400" : "",
        theme === "delete" ? "text-error-300 hover:text-error-300" : "",
        theme === "primary" ? "text-cb-gray-900 hover:text-cb-gray-900" : "",

        theme === "secondary"
          ? "text-secondary-500 hover:text-secondary-500"
          : "",
        theme === "default" || theme === "close"
          ? "text-gray-500 hover:text-gray-500"
          : "",
        theme === "transparent" ? "" : "",
        disabled
          ? "cursor-default text-cb-disabled hover:text-cb-disabled"
          : "",
        size === "large" ? "w-9 h-9" : size === "small" ? "w-6 h-6" : "",
        className
      )}
      // TODO: props를 최상위로 올려야함. (만약 props에 className이 있으면 위 내용을 모두 무시하게 됨)
      {...props}
      onClick={(e) => {
        e.stopPropagation();
        if (disabled) {
          e.preventDefault();
          return;
        }
        props.onClick && props.onClick(e);
      }}
      style={{ ...props.style }}
    >
      {icon}
    </a>
  );
  return (
    <div
      className={twMerge(
        "transition-all duration-300 rounded",
        theme === "add" ? "hover:bg-success-100" : "",
        theme === "delete" ? "hover:bg-error-200 hover:bg-opacity-10" : "",
        theme === "default" ? "hover:bg-gray-200" : "",
        theme === "primary" ? "hover:bg-cb-gray-100" : "",
        theme === "secondary" ? "hover:bg-secondary-200" : "",
        theme === "close" ? "hover:bg-gray-200" : "",
        disabled ? "hover:bg-inherit" : "",
        wrapperClassName
      )}
    >
      {href ? (
        <Link href={href} legacyBehavior>
          {AnchorComponent}
        </Link>
      ) : (
        AnchorComponent
      )}
    </div>
  );
};

export default SimpleButton;
