// TODO: 정리
import { createPageUIData } from "@/domain/target/page";
import { GET_CAMPAIGN_TEMPLATE_DATA } from "@/gql/campaign-template";
import { TempCampaign } from "@/types/campaign";
import { IEventItem } from "@/types/group-scheduler";
import { handleApolloError } from "@/utils/handle-form";
import { AndroidFilled, AppleFilled } from "@ant-design/icons";
import { ApolloError } from "@apollo/client";
import {
  DEFAULT_EVENT_OG_DESCRIPTION,
  DEFAULT_PROMOTION_OG_DESCRIPTION,
  DEFAULT_SURVEY_OG_DESCRIPTION,
  DEFUALT_OG_IMAGE_URL,
} from "@shared/common/constant/form";
import { ICampaignTargetCustom } from "@shared/common/custom-target-condition/types";
import { geolocationTargetData } from "@shared/common/data/campaign-target-geolocation-data";
import { hasContent } from "@shared/common/node/document-helper";
import { INodeDevice } from "@shared/common/node/type";
import {
  ICampaignInput,
  ICampaignType,
  IGetCampaignInsightQuery,
  IGetCampaignTemplateDataQuery,
  IGetCampaignTemplateDataQueryVariables,
  ISiteStatCampaignType,
} from "@shared/common/types/api-private/generated/graphql";
import {
  Campaign,
  CampaignTargetType,
  CampaignTemplateData,
  CampaignType,
  ICampaignFilterV2,
  ICampaignTargetGroup,
  ICampaignTargetLoc,
  ICampaignTargetNewOrReturningVisitor,
  ICampaignTargetRoot,
  ICampaignTargetUserAgent,
  PartialCampaign,
} from "@shared/common/types/campaign";
import { ExternalSiteType } from "@shared/common/types/site";
import {
  createBannerCampaign,
  createBannerGroupCampaign,
  createChildPopupCampaign,
  createEmbeddedInstagramCampaignV2,
  createFormEventCampaign,
  createFormSurveyCampaign,
  createPopupCampaign,
  createPopupGroupCampaign,
  createPromotionCampaign,
} from "@shared/common/utils/create";
import { getParamValue } from "@shared/common/utils/url";
import { t } from "i18next";
import moment from "moment";
import { NextRouter } from "next/router";
import { AndGroup, OrGroup } from "../domain/target/target-converter";
import { getPrivateApiClient } from "./apollo-client";
export { routerQuerySetter } from "@shared/common/utils/router-query-setter";

export const getResizedImageUrl = (url: string | unknown, d: string) => {
  if (String(url).indexOf("?") > -1) {
    return `${url}&d=${d}`;
  } else {
    return `${url}?d=${d}`;
  }
};

export const DATE_TIME_24HOUR_FORMAT = "YYYY.MM.DD HH:mm";

export const DATE_FORMAT = "YYYY.MM.DD";

/**
 *
 * @param noHostUrl host 가 없고 (URL 객체에 넘길 수 없는) pathname 과 searchParams 만 있는 URL 문자열
 * @returns URLSearchParams 객체
 */
export function convertNoHostUrlToSearchParams(
  noHostUrl: string
): URLSearchParams {
  const queryStringStartIndex = noHostUrl.indexOf("?");

  if (queryStringStartIndex === -1) {
    throw new Error("Invalid input string: Query string not found");
  }

  const queryString = noHostUrl.slice(queryStringStartIndex + 1);
  return new URLSearchParams(queryString);
}

type TargetType = "ALL" | "PARTIAL" | "HOME";

export const getTargetType = (root?: ICampaignTargetRoot): TargetType => {
  if (!root) {
    return "ALL";
  }

  // 구버전 타겟 분류하기
  if (Number(root.version) < 8) {
    const result = getPageAndUrlTargetList(root).map((item) => {
      if (item.type === "PAGE") {
        if (item.value === "HOME") {
          return "HOME";
        }

        return "PAGE";
      }

      if (item.type === "URL") {
        return "URL";
      }
    });

    if (result.length > 0) {
      if (result.length === 1 && result[0] === "HOME") {
        return "HOME";
      }
      return "PARTIAL";
    }
  }

  // version 8 타겟 분류하기
  const length = root.ui?.list?.length ?? 0;
  if (length === 0) {
    return "ALL";
  }
  if (length === 1) {
    const first = root.ui?.list?.[0];
    // 유일한 페이지일 때
    if (first?.type === "page" && first.list.length === 1) {
      const firstListItem = first.list[0];
      if (
        firstListItem?.type === "PRESET" &&
        firstListItem.value === "HOME" &&
        firstListItem.action === "SHOW"
      ) {
        return "HOME";
      }
    }
  }

  return "PARTIAL";
};

export const getDefaultTargetRoot = (
  defaultHome = true
): ICampaignTargetRoot => {
  const result: ICampaignTargetRoot = {
    type: "ROOT",
    version: "8",
    value: {
      type: "AND",
      name: "ROOT",
      value: [],
    },
    ui: {
      list: defaultHome
        ? [
            createPageUIData({
              list: [
                { type: "PRESET", value: "HOME", params: {}, action: "SHOW" },
              ],
            }),
          ]
        : [],
    },
  };
  return result;
};

export const flatCampaignTargetDeep = (
  group: ICampaignTargetGroup
): CampaignTargetType[] => {
  const result = group.value.map((item) => {
    if (item.type === "AND" || item.type === "OR") {
      return flatCampaignTargetDeep(item);
    }
    return item;
  });

  return result.flat();
};

export const getPageAndUrlTargetList = (
  root: ICampaignTargetRoot
): CampaignTargetType[] => {
  if (root.value?.type !== "AND") {
    return [];
  }
  const pageAndUrlIncluded = root.value.value.find(
    (child): child is ICampaignTargetGroup =>
      child.type === "OR" && child.name === "PAGE_AND_URL_INCLUDED"
  );

  const pageAndUrlNotIncluded = root.value.value.find(
    (child): child is ICampaignTargetGroup =>
      child.type === "AND" && child.name === "PAGE_AND_URL_NOT_INCLUDED"
  );
  return [
    ...(pageAndUrlIncluded ? flatCampaignTargetDeep(pageAndUrlIncluded) : []),
    ...(pageAndUrlNotIncluded
      ? flatCampaignTargetDeep(pageAndUrlNotIncluded)
      : []),
  ];
};

export const getCustomTargetList = (
  root: ICampaignTargetRoot
): ICampaignTargetCustom[] => {
  if (root.value?.type === "AND") {
    return root.value.value.filter(
      (child): child is ICampaignTargetCustom => child.type === "CUSTOM"
    );
  }

  return [];
};

export const getUserAgentTargetList = (
  root: ICampaignTargetRoot
): ICampaignTargetUserAgent[] => {
  if (root.value?.type !== "AND") {
    return [];
  }

  const userAgentIncludedGroup = root.value.value.find(
    (
      child
    ): child is OrGroup<ICampaignTargetUserAgent, "USER_AGENT_INCLUDED"> =>
      child.type === "OR" && child.name === "USER_AGENT_INCLUDED"
  );

  const userAgentNotIncluedGroup = root.value.value.find(
    (
      child
    ): child is AndGroup<ICampaignTargetUserAgent, "USER_AGENT_NOT_INCLUDED"> =>
      child.type === "AND" && child.name === "USER_AGENT_NOT_INCLUDED"
  );

  return [
    ...(userAgentIncludedGroup ? userAgentIncludedGroup.value : []),
    ...(userAgentNotIncluedGroup ? userAgentNotIncluedGroup.value : []),
  ];
};

export const getDomainTargetList = (
  root: ICampaignTargetRoot
): ICampaignTargetLoc[] => {
  if (root.value?.type !== "AND") {
    return [];
  }

  const domainIncludedGroup = root.value.value.find(
    (child): child is OrGroup<ICampaignTargetLoc, "DOMAIN_INCLUDED"> =>
      child.type === "OR" && child.name === "DOMAIN_INCLUDED"
  );

  const domainNotIncludedGroup = root.value.value.find(
    (child): child is AndGroup<ICampaignTargetLoc, "DOMAIN_NOT_INCLUDED"> =>
      child.type === "AND" && child.name === "DOMAIN_NOT_INCLUDED"
  );

  return [
    ...(domainIncludedGroup ? domainIncludedGroup.value : []),
    ...(domainNotIncludedGroup ? domainNotIncludedGroup.value : []),
  ];
};

export const getConditionTargetList = (
  root: ICampaignTargetRoot,
  value: string
) => {
  if (root.value?.type !== "AND") {
    return [];
  }

  const included = `${value}_INCLUDED`;
  const notIncluded = `${value}_NOT_INCLUDED`;

  const includedGroup = root?.value?.value?.find?.(
    (child): child is ICampaignTargetGroup =>
      child.type === "OR" && child.name === included
  );

  const notIncludedGroup = root?.value?.value?.find?.(
    (child): child is ICampaignTargetGroup =>
      child.type === "AND" && child.name === notIncluded
  );

  return [
    ...(includedGroup ? includedGroup.value : []),
    ...(notIncludedGroup ? notIncludedGroup.value : []),
  ];
};

export const getNewOrReturningVisitorTargetList = (
  root: ICampaignTargetRoot
): ICampaignTargetNewOrReturningVisitor[] => {
  if (root.value?.type !== "AND") {
    return [];
  }

  const newOrReturningVisitorIncludedGroup = root?.value?.value?.find?.(
    (
      child
    ): child is OrGroup<
      ICampaignTargetNewOrReturningVisitor,
      "NEW_OR_RETURNING_VISITOR_INCLUDED"
    > =>
      child.type === "OR" && child.name === "NEW_OR_RETURNING_VISITOR_INCLUDED"
  );

  const newOrReturningVisitorNotIncluedGroup = root?.value?.value?.find?.(
    (
      child
    ): child is AndGroup<
      ICampaignTargetNewOrReturningVisitor,
      "NEW_OR_RETURNING_VISITOR_NOT_INCLUDED"
    > =>
      child.type === "AND" &&
      child.name === "NEW_OR_RETURNING_VISITOR_NOT_INCLUDED"
  );

  return [
    ...(newOrReturningVisitorIncludedGroup
      ? newOrReturningVisitorIncludedGroup.value
      : []),
    ...(newOrReturningVisitorNotIncluedGroup
      ? newOrReturningVisitorNotIncluedGroup.value
      : []),
  ];
};

export const getUserAgentPresets = () => {
  const userAgentPresets = [
    {
      name: "IOS",
      label: "editor:segment.target.panel.label.iOS.name",
      value: "like Mac OS",
      icon: <AppleFilled />,
    },
    {
      name: "ANDROID",
      label: "editor:segment.target.panel.label.android.name",
      value: "Android",
      icon: <AndroidFilled style={{ color: "#89ad0d" }} />,
    },
  ] as const;

  return userAgentPresets;
};

export const getReferrerPresets = () => {
  const referrerPresets = [
    {
      name: "NAVER",
      label: "NAVER",
      value: "naver",
      icon: <></>,
    },
    {
      name: "GOOGLE",
      label: "GOOGLE",
      value: "google",
      icon: <></>,
    },
    {
      name: "DAUM",
      label: "DAUM",
      value: "daum",
      icon: <></>,
    },
    {
      name: "FACEBOOK",
      label: "FACEBOOK",
      value: "facebook",
      icon: <></>,
    },
  ] as const;

  return referrerPresets;
};

export const getIsFirstPresets = () => {
  const referrerPresets = [
    {
      name: "NEW",
      label: "신규 방문자",
      value: "new",
      icon: <></>,
    },
    {
      name: "RETURNING",
      label: "재방문자",
      value: "returning",
      icon: <></>,
    },
  ] as const;

  return referrerPresets;
};

export const getNewOrReturningVisitorPresets = () => {
  const newOrReturningVisitorPresets = [
    {
      name: "NEW",
      label: "editor:segment.target.panel.label.newVisitor.name",
      value: "NEW",
      icon: <></>,
    },
    {
      name: "RETURNING",
      label: "editor:segment.target.panel.label.returningVisitor.name",
      value: "RETURNING",
      icon: <></>,
    },
  ] as const;

  return newOrReturningVisitorPresets;
};

export const getUserAgentPreset = (name: string) => {
  return getUserAgentPresets().find(
    (getUserAgentPreset) => getUserAgentPreset.name === name
  );
};

export const getReferrerPreset = (name: string) => {
  return getReferrerPresets().find(
    (getReferrerPreset) => getReferrerPreset.name === name
  );
};

export const getIsFirstPreset = (name: string) => {
  return getIsFirstPresets().find(
    (getReferrerPreset) => getReferrerPreset.name === name
  );
};

export const getNewOrReturningVisitorPreset = (name: string) => {
  return getNewOrReturningVisitorPresets().find(
    (getNewOrReturningVisitorPreset) =>
      getNewOrReturningVisitorPreset.name === name
  );
};

export const getGeolocationRegion = (
  countryValue: string,
  targetValue: string
) => {
  return geolocationTargetData[`${countryValue}`]?.find(
    (data) => data.geonameId === targetValue
  );
};

export const getCampaignTemplateData = async (templateId: string) => {
  if (templateId === "") {
    return undefined;
  }

  try {
    const templateDataRes = await getPrivateApiClient().query<
      IGetCampaignTemplateDataQuery,
      IGetCampaignTemplateDataQueryVariables
    >({
      query: GET_CAMPAIGN_TEMPLATE_DATA,
      variables: {
        id: templateId,
      },
    });
    const data = templateDataRes.data.campaignTemplateData as
      | CampaignTemplateData
      | undefined;

    return data;
  } catch (error) {
    handleApolloError(error as ApolloError);
  }
};

// 그래프 Y 값을 구함 (10/100/1000단위로)
export const getGraphMaxCount = (
  data: Omit<IGetCampaignInsightQuery, "eventCountBySite">
) => {
  let maxCount = 0;

  data.dailyInsightPvSessionCount.forEach((count) => {
    // TODO: remove optional
    if (typeof count?.viewCount === "number" && count.viewCount > maxCount) {
      maxCount = count.viewCount;
    }

    if (
      // TODO: remove optional
      typeof count?.viewerCount === "number" &&
      count.viewerCount > maxCount
    ) {
      maxCount = count.viewerCount;
    }
  });

  if (maxCount > 1000) {
    return maxCount + (1000 - (maxCount % 1000));
  } else if (maxCount > 100) {
    return maxCount + (100 - (maxCount % 100));
  }

  return maxCount + (10 - (maxCount % 10));
};

const SELECT_TEAM_KEY = "buttr.team";
const SELECT_SITE_KEY = "buttr.site";

/** 팀이 URL에 없는 상황에서 로컬스토리지에 저장된 팀을 가져오기 위함. */
export const storeSelectedTeam = (team: { slug: string }) => {
  localStorage.setItem(SELECT_TEAM_KEY, team.slug);
};

export const getStoredSelectedTeam = () => {
  return typeof window === "undefined"
    ? ""
    : localStorage.getItem(SELECT_TEAM_KEY);
};

/** 사이트가 URL에 없는 상황에서 로컬스토리지에 저장된 팀을 가져오기 위함. */
export const storeSelectedSite = (site: { publicId: string }) => {
  localStorage.setItem(SELECT_SITE_KEY, site.publicId);
};

export const removeStoredSelectedSite = () => {
  localStorage.removeItem(SELECT_SITE_KEY);
};

export const getStoredSelectedSite = () => {
  return typeof window === "undefined"
    ? ""
    : localStorage.getItem(SELECT_SITE_KEY);
};

const noSiteNames = ["payment", "plan", "ai", "team", "site"];

export const getHotLinkUrl = ({
  name,
  sitePublicId,
  orgSlug,
}: {
  name: string;
  orgSlug: string;
  sitePublicId: string;
}) => {
  const base = noSiteNames.includes(name)
    ? `/v2/${orgSlug}`
    : `/v2/${orgSlug}/${sitePublicId}`;

  switch (name) {
    case "banner-group":
    case "group-banner":
      return `${base}/banner`;
    case "group-popup":
      return `${base}/popup-group`;
    case "form":
    case "event-form":
      return `${base}/form-event`;
    case "survey":
      return `${base}/form-survey`;
    case "event":
    case "event-page":
    case "eventpage":
      return `${base}/promotion`;
    case "stat":
      return `${base}/stats`;
    case "settings":
      return `${base}/setting`;
    case "plan":
      return `${base}/payment/plan`;
    default:
      return `${base}/${name}`;
  }
};

/**
 * PartialCampaign 객체를 받아 ICampaignInput 객체로 변환해준다.
 *
 * @param campaign: PartialCampaign
 * @returns
 */
export const campaignToICampaignInput = (
  campaign: PartialCampaign
): ICampaignInput => {
  return {
    name: campaign?.name ?? "",
    description: campaign?.description ?? "",
    data: campaign?.data ?? {},
    display: campaign?.display ?? {},
    target: campaign?.target ?? {},
    isActive: campaign?.isActive ?? false,
    schedule: campaign?.schedule ?? {},
    type: campaign.type ?? "",
    ...((campaign.type === "BANNER" || campaign.type === "POPUP") &&
      campaign.parentPublicId && {
        parentPublicId: campaign.parentPublicId,
      }),
    ...((campaign.type === "PROMOTION" ||
      campaign.type === "FORM_EVENT" ||
      campaign.type === "FORM_SURVEY" ||
      campaign.type === "BANNER_GROUP" ||
      campaign.type === "POPUP_GROUP" ||
      campaign.type === "POPUP") &&
      campaign.meta && { meta: campaign.meta }),
  };
};

const getInstallHTMLCode = (
  sitePublicId: string,
  siteType?: ExternalSiteType
) => {
  if (siteType) {
    switch (siteType) {
      case "MAKESHOP":
        return `${process.env.NEXT_PUBLIC_SCRIPT_DOMAIN}/butter.js?site_id=${sitePublicId}`;
      case "CAFE24":
      case "GODOMALL":
      case "IMWEB":
      case "OOPY":
      case "SHOPBY":
      case "SHOPBY_PRO":
      case "SHOPIFY":
      case "SIXSHOP":
      case "WIX":
      case "WORDPRESS":
      case "NAVER_SMARTSTORE":
        break;
    }
  }

  return `<script>${getInstallJSCode(sitePublicId)}</script>`;
};

const getInstallJSCode = (
  sitePublicId: string
) => `(function (co,de,n,but,t,e,r){!n[co]&&(n[co]=function(){
(n[co].q=n[co].q||[]).push(arguments);});e=t.createElement(but);
e.async=true;e.src=de;r=t.getElementsByTagName(but)[0];
r.parentNode.insertBefore(e, r);
})("CodenButter", "${process.env.NEXT_PUBLIC_SCRIPT_DOMAIN}/butter.js", window, "script", document);
window.CodenButter("boot", { siteId: "${sitePublicId}", auto: true });`;

export const campaignEmptyResultText = (
  type: ICampaignType
): {
  reason: string;
  description: string;
} => {
  switch (type) {
    case "POPUP":
      return {
        reason: t("emptyCampaign.reason.popup"),
        description: t("emptyCampaign.description.popup"),
      };
    case "INSTAGRAM":
    case "INSTAGRAM_V2":
      return {
        reason: t("emptyCampaign.reason.instagram"),
        description: t("emptyCampaign.description.instagram"),
      };
    case "BANNER_GROUP":
      return {
        reason: t("emptyCampaign.reason.bannerGroup"),
        description: t("emptyCampaign.description.bannerGroup"),
      };
    case "BANNER":
      return {
        reason: t("emptyCampaign.reason.banner"),
        description: t("emptyCampaign.description.banner"),
      };
    case "POPUP_GROUP":
      return {
        reason: t("emptyCampaign.reason.popupGroup"),
        description: t("emptyCampaign.description.popupGroup"),
      };
    case "FORM_EVENT":
      return {
        reason: t("emptyCampaign.reason.formEvent"),
        description: t("emptyCampaign.description.formEvent"),
      };
    case "PROMOTION":
      return {
        reason: t("emptyCampaign.reason.promotion"),
        description: t("emptyCampaign.description.promotion"),
      };
    case "FORM_SURVEY":
      return {
        reason: t("emptyCampaign.reason.formSurvey"),
        description: t("emptyCampaign.description.formSurvey"),
      };
    case "ALL":
      return {
        reason: t("emptyCampaign.reason.all"),
        description: t("emptyCampaign.description.all"),
      };
  }
};

export const getCampaignCodeGuideProps = (
  type: CampaignType | "COMMON",
  publicId: string,
  siteType?: ExternalSiteType,
  guideType?: string
): {
  title: string;
  code: string;
  steps: string[];
  videoLink?: string | null;
  alternativeLink?: string;
  detailLink?: string;
  language: "plaintext" | "javascript";
} => {
  switch (type) {
    case "COMMON":
      return {
        title: t("wayInstallScript.title"),
        code: getInstallHTMLCode(publicId, siteType),
        steps: [
          t("wayInstallScript.step1"),
          t("wayInstallScript.step2"),
          t("wayInstallScript.step3"),
          t("wayInstallScript.step4"),
        ],
        alternativeLink: `${process.env.NEXT_PUBLIC_BLOG_URL}/%ec%b2%ab-%ec%82%ac%ec%9a%a9%ec%9e%90%eb%a5%bc-%ec%9c%84%ed%95%9c-%ec%bd%94%eb%93%9c%ec%95%a4%eb%b2%84%ed%84%b0-%ec%8b%9c%ec%9e%91-%ea%b0%80%ec%9d%b4%eb%93%9c/`,
        language: siteType === "MAKESHOP" ? "plaintext" : "javascript",
      };
    case "BANNER":
    case "BANNER_GROUP":
      return {
        title: "HTML 코드 추가 방법",
        code: `<div data-cb-embedded="banner-group" data-campaign-id="${publicId}"></div>`,
        steps: [
          "배너 <HTML> 코드를 원하는 영역에 붙여넣으세요.(<Body>와 </Body> 사이)",
          "HTML 코드를 추가한 사이트에서 배너가 노출되는지 확인해주세요.",
          "추가한 배너 크기나 세부적인 위치 조절이 필요하다면 CSS나 붙여넣은 태그 안에 스타일 속성을 이용하여 꾸며주세요. ",
        ],
        videoLink:
          siteType === "IMWEB"
            ? "https://youtu.be/VQJb8W3xKYs"
            : siteType === "CAFE24"
            ? "https://youtu.be/wfqfipeainw"
            : siteType === "MAKESHOP"
            ? "https://youtu.be/iQLIF6FcjpU"
            : siteType === "SHOPIFY"
            ? "https://youtu.be/qb6dfOimzng"
            : null,
        alternativeLink: `${process.env.NEXT_PUBLIC_BLOG_URL}/%eb%b0%b0%eb%84%88-%eb%b0%b0%eb%84%88-%ec%b6%94%ea%b0%80%ed%95%98%ea%b8%b0/`,
        language: "javascript",
      };
    case "INSTAGRAM":
    case "INSTAGRAM_V2":
      return {
        title: "HTML 코드 추가 방법",
        code: `<div data-cb-embedded="instagram-v2" data-campaign-id="${publicId}"></div>`,
        steps: [
          "인스타그램 피드 <HTML> 코드를 원하는 영역에 붙여넣으세요. (<Body>와 </Body> 사이)",
          "설치한 사이트에서 인스타그램 피드가 노출되는지 확인해주세요.",
          "만약 인스타그램 크기나 세부적인 위치 조절이 필요하다면 CSS나 붙여넣은 태그 안에 스타일 속성을 이용하여 꾸며주세요.",
        ],
        alternativeLink: `${process.env.NEXT_PUBLIC_BLOG_URL}/%ec%9d%b8%ec%8a%a4%ed%83%80%ea%b7%b8%eb%9e%a8-%ec%9d%b8%ec%8a%a4%ed%83%80%ea%b7%b8%eb%9e%a8-%ec%b6%94%ea%b0%80%ed%95%98%ea%b8%b0/`,
        language: "javascript",
      };
    case "PROMOTION":
      return {
        title: "HTML 코드 추가 방법",
        code: `<div data-cb-embedded="promotion" data-campaign-id="${publicId}"></div>`,
        steps: [],
        detailLink: `${process.env.NEXT_PUBLIC_BLOG_URL}${
          guideType === "SITE"
            ? "/%ed%8e%98%ec%9d%b4%ec%a7%80-%eb%85%b8%ec%b6%9c-%eb%b0%a9%ec%8b%9d-%ec%82%ac%ec%9d%b4%ed%8a%b8%ec%97%90-%eb%85%b8%ec%b6%9c%ed%95%98%ea%b8%b0/"
            : guideType === "BOARD"
            ? "/%ed%8e%98%ec%9d%b4%ec%a7%80-%eb%85%b8%ec%b6%9c-%eb%b0%a9%ec%8b%9d-%ea%b2%8c%ec%8b%9c%ed%8c%90%ec%97%90-%eb%85%b8%ec%b6%9c%ed%95%98%ea%b8%b0/"
            : ""
        }`,
        language: "javascript",
      };
    case "FORM_SURVEY":
    case "POPUP":
    case "POPUP_GROUP":
    case "FORM_EVENT":
      return {
        title: "",
        code: "",
        steps: [],
        alternativeLink: "",
        language: "javascript",
      };
  }
};

export const getDashboardCategoryName = (
  campaignType: ISiteStatCampaignType
) => {
  switch (campaignType) {
    case "POPUP" || "INSTAGRAM":
      return "캠페인";
    case "BANNER_GROUP":
      return "배너그룹";
    case "POPUP_GROUP":
      return "그룹 팝업";
    default:
      return "캠페인";
  }
};

export const getInitialNodeDevice = (campaign: Campaign) => {
  if (
    campaign.type === "INSTAGRAM_V2" ||
    campaign.type === "INSTAGRAM" ||
    campaign.type === "FORM_EVENT" ||
    campaign.type === "FORM_SURVEY" ||
    campaign.type === "PROMOTION"
  ) {
    return "ALL";
  }

  const mobileHasContent = hasContent(
    campaign.data.documents.find((document) => document.device === "MOBILE")
  );
  const desktopHasContent = hasContent(
    campaign.data.documents.find((document) => document.device === "DESKTOP")
  );
  const allHasContent = hasContent(
    campaign.data.documents.find((document) => document.device === "ALL")
  );

  if (mobileHasContent) {
    return "MOBILE";
  }

  if (desktopHasContent) {
    return "DESKTOP";
  }

  if (allHasContent) {
    return "ALL";
  }

  return "MOBILE";
};

export const getIfValidNodeDeviceFromRouter = (
  router: NextRouter
): INodeDevice | null => {
  const nodeDevice = getParamValue<INodeDevice>(router.query, "device");
  if (
    nodeDevice === "DESKTOP" ||
    nodeDevice === "MOBILE" ||
    nodeDevice === "ALL"
  ) {
    return nodeDevice;
  }

  return null;
};

export const isTempCampaign = (
  campaign: Campaign
): campaign is TempCampaign => {
  return "isTemp" in campaign;
};

export const getDefaultCampaign = ({
  campaignType,
  name,
  parentPublicId,
}: {
  campaignType: CampaignType;
  name?: string;
  parentPublicId?: string;
}) => {
  switch (campaignType) {
    case "POPUP":
      return parentPublicId
        ? createChildPopupCampaign({
            target: getDefaultTargetRoot(false),
            name: name ?? `새 팝업 ${moment().format("YYYY.MM.DD")}`,
            isActive: false,
            type: "POPUP",
            parentPublicId,
          })
        : createPopupCampaign({
            target: getDefaultTargetRoot(),
            name: name ?? `새 팝업 ${moment().format("YYYY.MM.DD")}`,
            isActive: false,
            type: "POPUP",
          });

    case "INSTAGRAM":
    case "INSTAGRAM_V2":
      return createEmbeddedInstagramCampaignV2({
        target: getDefaultTargetRoot(false),
        name: name ?? `새 인스타그램 ${moment().format("YYYY.MM.DD")}`,
        isActive: false,
        type: "INSTAGRAM_V2",
      });

    case "BANNER_GROUP":
      return createBannerGroupCampaign({
        target: getDefaultTargetRoot(false),
        name: name ?? `새 배너 영역 ${moment().format("YYYY.MM.DD")}`,
        isActive: false,
        type: "BANNER_GROUP",
      });

    case "BANNER":
      return createBannerCampaign({
        target: getDefaultTargetRoot(false),
        name: name ?? `새 배너 ${moment().format("YYYY.MM.DD")}`,
        isActive: false,
        type: "BANNER",
        parentPublicId,
      });

    case "POPUP_GROUP":
      return createPopupGroupCampaign({
        target: getDefaultTargetRoot(),
        name: name ?? `새 팝업 영역 ${moment().format("YYYY.MM.DD")}`,
        isActive: false,
        type: "POPUP_GROUP",
      });

    case "FORM_EVENT":
      return createFormEventCampaign({
        target: getDefaultTargetRoot(false),
        name: name ?? `새 이벤트 폼${moment().format("YYYY.MM.DD")}`,
        isActive: false,
        type: "FORM_EVENT",
        meta: {
          og: {
            type: "DEFAULT",
            title: `새 이벤트 ${moment().format("YYYY.MM.DD")}`,
            description: DEFAULT_EVENT_OG_DESCRIPTION,
            imageUrl: DEFUALT_OG_IMAGE_URL,
          },
        },
      });

    case "PROMOTION":
      return createPromotionCampaign({
        target: getDefaultTargetRoot(false),
        name: name ?? `새 이벤트 페이지 ${moment().format("YYYY.MM.DD")}`,
        isActive: false,
        type: "PROMOTION",
        meta: {
          og: {
            type: "DEFAULT",
            title: `새 이벤트 페이지 ${moment().format("YYYY.MM.DD")}`,
            description: DEFAULT_PROMOTION_OG_DESCRIPTION,
            imageUrl: DEFUALT_OG_IMAGE_URL,
          },
        },
      });

    case "FORM_SURVEY":
      return createFormSurveyCampaign({
        target: getDefaultTargetRoot(false),
        name: name ?? `새 설문조사 ${moment().format("YYYY.MM.DD")}`,
        isActive: false,
        type: "FORM_SURVEY",
        meta: {
          og: {
            type: "DEFAULT",
            title: `새 설문조사 ${moment().format("YYYY.MM.DD")}`,
            description: DEFAULT_SURVEY_OG_DESCRIPTION,
            imageUrl: DEFUALT_OG_IMAGE_URL,
          },
        },
      });
  }
};

export const routerPushOnCampaignListFilterChange = ({
  router,
  newFilter,
}: {
  router: NextRouter;
  newFilter: Partial<ICampaignFilterV2>;
}) => {
  const filterJSON = getParamValue(router.query, "filter");
  const filter = JSON.parse(filterJSON || "{}") as ICampaignFilterV2;

  return router.push({
    query: {
      ...router.query,
      filter: JSON.stringify({
        ...filter,
        ...newFilter,
      }),
      page: "1",
    },
  });
};

/** 웹 빌더 스크립트 설치 가이드 */
export const builderCommonScriptGuideLink =
  "https://www.codenbutter.com/blog/%ec%b2%ab-%ec%82%ac%ec%9a%a9%ec%9e%90%eb%a5%bc-%ec%9c%84%ed%95%9c-%ec%bd%94%eb%93%9c%ec%95%a4%eb%b2%84%ed%84%b0-%ec%8b%9c%ec%9e%91-%ea%b0%80%ec%9d%b4%eb%93%9c/";

const siteTypeNameMap: Record<ExternalSiteType, string> = {
  MAKESHOP: "메이크샵",
  GODOMALL: "고도몰",
  IMWEB: "아임웹",
  OOPY: "우피",
  CAFE24: "카페24",
  SHOPBY: "샵바이",
  SHOPBY_PRO: "샵바이프로",
  SIXSHOP: "식스샵",
  SHOPIFY: "Shopify",
  WIX: "Wix",
  WORDPRESS: "워드프레스",
  NAVER_SMARTSTORE: "스마트스토어",
};

/**
 *
 * @param siteType
 * @returns siteType에 따른 사이트명을 반환
 */
export const getSiteTypeName = (siteType: ExternalSiteType) => {
  return siteTypeNameMap[siteType];
};

const videoGuideLinkMap: Record<ExternalSiteType, string> = {
  MAKESHOP: "https://youtu.be/agS-wHEmuio",
  GODOMALL: "https://youtu.be/-2yzHQecCt4",
  IMWEB: "https://youtu.be/Yof8aGh6Zdg",
  OOPY: "https://youtu.be/9Kf0pyNNNW0",
  CAFE24: "https://youtu.be/vq2nQHt87Eo",
  SHOPBY: "https://youtu.be/-2yzHQecCt4",
  SHOPBY_PRO: "https://youtu.be/-2yzHQecCt4",
  SIXSHOP: "https://youtu.be/7hLJNoZB-Xc",
  SHOPIFY: "https://youtu.be/zfwHu45rF5E",
  // TODO: 채워넣기
  WORDPRESS: "",
  NAVER_SMARTSTORE: "",
  WIX: "",
};

/**
 *
 * @param siteType
 * @returns siteType에 따른 영상 가이드 링크를 반환
 */
export const getVideoGuideLink = (siteType: ExternalSiteType) => {
  return videoGuideLinkMap[siteType];
};

const getNumberOfTargets_LT_V8 = (target: CampaignTargetType): number => {
  const innerTarget = target.type === "ROOT" ? target.value : target;
  let sum = 0;

  if (
    innerTarget &&
    "value" in innerTarget &&
    Array.isArray(innerTarget.value)
  ) {
    for (const v of innerTarget.value) {
      sum += typeof v === "string" ? 1 : getNumberOfTargets_LT_V8(v);
    }
  } else {
    sum += 1;
  }

  return sum;
};

const getNumberOfTargetsV8 = (target: CampaignTargetType): number => {
  if (target.type !== "ROOT") {
    return 0;
  }

  return (
    target.ui?.list?.reduce((sum, uiData) => {
      return sum + (uiData.list?.length ?? 0);
    }, 0) ?? 0
  );
};

export const getNumberOfTargets = (target: CampaignTargetType): number => {
  if (target.type === "ROOT" && target.version === "8") {
    return getNumberOfTargetsV8(target);
  }

  if (target.type === "ROOT" && target.version !== "8") {
    return getNumberOfTargets_LT_V8(target);
  }
  return 0;
};

export const isSameEventItems = (
  eventItems1: IEventItem[],
  eventItems2: IEventItem[]
) => {
  if (eventItems1.length !== eventItems2.length) {
    return false;
  }

  const eventIdMap = new Map<string | number, IEventItem>();
  for (const eventItem2 of eventItems2) {
    eventIdMap.set(eventItem2.id, eventItem2);
  }

  const keys = ["id", "start", "end", "resourceId", "title", "isDeleted"];
  for (let i = 0; i < eventItems1.length; i++) {
    const eventItem1 = eventItems1[i];
    const eventItem2 = eventIdMap.get(eventItem1?.id ?? "");

    if (!eventItem1 || !eventItem2) {
      return false;
    }

    const isSame = keys.every((prop) => {
      return (
        prop in eventItem1 &&
        prop in eventItem2 &&
        eventItem1[prop] === eventItem2[prop]
      );
    });

    if (!isSame) {
      return false;
    }
  }

  return true;
};

export const roleToString = (role?: string) => {
  if (role === "OWNER") {
    return t("role.owner");
  } else if (role === "MONITORING") {
    return (
      <span className="font-bold text-white bg-black">🚨모니터링중🚨</span>
    );
  } else {
    return t("role.member");
  }
};
