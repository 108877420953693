import { twMerge } from "tailwind-merge";

interface IScheduleRadioProps {
  selected: boolean;
  children: React.ReactNode;
  onClick: () => void;
  center?: boolean;
  gtmId: string;
}

const ScheduleRadio: React.FC<IScheduleRadioProps> = ({
  selected,
  children,
  onClick,
  center,
  gtmId,
}) => {
  return (
    <button
      type="button"
      className={twMerge(
        "flex items-center gap-2 px-4 bg-white border rounded-md min-w-[10rem] h-9 border-cb-stroke-default text-cb-gray-900 hover:border-cb-gray-400 transition-all",
        selected && "border-cb-gray-800 hover:border-cb-gray-800",
        center && "justify-center"
      )}
      onClick={onClick}
      data-gtm-id={gtmId}
    >
      <img
        src={`/ui/radio-${selected ? "on" : "off"}.png`}
        alt="radio button"
        className="w-4 h-4"
      />
      {children}
    </button>
  );
};

export default ScheduleRadio;
