import React from "react";

interface IWixIconProps {
  width?: number;
  height?: number;
}

const WixIcon: React.FC<IWixIconProps> = ({ width = 44, height = 17 }) => {
  return (
    <svg
      width={width}
      height={height}
      role="img"
      viewBox="0 0 44 17"
      xmlns="http://www.w3.org/2000/svg"
      style={{ fill: "#20303C" }}
    >
      <title>Wix</title>
      <defs>
        <path id="a" d="M3.698 0H.629v3.656h3.069V0z" />
        <path id="c" d="M0 0v16.971h22.884V0H0z" />
        <path id="e" d="M16.163 17V0H.596v17h15.567z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(23.347)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill="#20303C"
            d="M1.826.317C.955.771.63 1.537.63 3.656c0 0 .442-.425 1.097-.66a4.669 4.669 0 001.121-.606c.737-.536.85-1.226.85-2.39 0 0-1.2-.032-1.87.317"
            mask="url(#b)"
          />
        </g>
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          fill="#20303C"
          d="M20.002.813c-.71.623-.918 1.616-.918 1.616l-2.355 9.063-1.957-7.417c-.19-.79-.534-1.764-1.079-2.423-.693-.84-2.1-.892-2.25-.892-.15 0-1.56.052-2.252.892-.544.66-.888 1.634-1.078 2.423l-1.958 7.417L3.801 2.43s-.208-.993-.919-1.616C1.729-.197 0 .02 0 .02l4.508 16.937s1.487.107 2.232-.27c.977-.496 1.442-.878 2.034-3.182.527-2.055 2.001-8.09 2.138-8.52.069-.211.155-.719.53-.719.384 0 .463.507.53.72.135.43 1.612 6.464 2.139 8.52.592 2.303 1.057 2.685 2.034 3.18.744.378 2.231.271 2.231.271L22.884.02s-1.729-.217-2.882.793"
          mask="url(#d)"
        />
        <path
          fill="#20303C"
          d="M27.043 2.785s-.282.433-.925.79c-.413.23-.81.386-1.235.589-.713.34-.906.718-.906 1.295V16.957s1.138.143 1.881-.234c.958-.485 1.178-.953 1.186-3.059V2.785z"
        />
        <g transform="translate(27.837)">
          <mask id="f" fill="#fff">
            <use xlinkHref="#e" />
          </mask>
          <path
            fill="#20303C"
            d="M10.443 8.53l5.72-8.454s-2.414-.409-3.61.673c-.764.691-1.62 1.935-1.62 1.935L8.83 5.7c-.102.157-.235.328-.45.328-.212 0-.347-.171-.448-.328L5.826 2.684S4.971 1.44 4.206.75C3.011-.333.596.076.596.076l5.72 8.453-5.704 8.428s2.514.316 3.71-.766c.764-.692 1.504-1.816 1.504-1.816l2.105-3.016c.101-.156.236-.327.449-.327.214 0 .347.17.449.327l2.105 3.016s.784 1.124 1.549 1.816c1.195 1.082 3.665.766 3.665.766l-5.705-8.428z"
            mask="url(#f)"
          />
        </g>
      </g>
    </svg>
  );
};

export default WixIcon;
