import React from "react";

interface INhncommerceIconProps {
  width?: number;
  height?: number;
}

const NhncommerceIcon: React.FC<INhncommerceIconProps> = ({
  width = 620,
  height = 134,
}) => {
  return (
    <img
      src="/images/nhncommerce_logo.jpg"
      width={width}
      height={height}
      alt="nhncommerce"
    />
  );
};

export default NhncommerceIcon;
