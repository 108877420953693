import LoadingButton from "@/components/common/form/loading-button";
import { RESERVATION_COUNSELING } from "@/gql/site";
import { useMutation } from "@apollo/client";
import {
  IReservationCounselingMutation,
  IReservationCounselingMutationVariables,
} from "@shared/common/types/api-private/generated/graphql";
import React from "react";
import toast from "react-hot-toast";
import ScheduleRadio from "../common/schedule-radio";

interface IReservationCounselingGuide {
  onChangeStep: (step: number) => void;
  schedule: number;
  setSchedule: (schedule: number) => void;
  publicId: string;
}

const ReservationCounselingGuide: React.FC<IReservationCounselingGuide> = ({
  onChangeStep,
  schedule,
  setSchedule,
  publicId,
}) => {
  const [reservationCounseling, { loading }] = useMutation<
    IReservationCounselingMutation,
    IReservationCounselingMutationVariables
  >(RESERVATION_COUNSELING, {
    onError: (err) => {
      toast(
        err.message ||
          "요청을 보내는 중 오류가 발생했습니다. 잠시 후에 다시 시도해주세요."
      );
    },
  });

  const handleReservationCounselingClick = async () => {
    const message =
      schedule === 1
        ? "아무때나 좋아요"
        : schedule === 2
        ? "09:00 ~ 11:00"
        : schedule === 3
        ? "11:00 ~ 14:00"
        : "14:00 ~ 18:00";

    await reservationCounseling({
      variables: { sitePublicId: publicId, message },
    });
    onChangeStep(3);
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="text-center">
          <div className="flex justify-center gap-4">
            <ScheduleRadio
              selected={schedule === 1}
              onClick={() => setSchedule(1)}
              gtmId="consulting_schedule_anytime"
            >
              아무때나 좋아요
            </ScheduleRadio>
            <ScheduleRadio
              selected={schedule === 2}
              onClick={() => setSchedule(2)}
              gtmId="consulting_schedule_09_11"
            >
              09:00 ~ 11:00
            </ScheduleRadio>
          </div>
          <div className="flex justify-center gap-4 mt-4">
            <ScheduleRadio
              selected={schedule === 3}
              onClick={() => setSchedule(3)}
              gtmId="consulting_schedule_11_14"
            >
              11:00 ~ 14:00
            </ScheduleRadio>
            <ScheduleRadio
              selected={schedule === 4}
              onClick={() => setSchedule(4)}
              gtmId="consulting_schedule_14_18"
            >
              14:00 ~ 18:00
            </ScheduleRadio>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-end gap-4 mt-8">
        <LoadingButton
          theme="primary"
          disabled={!schedule}
          onClick={handleReservationCounselingClick}
          loading={loading}
          className="min-w-[6rem]"
          block
          data-gtm-id="consulting_booking_btn"
        >
          상담 예약하기
        </LoadingButton>
      </div>
    </>
  );
};

export default React.memo(ReservationCounselingGuide);
