import type { NextRouter } from "next/router";

/**
 * router.replace 를 한다고 해서 router.query가 즉시 변경되는 게 아니므로
 * 변경사항을 모아 한번에 적용시키기 위한 중간 관리자.
 * 값을 설정하더라도 기존 쿼리 값은 영향을 미치지 않습니다.
 * 특정 쿼리스트링을 없애려면 null 로 설정하세요.
 * router.replace 의 option은 undefined, transitionOptions 는  { shallow: true } 입니다.
 */
export const createRouterQuerySetter = () => {
  const timeouts: ReturnType<typeof setTimeout>[] = [];

  const resolvers: ((result: boolean) => void)[] = [];

  let pendingNewQuery: NextRouter["query"] | null = null;

  return {
    timeouts,

    resolvers,

    set(router: NextRouter, query: { [key: string]: string | null }) {
      const newQuery = pendingNewQuery || { ...router.query };
      Object.keys(query).forEach((key) => {
        const value = query[key];
        if (value === null) {
          delete newQuery[key];
        } else {
          newQuery[key] = value;
        }
      });

      if (!pendingNewQuery) {
        pendingNewQuery = newQuery;
      }

      return new Promise<boolean>((newResolve) => {
        const newTimeout = setTimeout(() => {
          if (!this.resolvers[0]) {
            return;
          }

          const resolversPhased = [...this.resolvers];
          this.resolvers = [];

          this.timeouts.forEach((timeout) => {
            clearTimeout(timeout);
          });
          this.timeouts = [];

          // TODO: catch 추가
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          router
            .replace({ query: pendingNewQuery }, undefined, { shallow: true })
            .then((result) => {
              resolversPhased.forEach((resolve) => {
                resolve(result);
              });
            });

          pendingNewQuery = null;
        }, 1);

        this.timeouts.push(newTimeout);
        this.resolvers.push(newResolve);
      });
    },
  };
};

export const routerQuerySetter = createRouterQuerySetter();
