import { useOrgAndsiteContext } from "@/context";
import { createPortal } from "react-dom";

const GTMCurrentTeam: React.FC = () => {
  const { currentOrganization } = useOrgAndsiteContext();
  const name = currentOrganization?.name ?? "";
  const slug = currentOrganization?.slug ?? "";
  const portal =
    typeof window !== "undefined"
      ? createPortal(
          <>
            <div className="sr-only" aria-hidden id="gtm-team-name">
              {name}
            </div>
            <div className="sr-only" aria-hidden id="gtm-team-id">
              {slug}
            </div>
          </>,
          document.body
        )
      : null;

  return portal;
};

export default GTMCurrentTeam;
