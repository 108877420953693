import { createPosition } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { IFormRatingScaleNode, PartialNode } from "../type";

const createFormRatingScaleNode = (
  data?: PartialNode<IFormRatingScaleNode>
): IFormRatingScaleNode => {
  return {
    type: "FORM_RATING_SCALE",
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    reactions: [],
    position: createPosition(data?.position),

    name: "",

    width: { value: 100, unit: "%" },

    fills: [],

    locked: false,

    strokes: [],
    strokeWeight: 1,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    visible: true,

    effects: [],

    label: "<p>새 항목</p>",
    required: true,
    min: 1,
    range: 5,
    description: { left: "매우 불만족", right: "매우 만족" },

    ...data,
  };
};

export default createFormRatingScaleNode;
