import React from "react";

interface IImwebIconProps {
  width?: number;
  height?: number;
}

const ImwebIcon: React.FC<IImwebIconProps> = ({ width = 176, height = 54 }) => {
  return (
    <img
      src="/images/imweb_new_logo_blue_88.png"
      width={width}
      height={height}
      alt="imweb"
    />
  );
};

export default ImwebIcon;
