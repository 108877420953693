import LoadingButton from "@/components/common/form/loading-button";
import Tab from "@/components/common/ui/tab";
import SiteTypeIcon from "@/components/site/site-type-icon";
import V2CodeBlock from "@/components/v2/common/v2-code-block";
import OnboardingMessage from "@/components/v2/team/onboarding/onboarding-message";
import { useOrgAndsiteContext } from "@/context";
import { GET_ORGANIZATIONS, UPDATE_ORGANIZATION } from "@/gql/organization";
import { REQUEST_INSTALL_SCRIPT } from "@/gql/site";
import {
  builderCommonScriptGuideLink,
  getCampaignCodeGuideProps,
  getSiteTypeName,
  getVideoGuideLink,
} from "@/utils/common";
import { useMutation } from "@apollo/client";
import {
  IRequestInstallScriptMutation,
  IRequestInstallScriptMutationVariables,
  IUpdateOrganizationMutation,
  IUpdateOrganizationMutationVariables,
} from "@shared/common/types/api-private/generated/graphql";
import { ExternalSiteType } from "@shared/common/types/site";
import { Form, Input } from "antd";
import { ExternalLink } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import FormLabel from "../../auth/form-label";
import ScheduleRadio from "../../common/schedule-radio";
import V2Tooltip from "../../common/v2-tooltip";
import ExcelamationMark from "../../icon/v2-excelamation-mark";
import WordpressOnboardingScript from "./wordpress-onboarding-script";

interface IOnboardingScriptProps {
  prefixGtmId: string;
  publicId: string;
  siteType?: ExternalSiteType | null;
  onFinish: () => void;
  nextText?: string;
  initialSiteUrl?: string;
  isTeamOnboarding?: boolean;
}

interface INeedsHelpFormValues {
  id: string;
  password: string;
}

// TODO: 각 nhn, makeshop 등 온보딩 스크립트 수정
const OnboardingScript = (props: IOnboardingScriptProps) => {
  const { currentSite } = useOrgAndsiteContext();

  switch (props.siteType) {
    case "WORDPRESS":
      return (
        <WordpressOnboardingScript
          initialSiteUrl={currentSite?.url}
          {...props}
        />
      );
    default:
      return <DefaultOnboardingScript {...props} />;
  }
};

export default OnboardingScript;

const DefaultOnboardingScript = ({
  prefixGtmId,
  publicId,
  siteType,
  onFinish,
  nextText = "확인",
  isTeamOnboarding,
}: IOnboardingScriptProps) => {
  const [schedule, setSchedule] = useState(1);
  const [tab, setTab] = useState(0);
  const [form] = Form.useForm<INeedsHelpFormValues>();

  const [updateTeam] = useMutation<
    IUpdateOrganizationMutation,
    IUpdateOrganizationMutationVariables
  >(UPDATE_ORGANIZATION, { refetchQueries: [GET_ORGANIZATIONS] });

  const { currentOrganization } = useOrgAndsiteContext();

  const [requestInstall, { loading }] = useMutation<
    IRequestInstallScriptMutation,
    IRequestInstallScriptMutationVariables
  >(REQUEST_INSTALL_SCRIPT, {
    onError: (err) => {
      toast(
        err.message ||
          "요청을 보내는 중 오류가 발생했습니다. 잠시 후에 다시 시도해주세요."
      );
    },
  });

  useEffect(() => {
    if (siteType) {
      setTab(1);
    }
  }, [siteType]);

  const handleFormFinish = async (val: INeedsHelpFormValues) => {
    const message = `
아이디: ${val.id}
비밀번호: ${val.password}`;
    const ret = await requestInstall({
      variables: { sitePublicId: publicId, message },
    });

    if (ret.data?.requestInstallScript) {
      toast("설치를 요청하였습니다.");
      if (isTeamOnboarding && currentOrganization) {
        await updateTeam({
          variables: {
            organizationId: currentOrganization.id,
            object: { onboardStep: 40 },
          },
        });
      }
      onFinish();
    }
  };

  const handleRequestInstall = useCallback(async () => {
    if (siteType) {
      form.submit();
    } else {
      const message =
        schedule === 1
          ? "아무때나 좋아요"
          : schedule === 2
          ? "09:00 ~ 11:00"
          : schedule === 3
          ? "11:00 ~ 14:00"
          : "14:00 ~ 18:00";
      const ret = await requestInstall({
        variables: { sitePublicId: publicId, message },
      });

      if (ret.data?.requestInstallScript) {
        toast("설치를 요청하였습니다. 요청하신 시간에 연락드리겠습니다.");
        if (isTeamOnboarding && currentOrganization) {
          await updateTeam({
            variables: {
              organizationId: currentOrganization.id,
              object: { onboardStep: 40 },
            },
          });
        }

        onFinish();
      }
    }
  }, [
    currentOrganization,
    form,
    isTeamOnboarding,
    onFinish,
    publicId,
    requestInstall,
    schedule,
    siteType,
    updateTeam,
  ]);

  const { code, language } = getCampaignCodeGuideProps(
    "COMMON",
    publicId,
    siteType || undefined
  );

  const NeedsHelpRender = () => {
    return siteType ? (
      <div className="text-center">
        <div className="flex items-center justify-center gap-1">
          <span className="font-semibold">
            어려우신가요? 코드앤버터에게 맡겨주세요!
          </span>
          <V2Tooltip
            title="스크립트 설치 지원 용도로만 사용되며 모든 계정 정보는 보안을 위해 암호화 처리되어 저장됩니다. (사용 후 파기됩니다)"
            zIndex={99999}
          >
            <div>
              <ExcelamationMark />
            </div>
          </V2Tooltip>
        </div>
        <div className="mt-1 text-cb-gray-500">
          고객님의 {getSiteTypeName(siteType)} 운영자 계정을 기입해주시면
          <br />
          근무일 기준 2-3일 내로 설치가 완료됩니다.
        </div>
        <div className="mt-8 text-left">
          <Form
            form={form}
            requiredMark={false}
            layout="vertical"
            initialValues={{ id: "", password: "" }}
            onFinish={handleFormFinish}
          >
            <Form.Item
              name="id"
              label={<FormLabel label="아이디" required />}
              className="mb-6"
              rules={[
                {
                  required: true,
                  message: "아이디를 입력해주세요.",
                },
              ]}
            >
              <Input
                placeholder="아이디를 입력해주세요."
                className="w-full text-base text-black"
              />
            </Form.Item>

            <Form.Item
              name="password"
              label={<FormLabel label="비밀번호" required />}
              className="mb-6"
              rules={[
                {
                  required: true,
                  message: "비밀번호를 입력해주세요.",
                },
              ]}
            >
              <Input
                placeholder="비밀번호를 입력해주세요."
                className="w-full text-base text-black"
                type="password"
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    ) : (
      <div className="text-center">
        <div>
          <span className="font-semibold">
            어려우신가요? 코드앤버터에게 맡겨주세요!
          </span>
        </div>
        <div className="mt-1 text-cb-gray-500">
          고객님의 연락처로 설치 도움 관련{" "}
          <span className="font-semibold underline">SMS</span>을 전달드립니다.
        </div>
        <div className="mt-8">
          <div className="flex justify-center gap-4">
            <ScheduleRadio
              selected={schedule === 1}
              onClick={() => setSchedule(1)}
              gtmId={`${prefixGtmId}_script_schedule_anytime`}
            >
              아무때나 좋아요
            </ScheduleRadio>
            <ScheduleRadio
              selected={schedule === 2}
              onClick={() => setSchedule(2)}
              gtmId={`${prefixGtmId}_script_schedule_09_11`}
            >
              09:00 ~ 11:00
            </ScheduleRadio>
          </div>
          <div className="flex justify-center gap-4 mt-4">
            <ScheduleRadio
              selected={schedule === 3}
              onClick={() => setSchedule(3)}
              gtmId={`${prefixGtmId}_script_schedule_11_14`}
            >
              11:00 ~ 14:00
            </ScheduleRadio>
            <ScheduleRadio
              selected={schedule === 4}
              onClick={() => setSchedule(4)}
              gtmId={`${prefixGtmId}_script_schedule_14_18`}
            >
              14:00 ~ 18:00
            </ScheduleRadio>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={tab === 0 ? "w-104" : "w-126"}>
      <OnboardingMessage
        message={
          <div>
            <div className="text-center">사이트에 스크립트를 추가하여</div>
            <div className="text-center">
              팝업 또는 배너를 추가하고 고객 데이터를 분석합니다.
            </div>
          </div>
        }
      />
      <div className="mt-8">
        <Tab fullWidth={true}>
          <Tab.Label
            data-gtm-id={`${prefixGtmId}_script_consult`}
            selected={tab === 0}
            onClick={() => setTab(0)}
          >
            맡겨주세요
          </Tab.Label>
          <Tab.Label
            data-gtm-id={`${prefixGtmId}_script_self`}
            selected={tab === 1}
            onClick={() => setTab(1)}
          >
            <div className="relative">
              <div
                className={twMerge(
                  "absolute left-0 right-0 mx-auto w-fit bottom-8 hidden",
                  tab === 0 && "block"
                )}
              >
                <div className="relative flex items-center gap-2 py-1 px-2 bg-black opacity-[0.88] rounded-sm shadow-lg z-50">
                  <p className="text-sm text-cb-brand">생각보다 간단해요!</p>
                </div>
                <div className="absolute w-0 h-0 border-t-[8px] border-x-[8px] border-x-transparent border-t-black border-opacity-[0.88] -bottom-[7px] left-0 right-0 mx-auto" />
              </div>
              혼자 해볼래요
            </div>
          </Tab.Label>
        </Tab>

        <div className="flex flex-col justify-center p-8 mt-1 bg-cb-gray-50">
          {tab === 0 ? (
            <NeedsHelpRender />
          ) : (
            <>
              {siteType && (
                <div className="flex my-16 center-center">
                  <SiteTypeIcon siteType={siteType} width={80} height={80} />
                </div>
              )}
              <div className="flex flex-col gap-4">
                {/* TODO: 추후 영상 제작되는대로 !== siteType 조건 제거 */}
                {siteType &&
                  siteType !== "WORDPRESS" &&
                  siteType !== "NAVER_SMARTSTORE" &&
                  siteType !== "WIX" && (
                    <div className="flex center-center">
                      <div className="relative">
                        <div className="absolute left-0 right-0 mx-auto w-fit bottom-12">
                          <div className="relative flex items-center gap-2 py-1 px-2 bg-black opacity-[0.88] rounded-sm shadow-lg z-50">
                            <p className="text-sm text-cb-brand">
                              1분안에 쉽게 따라하기
                            </p>
                          </div>
                          <div className="absolute w-0 h-0 border-t-[8px] border-x-[8px] border-x-transparent border-t-black border-opacity-[0.88] -bottom-[7px] left-0 right-0 mx-auto" />
                        </div>

                        <a
                          href={getVideoGuideLink(siteType)}
                          target="_blank"
                          data-gtm-id={`${prefixGtmId}_script_${siteType.toLocaleLowerCase()}_video_guide`}
                          className="inline-flex w-fit gap-2 px-4 border rounded-md center-center h-9 border-cb-gray-900 min-w-[209px]"
                        >
                          {getSiteTypeName(siteType)} 영상 가이드
                          <ExternalLink className="w-4 h-4" />
                        </a>
                      </div>
                    </div>
                  )}
                <div className="flex center-center">
                  <a
                    href={builderCommonScriptGuideLink}
                    target="_blank"
                    data-gtm-id={`${prefixGtmId}_script_guide`}
                    className="inline-flex w-fit gap-2 px-4 border rounded-md center-center h-9 border-cb-gray-900 min-w-[209px]"
                  >
                    {siteType ? getSiteTypeName(siteType) : ""} 가이드 바로가기
                    <ExternalLink className="w-4 h-4" />
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
        {tab === 1 ? (
          <div className="text-center">
            <div className="mt-1 text-cb-gray-500">
              아래 스크립트를 설치해 주세요.
            </div>
            <div className="mt-8 text-left">
              <V2CodeBlock language={language} value={code} />
            </div>
            {!siteType ? (
              <ol className="flex flex-col gap-1 pl-4 mt-4 text-left list-decimal">
                <li>HTML 코드에서 head tag를 찾습니다. (&lt;HEAD&gt;)</li>
                <li>
                  코드앤버터 스크립트를 복사하고 head 태그 사이에 입력
                  합니다.(&lt;HEAD&gt;와 &lt;/HEAD&gt; 사이)
                </li>
                <li>수정한 사항을 저장합니다.</li>
                <li>
                  이제 웹페이지를 새로고침하면 작성한 캠페인을 확인할 수
                  있습니다.
                </li>
              </ol>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="mt-8">
        {tab === 0 ? (
          <>
            <LoadingButton
              theme="primary"
              block
              loading={loading}
              onClick={handleRequestInstall}
              data-gtm-id={`${prefixGtmId}_script_request_install_script`}
            >
              설치 요청
            </LoadingButton>
            <LoadingButton
              theme="text"
              className="mt-1"
              block
              size="small"
              onClick={onFinish}
              data-gtm-id={`${prefixGtmId}_script_later_install_script`}
            >
              다음에 설치하기
            </LoadingButton>
          </>
        ) : (
          <LoadingButton
            data-gtm-id={`${prefixGtmId}_script_self_install_script_start`}
            theme="primary"
            block
            onClick={onFinish}
          >
            {nextText}
          </LoadingButton>
        )}
      </div>
    </div>
  );
};
