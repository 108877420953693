import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient() {
  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_PRIVATE_API_ENDPOINT,
    credentials: "include",
  });

  apolloClient = new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: httpLink, //authLink.concat(httpLink),
    cache: new InMemoryCache({}),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });

  return apolloClient;
}

export const getPrivateApiClient = () => {
  return apolloClient ?? createApolloClient();
};
