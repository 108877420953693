import enCampaignJson from "@/public/locales/en/campaign.json";
import enCommonJson from "@/public/locales/en/common.json";
import enDashboardJson from "@/public/locales/en/dashboard.json";
import enEditorJson from "@/public/locales/en/editor.json";
import enGnbJson from "@/public/locales/en/gnb.json";
import enListJson from "@/public/locales/en/list.json";
import enLnbJson from "@/public/locales/en/lnb.json";
import enPopupJson from "@/public/locales/en/popup.json";
import enSettingJson from "@/public/locales/en/setting.json";

import koCampaignJson from "@/public/locales/ko/campaign.json";
import koCommonJson from "@/public/locales/ko/common.json";
import koDashboardJson from "@/public/locales/ko/dashboard.json";
import koEditorJson from "@/public/locales/ko/editor.json";
import koGnbJson from "@/public/locales/ko/gnb.json";
import koListJson from "@/public/locales/ko/list.json";
import koLnbJson from "@/public/locales/ko/lnb.json";
import koPopupJson from "@/public/locales/ko/popup.json";
import koSettingJson from "@/public/locales/ko/setting.json";

import jpCampaignJson from "@/public/locales/jp/campaign.json";
import jpCommonJson from "@/public/locales/jp/common.json";
import jpDashboardJson from "@/public/locales/jp/dashboard.json";
import jpEditorJson from "@/public/locales/jp/editor.json";
import jpGnbJson from "@/public/locales/jp/gnb.json";
import jpListJson from "@/public/locales/jp/list.json";
import jpLnbJson from "@/public/locales/jp/lnb.json";
import jpPopupJson from "@/public/locales/jp/popup.json";
import jpSettingJson from "@/public/locales/jp/setting.json";

import { setCookie } from "cookies-next";
import i18n from "i18next";
import { NextRouter } from "next/router";
import { initReactI18next } from "react-i18next";

export const langueges = [
  { label: "한국어", value: "ko", beta: false },
  { label: "English", value: "en", beta: true },
  { label: "日本語", value: "jp", beta: true },
];

export const getLanguage = (locale?: string) => {
  const lang = langueges.find((language) => language.value === locale);

  return lang ? lang.label : "한국어";
};

export const setLanguage = (locale: string, router: NextRouter) => {
  const { pathname, query, asPath } = router;
  setCookie("NEXT_LOCALE", locale, { maxAge: 60 * 60 * 24 * 365 });
  router.push({ pathname, query }, asPath, {
    locale,
  });
};

i18n.use(initReactI18next).init({
  defaultNS: "common",
  resources: {
    en: {
      ...enCommonJson,
      ...enDashboardJson,
      ...enGnbJson,
      ...enPopupJson,
      ...enListJson,
      ...enLnbJson,
      ...enEditorJson,
      ...enCampaignJson,
      ...enSettingJson,
    },
    ko: {
      ...koCommonJson,
      ...koDashboardJson,
      ...koGnbJson,
      ...koPopupJson,
      ...koListJson,
      ...koLnbJson,
      ...koEditorJson,
      ...koCampaignJson,
      ...koSettingJson,
    },
    jp: {
      ...jpCommonJson,
      ...jpDashboardJson,
      ...jpGnbJson,
      ...jpPopupJson,
      ...jpListJson,
      ...jpLnbJson,
      ...jpEditorJson,
      ...jpCampaignJson,
      ...jpSettingJson,
    },
  },
  lng: "ko",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
