export const geolocationTargetData: {
  [key: string]: { geonameId: string; koName: string; name: string }[];
} = {
  KR: [
    { geonameId: "ALL", koName: "전체", name: "All" },
    { geonameId: "1835847", koName: "서울특별시", name: "Seoul" },
    { geonameId: "1843561", koName: "인천광역시", name: "Incheon" },
    { geonameId: "1835224", koName: "대전광역시", name: "Daejeon" },
    { geonameId: "1841808", koName: "광주광역시", name: "Gwangju" },
    { geonameId: "1835327", koName: "대구광역시", name: "Daegu" },
    { geonameId: "1833742", koName: "울산광역시", name: "Ulsan" },
    { geonameId: "1838519", koName: "부산광역시", name: "Busan" },
    { geonameId: "1841610", koName: "경기도", name: "Gyeonggi-do" },
    { geonameId: "1843125", koName: "강원도", name: "Gangwon" },
    { geonameId: "1845106", koName: "충청북도", name: "Chungcheongbuk-do" },
    { geonameId: "1845105", koName: "충청남도", name: "Chungcheongnam-do" },
    { geonameId: "1845789", koName: "전라북도", name: "Jeollabuk-do" },
    { geonameId: "1845788", koName: "전라남도", name: "Jeollanam-do" },
    { geonameId: "1841597", koName: "경상북도", name: "Gyeongsangbuk-do" },
    { geonameId: "1902028", koName: "경상남도", name: "Gyeongsangnam-do" },
    { geonameId: "1846265", koName: "제주도", name: "Jeju-do" },
    { geonameId: "8394437", koName: "세종특별자치시", name: "Sejong-si" },
  ],
};
