import { gql } from "@apollo/client";

export const GET_SITE_META_INFO = gql`
  query GetSiteMetaInfo($url: String!) {
    siteMetaInfo(url: $url) {
      url
      icon
      name
      siteType
      siteInfo
    }
  }
`;

const SITE_WITH_CAMPAIGN_COUNT_FIELDS = gql`
  fragment SiteWithCampaignCountFields on SiteWithCampaignCount {
    id
    publicId
    organizationId
    name
    icon
    url
    siteType
    siteInfo
    option
    createdAt
    updatedAt
    plan
    siteType
    siteInfo
    campaignCount
    activeCampaignCount
    isInstalled
    installedAt
    isPublished
    publishedAt
    attribution
    setting
  }
`;

const SITE_FIELDS = gql`
  fragment SiteFields on Site {
    id
    publicId
    organizationId
    name
    icon
    url
    siteType
    siteInfo
    option
    createdAt
    updatedAt
    plan
    siteType
    siteInfo
    isInstalled
    installedAt
    isPublished
    publishedAt
    attribution
    setting
  }
`;

const CAFE24TOKEN_FIELDS = gql`
  fragment Cafe24TokenFields on Cafe24Token {
    id
    mallId
    siteId
  }
`;

export const GET_SITES = gql`
  ${SITE_WITH_CAMPAIGN_COUNT_FIELDS}
  query GetSites($organizationId: ID!) {
    sites(organizationId: $organizationId) {
      ...SiteWithCampaignCountFields
      siteIntegration {
        tokenId
        type
      }
    }
  }
`;

export const GET_SITES_BY_ORG_SLUG = gql`
  ${SITE_WITH_CAMPAIGN_COUNT_FIELDS}
  query GetSitesByOrgSlug($organizationSlug: String!) {
    sitesByOrgSlug(organizationSlug: $organizationSlug) {
      ...SiteWithCampaignCountFields
      siteIntegration {
        tokenId
        type
      }
    }
  }
`;

export const GET_SITE = gql`
  ${SITE_FIELDS}
  query GetSite($siteId: ID!) {
    site(siteId: $siteId) {
      ...SiteFields
    }
  }
`;

export const CREATE_SITE = gql`
  ${SITE_FIELDS}
  mutation CreateSite($organizationId: ID!, $object: SiteInput!) {
    createSite(organizationId: $organizationId, object: $object) {
      ...SiteFields
    }
  }
`;

export const CONNECT_CAFE24_TOKEN = gql`
  ${CAFE24TOKEN_FIELDS}
  mutation ConnectCafe24Token($sitePublicId: String!) {
    connectCafe24Token(sitePublicId: $sitePublicId) {
      ...Cafe24TokenFields
    }
  }
`;

export const INSTALL_NHN_SCRIPT = gql`
  mutation InstallNhnScript($sitePublicId: String!) {
    installNhnScript(sitePublicId: $sitePublicId) {
      id
    }
  }
`;

export const UPDATE_SITE = gql`
  ${SITE_FIELDS}
  mutation UpdateSite($siteId: ID!, $object: SiteInput!) {
    updateSite(siteId: $siteId, object: $object) {
      ...SiteFields
    }
  }
`;

export const DELETE_SITE = gql`
  mutation DeleteSite($siteId: ID!) {
    deleteSite(siteId: $siteId) {
      id
    }
  }
`;

export const REQUEST_INSTALL_SCRIPT = gql`
  ${SITE_FIELDS}
  mutation RequestInstallScript($sitePublicId: String!, $message: String!) {
    requestInstallScript(sitePublicId: $sitePublicId, message: $message) {
      ...SiteFields
    }
  }
`;

export const RESERVATION_COUNSELING = gql`
  ${SITE_FIELDS}
  mutation reservationCounseling($sitePublicId: String!, $message: String!) {
    reservationCounseling(sitePublicId: $sitePublicId, message: $message) {
      ...SiteFields
    }
  }
`;

const CATEGORY_FIELDS = gql`
  fragment CategoryFields on Category {
    id
    name
    description
    icon
  }
`;

export const GET_CATEGORIES = gql`
  ${CATEGORY_FIELDS}
  query GetCategories($sitePublicId: String!) {
    categories(sitePublicId: $sitePublicId) {
      ...CategoryFields
    }
  }
`;

export const CREATE_EASY_CANVAS_TOKEN = gql`
  mutation CreateEasyCanvasCode($sitePublicId: String!) {
    createEasyCanvasCode(sitePublicId: $sitePublicId)
  }
`;
