import { useOrgAndsiteContext } from "@/context";
import { createPortal } from "react-dom";

const GTMCurrentSite: React.FC = () => {
  const { currentSite } = useOrgAndsiteContext();
  const name = currentSite?.name ?? "";
  const publicId = currentSite?.publicId ?? "";
  const portal =
    typeof window !== "undefined"
      ? createPortal(
          <>
            <div className="sr-only" aria-hidden id="gtm-site-name">
              {name}
            </div>
            <div className="sr-only" aria-hidden id="gtm-site-id">
              {publicId}
            </div>
          </>,
          document.body
        )
      : null;

  return portal;
};

export default GTMCurrentSite;
