import Loader from "@/components/common/ui/loader";
import { USER_INPUT_STEP } from "@shared/common/constant/auth";
import { getParamValue } from "@shared/common/utils/url";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";

// const FALLBACK_SITE_ID = "temp";

const v2UrlPrefix = process.env.NEXT_PUBLIC_V2_URL_PREFIX;

interface IFallbackRouterProps {
  children: ReactNode;
}

const noAuthWhitelist = [
  "/login",
  "/sign-up",
  "/reset-password",
  "/info/changelog",
  "/info/guide",
  "/agree/terms-of-service",
  "/agree/privacy-policy",
  "/agree/privacy-policy/20230615", // 추후에 이력 관리 들어가게 되면 하위 경로 패스 와일드카드 처리 필요
  "/i/cafe24/sign-up",
  "/i/cafe24/existing-login",
  `${v2UrlPrefix}/login`,
  `${v2UrlPrefix}/login/external`,
  `${v2UrlPrefix}/seeyou`,
  `${v2UrlPrefix}/signup/email`,
  `${v2UrlPrefix}/find-password`,
  `${v2UrlPrefix}/find-email`,
];

const noAuthWhitelistRegexList = [/^\/agree\/privacy-policy.*/];

const FallbackRouter = ({ children }: IFallbackRouterProps) => {
  const router = useRouter();

  const { data: session, status: sessionStatus } = useSession();

  const [userStatus, setUserStatus] = useState<
    | { type: "unknown" }
    | { type: "valid"; loggedIn: boolean }
    | { type: "invalid"; loggedIn: boolean; redirectTo: string }
  >({ type: "unknown" });

  // 쿼리스트링으로 open_channeltalk을 입력해서 들어온 대상에게 채널톡을 열어서 보여준다.
  useEffect(() => {
    if (getParamValue(router.query, "open_channeltalk") === "true") {
      window.ChannelIO("openChat");
    }
  }, [router.query]);

  // 사용자의 상태에 따라 userStatus 를 설정
  useEffect(() => {
    if (sessionStatus === "loading") {
      return;
    }

    if (session?.user) {
      // 기존 로그인 사용자정보 입력 페이지로 이동
      if (session.user.onboardStep === 100) {
        const providerType =
          session.user.provider === "email" ? "email" : "social";
        const migrateRoute = `${v2UrlPrefix}/migrate/${providerType}`;
        const userInfoStepQuery = `step=${USER_INPUT_STEP.USER_INFO}`;
        const additionalInfoStepQuery = `step=${USER_INPUT_STEP.ADDITIONAL_INFO}`;

        if (!session.user.hasUserInfo) {
          setUserStatus({
            type: "invalid",
            loggedIn: true,
            redirectTo: `${migrateRoute}?${userInfoStepQuery}`,
          });
          return;
        } else if (!session.user.hasAdditionalInfo) {
          setUserStatus({
            type: "invalid",
            loggedIn: true,
            redirectTo: `${migrateRoute}?${additionalInfoStepQuery}`,
          });
          return;
        }
      }

      // 신규 간편 로그인 사용자정보 입력 페이지로 이동
      else if (session.user.onboardStep === 10) {
        const signupRoute = `${v2UrlPrefix}/signup/social`;
        const agreementStepQuery = `step=${USER_INPUT_STEP.AGREEMENT}`;
        const userInfoStepQuery = `step=${USER_INPUT_STEP.USER_INFO}`;
        const additionalInfoStepQuery = `step=${USER_INPUT_STEP.ADDITIONAL_INFO}`;

        if (!session.user.agreedPrivacy || !session.user.agreedTerms) {
          setUserStatus({
            type: "invalid",
            loggedIn: true,
            redirectTo: `${signupRoute}?${agreementStepQuery}`,
          });
          return;
        } else if (!session.user.hasUserInfo) {
          setUserStatus({
            type: "invalid",
            loggedIn: true,
            redirectTo: `${signupRoute}?${userInfoStepQuery}`,
          });
          return;
        } else if (!session.user.hasAdditionalInfo) {
          setUserStatus({
            type: "invalid",
            loggedIn: true,
            redirectTo: `${signupRoute}?${additionalInfoStepQuery}`,
          });
          return;
        }
        // eslint-disable-next-line no-console
        console.log(
          "알 수 없는 이유로 사용자의 onboardStep 이 10 입니다.",
          session.user
        );
        return;
      }

      setUserStatus({ type: "valid", loggedIn: true });
    } else {
      setUserStatus({ type: "valid", loggedIn: false });
    }
  }, [router, session?.user, sessionStatus]);

  // 사용자의 상태에 따라 적절한 곳으로 redirect
  useEffect(() => {
    if (userStatus.type === "unknown") {
      return;
    }

    // 로그인을 안한 상태에서 세션이 필요한 페이지인 경우
    if (
      userStatus.type === "valid" &&
      !userStatus.loggedIn &&
      !noAuthWhitelist.includes(router.pathname) &&
      !noAuthWhitelistRegexList.some((regex) => regex.test(router.pathname))
    ) {
      router.replace(
        `${v2UrlPrefix}/login?redirect_url=${encodeURIComponent(router.asPath)}`
      );
      return;
    }

    // 사용자의 상태가 불완전한 경우
    if (userStatus.type === "invalid") {
      const currentUrl = new URL(window.location.href);
      const redirectUrl = new URL(
        userStatus.redirectTo,
        window.location.origin
      );
      currentUrl.searchParams.delete("redirect_url");
      redirectUrl.searchParams.delete("redirect_url");

      if (currentUrl.toString() === redirectUrl.toString()) {
        return;
      }

      redirectUrl.searchParams.set("redirect_url", currentUrl.toString());
      router.replace(redirectUrl.toString());
      return;
    }

    // userStatus 자체만 보면 의도되지 않은 리렌더링이 발생할 수 있음.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, userStatus.type]);

  // invalid 상태인데 redirectUrl로 왔을 경우 로딩을 하지 않고 렌더링
  if (userStatus.type === "invalid") {
    const currentUrl = new URL(window.location.href);
    const redirectUrl = new URL(userStatus.redirectTo, window.location.origin);
    currentUrl.searchParams.delete("redirect_url");
    redirectUrl.searchParams.delete("redirect_url");
    if (currentUrl.toString() === redirectUrl.toString()) {
      return <>{children}</>;
    }
  }

  if (userStatus.type !== "valid") {
    return <Loader size="large" />;
  }

  return <>{children}</>;
};

export default FallbackRouter;
