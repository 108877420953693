import React from "react";

interface IShopbyIconProps {
  width?: number;
  height?: number;
}

const ShopbyIcon: React.FC<IShopbyIconProps> = ({
  width = 199,
  height = 52,
}) => {
  return (
    <img
      src="/images/shopby_logo.png"
      width={width}
      height={height}
      alt="shopby"
    />
  );
};

export default ShopbyIcon;
