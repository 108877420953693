import { getDataLayer } from "@shared/common/utils/gtm";
import { useSession } from "next-auth/react";
import React, { useEffect, useRef } from "react";

interface IGTMUserIdConnector {}

const GTMUserIdConnector: React.FC<IGTMUserIdConnector> = () => {
  const { data: session } = useSession();
  const userIdConnected = useRef(false);

  useEffect(() => {
    if (session?.user && userIdConnected.current === false) {
      userIdConnected.current = true;

      getDataLayer().push({
        event: "user_login",
        userId: session.user.id,
      });
    }
  }, [session]);

  return null;
};

export default GTMUserIdConnector;
