import React from "react";

interface IOopyIconProps {
  width?: number;
  height?: number;
}

const OopyIcon: React.FC<IOopyIconProps> = ({ width = 26, height = 26 }) => {
  return (
    <img
      width={width}
      height={height}
      src="/install-guide/oopy.png"
      alt="oopy"
    />
  );
};

export default React.memo(OopyIcon);
