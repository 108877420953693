import { createPosition } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { IFormImageChoiceNode, PartialNode } from "../type";

const createFormImageChoiceNode = (
  data?: PartialNode<IFormImageChoiceNode>
): IFormImageChoiceNode => {
  return {
    type: "FORM_IMAGE_CHOICE",
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    reactions: [],
    position: createPosition(data?.position),

    name: "",

    width: { value: 100, unit: "%" },

    fills: [],

    locked: false,

    strokes: [],
    strokeWeight: 1,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    visible: true,

    effects: [],

    label: "<p>새 항목</p>",
    required: true,
    choiceType: "RADIO",
    range: {
      type: "RANGE",
      min: 0,
      max: 2,
    },

    ...data,

    items: data?.items
      ? data.items.map((item) => ({
          id: shortUUID.generate(),
          url: "https://image.codenbutter.com/campaign/template/image-choice-sample.png",
          ...item,
        }))
      : [
          {
            id: shortUUID.generate(),
            url: "https://image.codenbutter.com/campaign/template/image-choice-sample.png",
          },
          {
            id: shortUUID.generate(),
            url: "https://image.codenbutter.com/campaign/template/image-choice-sample.png",
          },
          {
            id: shortUUID.generate(),
            url: "https://image.codenbutter.com/campaign/template/image-choice-sample.png",
          },
          {
            id: shortUUID.generate(),
            url: "https://image.codenbutter.com/campaign/template/image-choice-sample.png",
          },
        ],
  };
};

export default createFormImageChoiceNode;
