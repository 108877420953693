import {
  createFormItemNode,
  createPosition,
} from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { IFormNode, PartialNode } from "../type";

const createFormNode = (data?: PartialNode<IFormNode>): IFormNode => {
  return {
    // 기본값
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [
        {
          type: "form_edit_item",
          name: "내용 편집",
        },
      ],
    },
    position: createPosition(data?.position),
    reactions: [],
    name: "",
    width: {
      value: 100,
      unit: "%",
    },
    padding: {
      top: {
        value: 30,
        unit: "px",
      },
      bottom: {
        value: 30,
        unit: "px",
      },
      left: {
        value: 15,
        unit: "px",
      },
      right: {
        value: 15,
        unit: "px",
      },
    },
    gap: 24,

    locked: false,
    visible: true,

    strokes: [],
    strokeWeight: 0,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    fills: [],

    effects: [],
    alignHorizontal: "LEFT",

    layoutMode: "VERTICAL",
    primaryAxisSizingMode: "AUTO",
    counterAxisSizingMode: "AUTO",

    baseColor: { r: 217, g: 218, b: 228, a: 1 },
    fontColor: { r: 27, g: 28, b: 27, a: 1 },
    primaryColor: { r: 27, g: 28, b: 27, a: 1 },
    fontSize: { value: 16, unit: "px" },
    fontName: { style: "normal", family: "PretendardVariable-v1" },

    cornerRadius: {
      bl: { value: 0, unit: "px" },
      br: { value: 0, unit: "px" },
      tl: { value: 0, unit: "px" },
      tr: { value: 0, unit: "px" },
    },

    ...data,
    children: data?.children?.map(createFormItemNode) ?? [],

    // 필수값
    type: "FORM",
  };
};

export default createFormNode;
