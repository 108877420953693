import { createPosition } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { IFormDividerNode, PartialNode } from "../type";

const createFormDividerNode = (
  data?: PartialNode<IFormDividerNode>
): IFormDividerNode => {
  return {
    type: "FORM_DIVIDER",
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    reactions: [],
    position: createPosition(data?.position),

    name: "",

    width: { value: 100, unit: "%" },

    fills: [],

    locked: false,

    strokes: [],
    strokeWeight: 1,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    visible: true,

    effects: [],

    label: "구분선",
    required: true,

    margin: {
      top: { value: 10, unit: "px" },
      bottom: { value: 10, unit: "px" },
    },

    ...data,
  };
};

export default createFormDividerNode;
