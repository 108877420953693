import { createRecentVersionData } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import { RICH_TEXT_EMPTY_VALUE } from "../const";
import type { ITextNode, PartialNode } from "../type";

const createTextNode = (data?: PartialNode<ITextNode>): ITextNode => {
  return {
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [
        {
          type: "text_content",
          name: "텍스트 내용",
        },
      ],
    },
    reactions: [],
    position: { type: "RELATIVE" },

    // 노드트리 등에서 보이는 이름
    name: "",

    width: { value: 100, unit: "%" },
    alignHorizontal: "LEFT",

    content: RICH_TEXT_EMPTY_VALUE,

    fills: [],
    backgrounds: [],

    locked: false,

    paragraphIndent: {
      value: 0,
      unit: "em",
    },

    lineHeight: {
      value: 1.3,
      unit: "",
    },

    paragraphSpacing: {
      value: 0,
      unit: "em",
    },

    strokes: [],
    strokeWeight: 1,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    textAlignHorizontal: "CENTER",
    textAlignVertical: "CENTER",
    // TODO: 현재 기본값이 HEIGHT일 뿐, 세로 자동 조정은 height 속성 여부에 의해 결정됨. WIDTH_AND_HEIGHT일 때에는 가로 자동 조정됨.
    textAutoResize: "HEIGHT",

    fontSize: {
      value: 16,
      unit: "px",
    },

    visible: true,

    effects: [],
    boxShadowEffects: [],

    padding: {
      top: {
        value: 10,
        unit: "px",
      },
      bottom: {
        value: 10,
        unit: "px",
      },
      left: {
        value: 0,
        unit: "px",
      },
      right: {
        value: 0,
        unit: "px",
      },
    },

    // 인자값
    ...createRecentVersionData(data),

    // 필수값
    type: "TEXT",
  };
};

export default createTextNode;
