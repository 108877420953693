import type { ParsedUrlQuery } from "node:querystring";
import type { CampaignType } from "../types/campaign";
import {
  getEventPageBaseUrl,
  getPromotionPageBaseUrl,
  getSurveyPageBaseUrl,
} from "./config";

export function getParamValue<T extends string = string>(
  query: ParsedUrlQuery,
  param: string,
  defaultValue?: T
) {
  const value = query[param];
  if (!value) {
    return defaultValue || null;
  }

  return (Array.isArray(value) ? value[0] : value) as T;
}

/**
 * 1. http가 없으면 붙여줌
 * 2. URL의 끝에 "/"를 없앰
 */
export const getUrlWithSchema = (url: string) => {
  let ret = url.trim();

  if (ret.indexOf("http://") === -1 && ret.indexOf("https://") === -1) {
    ret = `http://${ret}`;
  }

  if (ret !== "http://" && ret[ret.length - 1] === "/") {
    ret = ret.substring(0, ret.length - 1);
  }

  return ret;
};

export const isValidURL = (url: string) => {
  const res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  return res !== null;
};

export const flatToQueryString = (
  paramArr:
    | Readonly<{ key: string; value: string }[]>
    | { key: string; value: string }[]
) =>
  `?${paramArr
    .filter(({ key }) => key.length > 0)
    .map(({ key, value }) => `${key}=${value}`)
    .join("&")}`;

export function getOptimizedImagePath(
  imagePath: string,
  isOptimize: boolean,
  hasTransparency?: boolean
) {
  const imageURL = new URL(imagePath);
  imageURL.searchParams.delete("f");

  if (isOptimize) {
    if (imageURL.pathname.endsWith("png") && !hasTransparency) {
      imageURL.searchParams.append("f", "jpg");
    }
  } else {
    imageURL.searchParams.append("f", "original");
  }

  return imageURL.toString();
}

export const getPageUrl = ({
  campaignPublicId,
  sitePublicId,
  campaignType,
}: {
  campaignType: CampaignType;
  campaignPublicId: string | null | undefined;
  sitePublicId: string | null | undefined;
}) => {
  const domain =
    campaignType === "PROMOTION"
      ? getPromotionPageBaseUrl()
      : campaignType === "FORM_EVENT"
      ? getEventPageBaseUrl()
      : campaignType === "FORM_SURVEY"
      ? getSurveyPageBaseUrl()
      : "";

  return `${domain}?s=${sitePublicId}&c=${campaignPublicId}`;
};
