import React from "react";

interface IOnboardingMessageProps {
  message: React.ReactNode;
}

const OnboardingMessage = ({ message }: IOnboardingMessageProps) => {
  return (
    <div className="flex center-center">
      <div className="px-4 py-1 text-base font-semibold rounded-2xl bg-cb-gray-100 text-cb-gray-900">
        {message}
      </div>
    </div>
  );
};

export default React.memo(OnboardingMessage);
