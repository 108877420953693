import React from "react";

interface ICouncelingIconProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  width?: number;
  height?: number;
}

const V2CounselingIcon: React.FC<ICouncelingIconProps> = ({
  width = 35,
  height = 32,
  ...props
}) => {
  return (
    <img
      width={width}
      height={height}
      src="/v2-icon/application-counseling.png"
      alt="counseling-icon"
      {...props}
    />
  );
};

export default V2CounselingIcon;
