import type { Keys } from "@shared/common/utils/types";
import { colord } from "colord";
import type {
  AspectRatioValue,
  BaseNode,
  BaseNodeData,
  Color,
  ContentItems,
  ContentMeta,
  CornerRadiusValue,
  FormItemNode,
  HeightType,
  IDocumentNode,
  IFormNode,
  IFrameNode,
  IMediaPaint,
  INodeDevice,
  IPartialDocumentData,
  ISolidPaint,
  Paint,
  PartialNode,
  SceneNode,
  ValueWithUnit,
  WidthType,
} from "./type";

export const zeroValue: ValueWithUnit = { value: 0, unit: "" };

/** 문자열의 형태가 `${number}${string}` 일 것으로 가정 */
export const convertToValueWithUnit = (str: string) => {
  const value = Number.parseFloat(str);
  const unit = str.substring(String(value).length);

  return { value: Number.isNaN(value) ? 0 : value, unit };
};

export const getUnitStyle = (
  { value, unit = "" }: ValueWithUnit = { value: 0, unit: "" },
  multiplier = 1
) => {
  return unit === "auto" ? "auto" : `${value * multiplier}${unit}`;
};

export const stringifyCalcItems = (items: ValueWithUnit[]) => {
  const first = items[0];
  if (items.length === 0) {
    return "0";
  }

  if (items.length === 1 && first) {
    return getUnitStyle(first);
  }

  return `calc(${items
    .filter((v) => v.value !== 0)
    .reduce<string>((str, acc, idx) => {
      if (idx === 0) {
        return getUnitStyle(acc);
      }

      return [
        str,
        acc.value < 0 ? "-" : "+",
        getUnitStyle({
          ...acc,
          value: Math.abs(acc.value),
        }),
      ].join(" ");
    }, "")})`;
};

export const getPaintsStyle = (value: Paint[], type?: "IMAGE" | "SOLID") => {
  return value
    .filter(
      (paint) => paint?.visible !== false && (!type || type === paint.type)
    ) // visible이 undefined 인 경우에는 true 로 간주 && type param이 있는 경우 해당 type의 style만 반환
    .map((paint) => getPaintStyle(paint))
    .join(", ");
};

export const getBorderColorStyle = (value: Paint[]) => {
  const paint = value
    .filter((paint) => paint.visible !== false)
    .filter((paint): paint is ISolidPaint => paint.type === "SOLID")[0];

  if (!paint) {
    return "";
  }

  return getHex(paint);
};

export const getPaintStyle = (value: Paint) => {
  switch (value?.type) {
    case "SOLID":
      return getSolidPaintStyle(value);
    case "IMAGE":
      return getImagePaintStyle(value);
    default:
      return "";
  }
};

export const getAspectRatioStyle = (value: AspectRatioValue) => {
  switch (value.type) {
    case "BACKGROUND_IMAGE":
      return `${value.imageWidth ?? 1} / ${value.imageHeight ?? 1}`;
    case "MANUAL":
      return `${value.widthRatio ?? 1} / ${value.heightRatio ?? 1}`;
  }
};

export const getBorderRadiusStyle = (value: CornerRadiusValue) => {
  return `${getUnitStyle(value.tl ?? zeroValue)} ${getUnitStyle(
    value.tr ?? zeroValue
  )} ${getUnitStyle(value.br ?? zeroValue)} ${getUnitStyle(
    value.bl ?? zeroValue
  )}`;
};

export const getSolidPaintStyle = (value: ISolidPaint) => {
  const colorString = colord(value.color)
    .alpha(value.opacity ?? 1)
    .toHex();

  return `linear-gradient(${colorString}, ${colorString})`;
};

export const getHex = (value: ISolidPaint) =>
  colord(value.color)
    .alpha(value.opacity ?? 1)
    .toHex();

export const getImagePaintStyle = (value: IMediaPaint) => {
  return `center / cover no-repeat url(${value.path})`;
};

export const getColordObject = ({
  color,
  opacity = 1,
}: {
  color: Color;
  opacity?: number;
}) => {
  const colordObj = colord(color);
  return colordObj.alpha(opacity);
};

export const getRgbString = (color: Color, opacity = 1) => {
  const colordObj = colord(color);
  return colordObj.alpha(opacity).toRgbString();
};

export function removeByKeys<T, U extends Keys<T>>(
  obj: T,
  keys: U[]
): Omit<T, U> {
  const result = { ...obj };
  keys.forEach((key) => {
    delete result[key as unknown as keyof T];
  });
  return result;
}

export function selectByKeys<T, U extends ReadonlyArray<keyof T>>(
  obj: T,
  keys: U
): Pick<T, U[number]> {
  const result = {} as Pick<T, U[number]>;

  keys.forEach((key) => {
    result[key] = obj[key];
  });

  return result;
}

export const traversePartial = ({
  handler,
  node,
}: {
  node: BaseNodeData;
  handler: (node: BaseNodeData) => void;
}) => {
  handler(node);
  if (node.type === "DOCUMENT" || node.type === "FRAME") {
    node.children?.forEach((child) => {
      traversePartial({ node: child, handler });
    });
  }
};

/**
 * 주어진 노드와 그 모든 자식 노드를 순회하면서 핸들러를 실행합니다.
 */
export const traverse = ({
  node,
  handler,
  currentLevel = 1,
  stopIf: stopTraverseIf,
}: {
  /** 노드 순회의 시작점입니다. */
  node: BaseNode;
  /** 노드를 순회하며 실행할 함수입니다. */
  handler: (node: BaseNode, level: number) => void;
  /** 얼마나 깊이 왔는지에 대한 척도입니다. 기본값은 1 */
  currentLevel?: number;
  /** 자식 노드에 대해 traverse 를 정지할지 여부를 설정합니다. 결과가 true 면 정지합니다. */
  stopIf?: (
    node: IDocumentNode | IFrameNode | IFormNode,
    level: number
  ) => boolean;
}) => {
  handler(node, currentLevel);

  if (
    node.type === "DOCUMENT" ||
    node.type === "FRAME" ||
    node.type === "FORM"
  ) {
    if (stopTraverseIf && stopTraverseIf(node, currentLevel)) {
      return;
    }

    node.children.forEach((child) => {
      traverse({
        node: child,
        handler,
        currentLevel: currentLevel + 1,
        stopIf: stopTraverseIf,
      });
    });
  }
};

export const isSameNodeType = (
  node1: PartialNode<BaseNode>,
  node2: PartialNode<BaseNode>
) => {
  return node1.type === node2.type;
};

export const clamp = (value: number, min: number, max: number) => {
  return Math.max(min, Math.min(max, value));
};

export const getWidthType = (node: BaseNode): WidthType => {
  return !node.width ? "HUG" : node.width?.unit === "px" ? "FIXED" : "FILL";
};

export const getHeightType = (node: BaseNode): HeightType => {
  if ("aspectRatio" in node) {
    if (node.aspectRatio?.type === "BACKGROUND_IMAGE") {
      return "FIT_TO_IMAGE";
    }

    if (node.aspectRatio?.type === "MANUAL") {
      return "ASPECT_RATIO";
    }
  }

  return !node.height
    ? "HUG"
    : node.height?.value === 100 && node.height.unit === "%"
    ? "FILL"
    : node.height.unit === "auto"
    ? "FIT_TO_SLIDE"
    : "FIXED";
};

/**
 * 빈 값(`[]`, `""` `null`, `undefined`)과 함수를 없앤 새로운 객체를 반환합니다.
 */
export function excludeEmptyOrFunctionProperty<
  T extends Record<string, unknown>,
>(obj: T): Partial<T> {
  const result = { ...obj };

  Object.keys(result).forEach((keyString) => {
    const key = keyString as keyof T;
    const value = result[key];

    if (
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "string" && value === "") ||
      typeof value === "function" ||
      typeof value === "undefined" ||
      value === null
    ) {
      delete result[key];
    }
  });

  return result;
}

/**
 * 해당 노드가 자식을 가질 수 있는지를 체크합니다.
 * @param nodeType 노드의 타입
 */
export function isChildrenMixin(
  node: PartialNode<BaseNode>
): node is
  | PartialNode<IDocumentNode>
  | PartialNode<IFrameNode>
  | PartialNode<IFormNode> {
  return (
    node.type === "DOCUMENT" || node.type === "FRAME" || node.type === "FORM"
  );
}

export function isFormMixin(
  node: PartialNode<BaseNode>
): node is PartialNode<FormItemNode> {
  return (
    node.type === "FORM_INPUT" ||
    node.type === "FORM_PRIVACY_POLICY" ||
    node.type === "FORM_CHOICE" ||
    node.type === "FORM_DIVIDER" ||
    node.type === "FORM_IMAGE_CHOICE" ||
    node.type === "FORM_DROPDOWN" ||
    node.type === "FORM_RATING_SCALE" ||
    node.type === "FORM_TEXT" ||
    node.type === "FORM_TEXTAREA"
  );
}

export const jsonifyDocumentNode = (
  documentNode: IDocumentNode,
  options?: { excludeKeys?: Array<Keys<BaseNode>> }
): PartialNode<IDocumentNode> => {
  let refined: Partial<IDocumentNode> = documentNode;
  if (options?.excludeKeys) {
    refined = removeByKeys(
      documentNode,
      options.excludeKeys as Array<Keys<IDocumentNode>>
    );
  }

  return {
    ...excludeEmptyOrFunctionProperty(refined),
    children: refined.children?.map((child) =>
      jsonifySceneNode(child, options)
    ),
  };
};

export const jsonifySceneNode = (
  node: SceneNode,
  options?: { excludeKeys?: Array<Keys<BaseNode>> }
): PartialNode<SceneNode> => {
  let refined = { ...node } as PartialNode<SceneNode>;
  if (options?.excludeKeys) {
    refined = removeByKeys(node, options.excludeKeys as Array<Keys<SceneNode>>);
  }

  if (isChildrenMixin(node)) {
    Object.assign(refined, {
      children: node.children?.map((child) => jsonifySceneNode(child, options)),
    });
  }

  if (
    node.type === "FORM_CHOICE" ||
    node.type === "FORM_IMAGE_CHOICE" ||
    node.type === "FORM_DROPDOWN"
  ) {
    Object.assign(refined, {
      items: node.items.map((item) => jsonifySimpleObject(item, options)),
    });
  }

  return excludeEmptyOrFunctionProperty(refined);
};

export const jsonifySimpleObject = <T extends object>(
  obj: T,
  options?: { excludeKeys?: Array<string | number | symbol> }
): PartialNode<T> => {
  let refined: PartialNode<T> = { ...obj };
  if (options?.excludeKeys) {
    refined = removeByKeys(
      obj,
      options.excludeKeys as Keys<T>[]
    ) as PartialNode<T>;
  }

  return excludeEmptyOrFunctionProperty(refined);
};

export const isContentMetaItem = (value: unknown): value is ContentMeta => {
  const valueObj = Object(value);

  if (valueObj !== value) {
    return false;
  }

  return (
    Object.prototype.hasOwnProperty.call(valueObj, "type") &&
    Object.prototype.hasOwnProperty.call(valueObj, "name")
  );
};

export const sortByContentSeq = (a: SceneNode, b: SceneNode) => {
  const seqA = (a.pluginData.contentEditorPropSeq ?? 0) as number;
  const seqB = (b.pluginData.contentEditorPropSeq ?? 0) as number;
  return seqB - seqA;
};

/**
 * fills 속성을 받아서 유효한 이미지가 한 개있으면 해당 이미지에 맞춰 aspectRatio 프로퍼티 반환한다.
 */
export const getImageAspectRatio = (fills: Paint[]): AspectRatioValue => {
  const imageList = fills.filter((fill) => fill.type === "IMAGE");

  if (!imageList || imageList.length > 1) {
    return {
      type: "BACKGROUND_IMAGE",
      imageWidth: undefined,
      imageHeight: undefined,
    };
  }

  const image = imageList[0] as IMediaPaint;

  if (!image || !image.width || !image.height) {
    return {
      type: "BACKGROUND_IMAGE",
      imageWidth: undefined,
      imageHeight: undefined,
    };
  }

  return {
    type: "BACKGROUND_IMAGE",
    imageWidth: image.width.value,
    imageHeight: image.height.value,
  };
};

const isContentItemAvailable = (node: BaseNode) => {
  switch (node.type) {
    case "FRAME":
    case "TEXT":
    case "IMAGE":
    case "SWIPER_PAGINATION":
    case "SWIPER_BUTTON_GROUP":
    case "FORM":
    case "SWIPER_CONTAINER":
    case "VIDEO":
    case "TIMER":
      return true;
    case "DOCUMENT":
    case "INSTAGRAM_GALLERY":
    case "SLOT":
    case "FORM_INPUT":
    case "FORM_PRIVACY_POLICY":
    case "FORM_DIVIDER":
    case "FORM_CHOICE":
    case "FORM_IMAGE_CHOICE":
    case "FORM_TEXT":
    case "FORM_DROPDOWN":
    case "FORM_RATING_SCALE":
    case "FORM_TEXTAREA":
      return false;
  }
};

export const getContentItems = (documentNode: IDocumentNode): ContentItems => {
  let newContentItems = {};

  traverse({
    node: documentNode,
    handler: (node) => {
      if (!isContentItemAvailable(node)) {
        return;
      }

      if (!Array.isArray(node.pluginData.contentMeta)) {
        return;
      }

      const contentMeta = node.pluginData.contentMeta.filter(isContentMetaItem);
      if (contentMeta.length === 0) {
        return;
      }

      newContentItems = {
        ...newContentItems,
        [node.id]: { node, contents: contentMeta },
      };
    },
  });

  return newContentItems;
};

export const findNodeByPredicate = <T extends BaseNode>(
  documentNode: IDocumentNode,
  predicate: (node: BaseNode) => node is T
): T | null => {
  let foundNode: T | null = null;

  traverse({
    node: documentNode,
    handler: (node) => {
      if (foundNode) {
        return;
      }

      if (predicate(node)) {
        foundNode = node;
      }
    },

    stopIf() {
      return Boolean(foundNode);
    },
  });

  return foundNode;
};

export const findAllNodeByPredicate = <T extends BaseNode>(
  documentNodes: IDocumentNode[],
  predicate: (node: BaseNode) => node is T
): T[] => {
  const foundNodes: T[] = [];

  documentNodes.forEach((documentNode) => {
    traverse({
      node: documentNode,
      handler: (node) => {
        if (predicate(node)) {
          foundNodes.push(node);
        }
      },
    });
  });

  return foundNodes;
};

export const getColorVar = (color: Color) => {
  const colordObj = colord(color).toRgb();
  return `${colordObj.r}, ${colordObj.g}, ${colordObj.b}`;
};

export const getDocumentNode = (
  documentData: IPartialDocumentData,
  { device = "MOBILE" }: { device?: INodeDevice } = {}
) => {
  const result = documentData.documents?.find((doc) => doc.device === device);
  return result ? result : null;
};
