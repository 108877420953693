import { createPosition } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { IFormTextareaNode, PartialNode } from "../type";

const createFormTextareaNode = (
  data?: PartialNode<IFormTextareaNode>
): IFormTextareaNode => {
  return {
    type: "FORM_TEXTAREA",
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    reactions: [],
    position: createPosition(data?.position),

    name: "",

    width: { value: 100, unit: "%" },

    fills: [],

    locked: false,

    strokes: [],
    strokeWeight: 1,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    visible: true,

    effects: [],

    label: "<p>새 항목</p>",
    required: false,

    ...data,
  };
};

export default createFormTextareaNode;
