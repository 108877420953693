import type {
  IBackgroundColorMeta,
  IBackgroundImageMeta,
  IDocumentData,
  IDocumentNode,
  IFormBaseColorMeta,
  IFormItemEditMeta,
  IFormPrimaryColorMeta,
  IGotoPageMeta,
  IGridLayoutMeta,
  IImageContentMeta,
  INodeDevice,
  IOgDescriptionMeta,
  IOgImageMeta,
  IOgTitleMeta,
  IReactionUrlMeta,
  ITextBackgroundColorMeta,
  ITextColorMeta,
  ITextContentMeta,
  IVideoMeta,
  Paint,
} from "@shared/common/node/type";
import createDocumentNode from "./create/document";
import createFrameNode from "./create/frame";
import createInstagramGalleryNode from "./create/instagram-gallery";
import createTextNode from "./create/text";

export const getDefaultDocumentData = (
  device: INodeDevice = "MOBILE"
): IDocumentData => {
  return {
    version: "0.0.1",
    documents: [getDefaultDocumentNode(device)],
    dataMap: {},
    pluginData: {},
  };
};

export const getDefaultDocumentNode = (device: INodeDevice) => {
  return device === "ALL"
    ? getDefaultInstagramDocumentNode()
    : getDefaultPopupDocumentNode(device);
};

export const getDefaultPopupDocumentNode = (
  device: Exclude<INodeDevice, "ALL"> = "MOBILE"
): IDocumentNode => {
  return createDocumentNode({
    type: "DOCUMENT",
    name: "팝업",
    device,
    width:
      device === "MOBILE"
        ? { unit: "%", value: 100 }
        : { unit: "px", value: 320 },
    children: [],
  });
};

export const getDefaultInstagramDocumentNode = (): IDocumentNode => {
  return createDocumentNode({
    type: "DOCUMENT",
    name: "인스타그램",
    device: "ALL",
    width: { value: 100, unit: "%" },
    children: [],
  });
};

export const getDefaultBannerGroupDocumentNode = (
  device: INodeDevice = "MOBILE"
): IDocumentNode => {
  return createDocumentNode({
    name: "배너영역",
    device,
    width: { value: 100, unit: "%" },
    alignHorizontal: "CENTER",
    children: [],
  });
};

export const getDefaultPopupGroupDocumentNode = (
  device: INodeDevice = "MOBILE"
): IDocumentNode => {
  return createDocumentNode({
    name: "팝업영역",
    device,
    width: { value: 100, unit: "%" },
    children: [],
  });
};

export const getDefaultBannerDocumentNode = (
  device: INodeDevice = "ALL"
): IDocumentNode => {
  return createDocumentNode({
    name: "배너",
    device,
    width: { value: 100, unit: "%" },
    height: { value: 100, unit: "%" },
    children: [],
  });
};

export const getDefaultBannerSchedule = () => {
  return {
    type: "OR",
    value: [{ end: null, slot: 0, type: "DATETIME_RANGE", start: null }],
  };
};

export const getDefaultFormEventDocumentNode = (): IDocumentNode => {
  return createDocumentNode({
    name: "이벤트",
    device: "ALL",
    width: { value: 100, unit: "%" },
    maxWidth: { value: 700, unit: "px" },
    alignHorizontal: "CENTER",
    children: [],
  });
};

export const getDefaultFormSurveyDocumentNode = (): IDocumentNode => {
  return createDocumentNode({
    name: "설문조사",
    device: "ALL",
    width: { value: 100, unit: "%" },
    maxWidth: { value: 700, unit: "px" },
    alignHorizontal: "CENTER",
    children: [],
  });
};

export const getDefaultPromotionDocumentNode = (): IDocumentNode => {
  return createDocumentNode({
    name: "이벤트 페이지",
    device: "ALL",
    width: { value: 100, unit: "%" },
    maxWidth: { value: 1200, unit: "px" },
    alignHorizontal: "CENTER",
    children: [],
  });
};

export const getDefaultInstagramTemplate = (): IDocumentNode => {
  return createDocumentNode({
    width: { unit: "%", value: 100 },
    device: "ALL",
    children: [
      createFrameNode({
        fills: [
          {
            type: "SOLID",
            color: { b: 255, g: 255, r: 255 },
            opacity: 0,
            visible: false,
          },
          { path: "", type: "IMAGE", visible: false, fileType: "png" },
          {
            type: "SOLID",
            color: { b: 255, g: 255, r: 255 },
            opacity: 1,
            visible: false,
          },
        ],
        padding: {
          top: { unit: "px", value: 75 },
          left: { value: 0 },
          right: { value: 0 },
          bottom: { value: 0 },
        },
        children: [
          createTextNode({
            content: "<p>Instagram</p>",
            width: { unit: "px", value: 109.52 },
            fontName: { style: "600", family: "PretendardVariable-v1" },
            fontSize: { unit: "px", value: 20 },
            position: {
              top: 0,
              left: 8,
              type: "ABSOLUTE",
              right: 582.4765625,
              scale: {
                top: 0,
                left: 0.011428571428571429,
                right: 0.832109375,
                width: 0.15646205357142856,
                bottom: 0.8800180288461539,
                height: 0.11998197115384615,
              },
              bottom: 228.8046875,
              centerX: -287.23828125,
              centerY: -114.40234375,
              constraintX: "LEFT",
              constraintY: "TOP",
            },
            textAlignHorizontal: "LEFT",
            textAutoResize: "WIDTH_AND_HEIGHT",
          }),
          createTextNode({
            content: "<p>@{{instagram.username}}</p>",
            width: { unit: "px", value: 68.04 },
            fontSize: { unit: "px", value: 13 },
            fills: [
              {
                type: "SOLID",
                color: { b: 128, g: 114, r: 107 },
                opacity: 1,
                visible: true,
              },
            ],
            position: {
              top: 30,
              left: 8,
              type: "ABSOLUTE",
              right: 623.9609375,
              scale: {
                top: 0.1346153846153846,
                left: 0.011428571428571429,
                right: 0.8913727678571428,
                width: 0.09719866071428572,
                bottom: 0.7853966346153847,
                height: 0.07998798076923076,
              },
              bottom: 204.203125,
              centerX: -307.98046875,
              centerY: -84.6015625,
              constraintX: "LEFT",
              constraintY: "TOP",
            },
            textAlignHorizontal: "LEFT",
            textAutoResize: "WIDTH_AND_HEIGHT",
          }),
          createTextNode({
            content:
              '<p><span style="color: rgb(255, 255, 255);">Follow</span></p>',
            width: { value: 91.27, unit: "px" },
            fontSize: { unit: "px", value: 14 },
            letterSpacing: { value: 0.025, unit: "em" },
            reactions: [
              {
                action: {
                  url: "https://instagram.com/{{instagram.username}}",
                  type: "URL",
                  target: "_blank",
                  seconds: 86400,
                  deeplinkIos: "",
                  fallbackIos: "itunes-appstore",
                  deeplinkAndroid: "",
                  deeplinkDesktop: "",
                  fallbackAndroid: "google-play",
                },
                trigger: { type: "ON_CLICK" },
              },
            ],
            position: {
              type: "ABSOLUTE",
              constraintX: "RIGHT",
              constraintY: "TOP",
              top: 10,
              left: 596.65625,
              right: 10.0703125,
              bottom: 290,
              centerX: 292.29296875,
              centerY: -123,
              scale: {
                top: 0.03333333333333333,
                left: 0.8523660714285715,
                right: 0.017243303571428573,
                width: 0.130390625,
                bottom: 0.8533333333333334,
                height: 0.11333333333333333,
              },
            },
            cornerRadius: {
              bl: { unit: "px", value: 6 },
              br: { unit: "px", value: 6 },
              tl: { unit: "px", value: 6 },
              tr: { unit: "px", value: 6 },
            },
            padding: {
              top: { unit: "px", value: 7 },
              left: { unit: "px", value: 16 },
              right: { unit: "px", value: 16 },
              bottom: { unit: "px", value: 7 },
            },
            backgrounds: [
              {
                type: "SOLID",
                color: { b: 255, g: 255, r: 255 },
                opacity: 0.25,
                visible: false,
              },
              { path: "", type: "IMAGE", visible: false, fileType: "png" },
              {
                type: "SOLID",
                color: { a: 1, b: 239, g: 149, r: 61 },
                opacity: 1,
                visible: true,
              },
            ],
            textAutoResize: "WIDTH_AND_HEIGHT",
          }),
          createInstagramGalleryNode({
            pluginData: {
              contentEditorPropSeq: 0,
              contentMeta: [{ type: "grid_layout", name: "행렬 조절" }],
            },
            position: {
              type: "RELATIVE",
            },
            reactions: [],
            name: "인스타그램 갤러리",
            width: {
              value: 100,
              unit: "%",
            },
            row: 2,
            col: 5,
            gap: 2,
          }),
        ],
      }),
    ],
  });
};

export const getDefaultBannerGroupTemplate = (): IDocumentNode => {
  return createDocumentNode({
    width: { unit: "%", value: 100 },
    device: "ALL",
    children: [
      {
        type: "SLOT",
        name: "배너 그룹",
      },
    ],
  });
};

export const NODE_ID_ATTRIBUTE_NAME = "data-node-id";
export const FORM_NODE_ID_ATTRIBUTE_NAME = "data-form-node-id";

export const EMPTY_NODE_ID = "[empty]";

export const IS_EDITOR_PANEL_ATTRIBUTE_NAME = "data-is-editor-panel";
export const IS_FORM_EDITOR_PANEL_ATTRIBUTE_NAME = "data-is-form-editor-panel";

export const GLOBAL_WRAPPER_DROP_KEY = "global-wrapper-drop";

export const DEFAULT_FONT_STYLE = "normal";

export const fillsLength = 3 as const;
export const imageColorFilterIndex = 0 as const;
export const imagePaintIndex = 1 as const;
export const solidPaintIndex = 2 as const;

export type BackgroundType = "SOLID" | "IMAGE" | "TRANSPARENT";

export const backgroundTypes = ["TRANSPARENT", "SOLID", "IMAGE"] as const;

export const getBackgroundType = (value: Paint[]) => {
  if (value.length !== fillsLength) {
    return "TRANSPARENT";
  }

  if (value[imagePaintIndex]?.visible !== false) {
    return "IMAGE";
  }
  if (value[solidPaintIndex]?.visible !== false) {
    return "SOLID";
  } else {
    return "TRANSPARENT";
  }
};

export const videoContentMetaData: [
  IVideoMeta,
  IReactionUrlMeta,
  IGotoPageMeta,
] = [
  {
    name: "비디오",
    type: "video",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.url.name",
    type: "reaction_url",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.gotoPage.name",
    type: "goto_page",
  },
];

export const frameContentMetaData: [
  IBackgroundImageMeta,
  IBackgroundColorMeta,
  IReactionUrlMeta,
  IGotoPageMeta,
  IOgImageMeta,
] = [
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.backgroundImage.name",
    type: "background_image",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.backgroundColor.name",
    type: "background_color",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.url.name",
    type: "reaction_url",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.gotoPage.name",
    type: "goto_page",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.ogImage.name",
    type: "og_image",
  },
];

export const imageContentMetaData: [
  IImageContentMeta,
  IReactionUrlMeta,
  IGotoPageMeta,
] = [
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.image.name",
    type: "image_content",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.url.name",
    type: "reaction_url",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.gotoPage.name",
    type: "goto_page",
  },
];

export const textContentMetaData: [
  ITextContentMeta,
  ITextColorMeta,
  ITextBackgroundColorMeta,
  IReactionUrlMeta,
  IGotoPageMeta,
  IOgTitleMeta,
  IOgDescriptionMeta,
] = [
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.textContent.name",
    type: "text_content",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.textColor.name",
    type: "text_color",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.backgroundColor.name",
    type: "text_background_color",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.url.name",
    type: "reaction_url",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.gotoPage.name",
    type: "goto_page",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.ogTitle.name",
    type: "og_title",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.ogDescription.name",
    type: "og_description",
  },
];

export const formContentMetaData: [
  IFormPrimaryColorMeta,
  IFormBaseColorMeta,
  IFormItemEditMeta,
] = [
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.formPrimaryColor.name",
    type: "form_primary_color",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.formBaseColor.name",
    type: "form_base_color",
  },
  {
    name: "editor:customize.advancedMode.settings.contentEditorProperties.formEditItem.name",
    type: "form_edit_item",
  },
];

export const instagramGalleryContentMetaData: [IGridLayoutMeta] = [
  {
    name: "행렬 조절",
    type: "grid_layout",
  },
];
