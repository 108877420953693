import { Copy } from "lucide-react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/cjs/styles/hljs";

interface IV2CodeBlockProps {
  language?: string;
  value: string;
  copyGtmId?: string;
}

const V2CodeBlock = ({ language, value, copyGtmId }: IV2CodeBlockProps) => {
  const { t } = useTranslation();

  return (
    <div className="relative overflow-hidden rounded-md">
      <SyntaxHighlighter
        language={language}
        wrapLines={true}
        codeTagProps={{ className: "font-mono" }}
        lineProps={{
          style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
        }}
        style={{
          ...dracula,
          hljs: {
            ...dracula.hljs,
            display: "block",
            overflowX: "auto",
            padding: "1.2em",
          },
        }}
      >
        {value}
      </SyntaxHighlighter>

      <div className="absolute top-2 right-2">
        <CopyToClipboard
          text={value}
          onCopy={() => {
            toast(t("toast.copyScript.success"));
          }}
        >
          <button
            type="button"
            className="flex items-center justify-center flex-shrink-0 w-10 h-10 transition-all duration-300 bg-black rounded-md hover:bg-gray-600 bg-opacity-70 hover:bg-opacity-100"
            data-gtm-id={copyGtmId}
          >
            <Copy width={15} className="text-white" />
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default V2CodeBlock;
