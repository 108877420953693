// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nl2br = (str: any, isXhtml = false) => {
  const breakTag =
    isXhtml || typeof isXhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
export const noopFunction = (..._args: any): any => {};

/**
 * 실제 이미지가 가로로 뉘여져있는지 확인합니다.
 * 만약 가로로 뉘여져 있다면 width와 height를 뒤바꿔야 합니다.
 *
 * ### (참고) exif 정보
 *
 * 1. no nothing
 * 2. flip horizontally
 * 3. rotate 180 degrees
 * 4. flip vertically
 * 5. rotate 90 degrees clockwise and flip horizontally
 * 6. rotate 90 degrees clockwise
 * 7. rotate 90 degrees clockwise and flip vertically
 * 8. rotate 270 degrees clockwise
 *
 * @see https://zpl.fi/exif-orientation-in-different-formats/#handling-exif-orientation
 */
export const getImageSize = (sizeInfo: {
  orientation?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  width: number;
  height: number;
}) => {
  return sizeInfo.orientation && sizeInfo.orientation >= 5
    ? { width: sizeInfo.height, height: sizeInfo.width }
    : { width: sizeInfo.width, height: sizeInfo.height };
};

const zIndex = {
  MainPanel: {
    MenuBar: 30,
    InfoPanel: 25,
    PropertyPanel: 25,
  },
  EditorPanel: {
    DragOutlineLayer: 8,
    DragBlankLayer: 8,
    FormNodeEntryLayer: 9,
    SelectionLayer: 10,
    HoverLayer: 11,
    SizeControllerSideLayer: 12,
    PaddingControllerLayer: 13,
    SizeControllerVertexLayer: 14,
    DropGuideLayer: 15,
    NodeTypeLayer: 17,
    SizeInfoLayer: 19,
    ToolbarButtonsLayer: 20,
    TextEditLayer: 20,
  },
  EditorCanvas: {
    BgSelector: 16,
    MobileDeviceSelector: 16,
    UndoRedoLayer: 18,
  },
  ContentEditor: {
    LayerOption: 20,
    SizeOption: 21,
    LayoutOption: 22,
    AnimaitionOption: 23,
  },
  sdkJsBody: {
    component: 999999999,
  },
  AdminEdit: {
    TemplateEdit: 1005,
  },
} as const;

export const getZIndex = <
  T extends keyof typeof zIndex,
  U extends keyof (typeof zIndex)[T],
>(type: {
  group: T;
  name: U;
}) => {
  return zIndex[type.group][type.name];
};

export const convertURLtoBuffer = async (url: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  const arrayBuffer = await data.arrayBuffer();
  return Buffer.from(arrayBuffer);
};

/**
 *
 * @param promises
 * @description Promise.allSettled를 사용하여 비동기 작업을 수행하고 수행결과의 fulfilled와 rejected를 분리합니다.
 * @returns '{fulfilled: Array<T>, rejected: Array<unknown>}' 형태의 객체를 반환합니다.
 */
export async function separateResults<T>(promises: Array<Promise<T>>): Promise<{
  fulfilled: Array<T>;
  rejected: Array<unknown>;
}> {
  const results = await Promise.allSettled(promises);

  const fulfilled: Array<T> = [];
  const rejected: Array<unknown> = [];

  results.forEach((result) => {
    if (result.status === "fulfilled") {
      fulfilled.push(result.value);
    } else if (result.status === "rejected") {
      rejected.push(`${result.reason}`);
    }
  });

  return { fulfilled, rejected };
}

// 소수점 2자리 반올림
// https://www.delftstack.com/ko/howto/javascript/javascript-round-to-2-decimal-places/
export const floatRound = (num: number) => {
  const m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
};

/**
 * 이메일 마스킹 처리
 * 1. 마스킹은 @ 이전 문자열만 해당됨
 * 2. 세 글자 이상인 경우 앞 두 글자 제외한 나머지를 마스킹 처리
 * 3. 두 글자인 경우에는 앞 한 글자 제외한 나머지를 마스킹 처리
 * 4. 한 글자인 경우 모두 마스킹 처리
 */
export const maskEmail = (email: string) => {
  const [id, domain] = email.split("@");
  if (!id || !domain) return "";

  let maskedId;
  if (id.length > 2) {
    maskedId = id.substring(0, 2) + "*".repeat(id.length - 2);
  } else if (id.length === 2) {
    maskedId = id.substring(0, 1) + "*";
  } else {
    maskedId = "*";
  }

  return `${maskedId}@${domain}`;
};

/**
 * 이름 마스킹 처리
 * 1. 세 글자 이상인 경우 맨앞,맨뒤의 글자 제외한 나머지를 마스킹 처리한다.
 * 2. 두 글자인 경우에는 앞 한 글자 제외한 나머지를 마스킹 처리한다.
 * 3. 한 글자인 경우 모두 마스킹 처리한다.
 */
export const maskName = (name: string) => {
  if (name.length > 2) {
    return name[0] + "*".repeat(name.length - 2) + name[name.length - 1];
  } else if (name.length === 2) {
    return name[0] + "*";
  } else {
    return "*";
  }
};

/**
 * 계좌번호 마스킹 처리
 * 1. 끝에서 5자리만 마스킹 처리
 */
export const maskAccountNumber = (accountNumber: string) => {
  if (accountNumber.length <= 5) {
    return "*".repeat(accountNumber.length);
  }

  const maskedPart = "*".repeat(5);
  const unmaskedPart = accountNumber.substring(0, accountNumber.length - 5);

  return unmaskedPart + maskedPart;
};
