import I18n from "@/components/i18n";
import ApplicationCounseling from "@/components/v2/application-counseling";
import GTMCurrentSite from "@/components/v2/gtm/gtm-current-site";
import GTMCurrentTeam from "@/components/v2/gtm/gtm-current-team";
import GTMPath from "@/components/v2/gtm/gtm-path";
import GTMUserIdConnector from "@/components/v2/gtm/gtm-user-id-connector";
import FallbackRouter from "@/components/v2/layout/fallback-router";
import TrackingScript from "@/components/v2/tracking";
import { OrgAndSiteProvider } from "@/context";
import BreadcrumbProvider from "@/context/breadcrumb";
import { getPrivateApiClient } from "@/utils/apollo-client";
import { ApolloProvider } from "@apollo/client";
import { getPublicAppURL } from "@shared/common/utils/config";
import { NextComponentType, NextPageContext } from "next";
import { SessionProvider } from "next-auth/react";
import { AppProps } from "next/app";
import Head from "next/head";
import React from "react";
import { Toaster } from "react-hot-toast";
import "../styles/globals.css";

const DEFAULT_TITLE = "코드앤버터 - 매출이 나는 스마트 팝업으로 바꾸세요!";
const DEFAULT_DESCRIPTION =
  "월 9,900원으로 시작하는 개인화 마케팅. 스마트 팝업의 강력한 타겟팅 기능을 통해 성장하는 매출을 경험하세요.";

const toastStyle = {
  borderRadius: "0.375rem",
  boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  background: "#1B1C1A",
  color: "#F2F3F2",
  padding: "0.5rem",
  maxWidth: "100%",
  fontSize: "0.875rem",
};

export type LayoutNextComponentType = NextComponentType & {
  getLayout(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    page: NextComponentType<NextPageContext, any, any>,
    props: unknown
  ): React.ReactNode;
};

function MyApp({ Component, pageProps, router }: AppProps) {
  // layout component를 각 page component에 위임하여 사용
  const getLayout =
    (Component as LayoutNextComponentType).getLayout ||
    ((Page: NextComponentType, props: Record<string, unknown>) => (
      <Page {...props} />
    ));

  return (
    <SessionProvider session={pageProps.session}>
      <ApolloProvider client={getPrivateApiClient()}>
        <OrgAndSiteProvider>
          <BreadcrumbProvider>
            <FallbackRouter>
              <Head>
                <title>{DEFAULT_TITLE}</title>
                <meta
                  name="viewport"
                  content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
                />

                <meta name="description" content={DEFAULT_DESCRIPTION} />
                <meta property="og:type" content="website" />

                <meta
                  key="ogTitle"
                  property="og:title"
                  content={DEFAULT_TITLE}
                />
                <meta
                  key="ogDescription"
                  property="og:description"
                  content={DEFAULT_DESCRIPTION}
                />

                <meta name="twitter:card" content="summary_large_image" />
                <meta
                  property="twitter:domain"
                  content={process.env.NEXT_PUBLIC_APP_DOMAIN}
                />
                <meta
                  key="twitterTitle"
                  name="twitter:title"
                  content={DEFAULT_TITLE}
                />
                <meta
                  key="twitterDescription"
                  name="twitter:description"
                  content={DEFAULT_DESCRIPTION}
                />
                <meta
                  key="ogImage"
                  property="og:image"
                  content={`${getPublicAppURL()}/og_2.jpg`}
                />
                <meta
                  key="twitterImage"
                  name="twitter:image"
                  content={`${getPublicAppURL()}/og_2.jpg`}
                />

                <link rel="icon" href="/favicon.ico?v=2" />
                <link
                  rel="apple-touch-icon"
                  sizes="180x180"
                  href="/apple-touch-icon.png?v=1"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="32x32"
                  href="/favicon-32x32.png?v=1"
                />
                <link
                  rel="icon"
                  type="image/png"
                  sizes="16x16"
                  href="/favicon-16x16.png?v=1"
                />
                <link rel="manifest" href="/site.webmanifest" />
                <link
                  rel="mask-icon"
                  href="/safari-pinned-tab.svg?v=1"
                  color="#5bbad5"
                />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ffffff"></meta>
              </Head>
              <main>
                <Toaster
                  containerStyle={{ top: "0.75rem" }}
                  toastOptions={{
                    style: toastStyle,
                  }}
                />
                <I18n>
                  {getLayout(Component, pageProps)}
                  <ApplicationCounseling />
                </I18n>
              </main>
              <GTMUserIdConnector />
              <GTMCurrentSite />
              <GTMCurrentTeam />
              <TrackingScript />
            </FallbackRouter>
          </BreadcrumbProvider>
        </OrgAndSiteProvider>
      </ApolloProvider>
      <GTMPath path={router.pathname} />
    </SessionProvider>
  );
}

export default MyApp;
