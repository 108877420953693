import { useEffect, useRef, useState } from "react";

/**
 * loading 변수를 그대로 사용할 경우
 * - loading이 너무 짧으면 버튼이 움찔하는것처럼 보임
 * - loading = true 일때 100ms 이후에 변경 (100ms 이하 처리는 loading 변화 없음)
 * - true가 된 상태에서 false로 변경시 바로 변경하지 않고 최소 350ms 이후에 변경
 *   - true에서 350ms 이상 지났다면 바로 false, 아니면 350ms - 진행시간만큼 딜레이
 *
 * 인지하기 위해 150ms 정도가 필요하다고 했을때, 등장/사라짐 애니메이션이 각각 100ms 이므로 100+100+150 = 350ms로 정함
 */
const INIT_DELAY = 100;
const MIN_LOADING_DELAY = 350;

const useLoading = () => {
  const [state, setState] = useState(false);
  const [loading, setLoading] = useState(false);

  const timeoutRef = useRef<number | null>(null);
  const startLoadingTime = useRef<number | null>(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (state) {
      timeoutRef.current = window.setTimeout(() => {
        setLoading(true);
        startLoadingTime.current = Date.now();
      }, INIT_DELAY);
    } else {
      if (startLoadingTime.current === null) {
        // 로딩 시작전이면 바로 종료
        setLoading(false);
      } else {
        const delay = Math.max(
          MIN_LOADING_DELAY - (Date.now() - startLoadingTime.current),
          0
        );
        timeoutRef.current = window.setTimeout(() => {
          setLoading(false);
          startLoadingTime.current = null;
        }, delay);
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [state]);

  return [loading, setState] as const;
};

export default useLoading;
