import React from "react";

interface IV2ExcelamationMarkProps {
  width?: number;
  height?: number;
  color?: string;
}

const V2ExcelamationMark: React.FC<IV2ExcelamationMarkProps> = ({
  width = 17,
  height = 17,
  color = "text-primary-900",
}) => {
  const handleColor = (color: string) => {
    if (color === "text-primary-500") {
      return "/v2-icon/excelamation-mark2.svg";
    }
    if (color === "#CBCCDA") {
      return "/v2-icon/excelamation-mark3.svg";
    }
    return "/v2-icon/excelamation-mark.svg";
  };

  return (
    <img
      width={width}
      height={height}
      src={handleColor(color)}
      alt="excelamation-mark"
      draggable={false}
    />
  );
};

export default V2ExcelamationMark;
