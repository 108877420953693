import React from "react";

interface ICafe24IconProps {
  width?: number;
  height?: number;
}

const Cafe24Icon: React.FC<ICafe24IconProps> = ({
  width = 75,
  height = 14,
}) => {
  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140 26"
    >
      <path
        className="ast0"
        d="M12.3 19.8c-4.1 0-7.5-3.3-7.5-7.5 0-4.1 3.4-7.5 7.5-7.5 2 0 3.8.8 5.1 2L21 3.6C18.8 1.4 15.7 0 12.3 0 5.5 0 0 5.5 0 12.3c0 6.8 5.5 12.3 12.3 12.3 3.4 0 6.5-1.4 8.7-3.6l-3.3-3.5c-1.4 1.4-3.3 2.3-5.4 2.3"
      />
      <path
        className="ast1"
        d="M111.3 2c2.2 1.3 3.7 3.6 3.7 6.1 0 1.7-.6 3.4-1.8 4.6-1.3 1.4-3.3 1.9-5.1 2.3-1.3.3-2.8.5-4 1-1.3.5-2.7 1.4-2.7 3h13.9v4.5h-19v-3.9c0-2.4 1.1-4.6 3.5-6.3 1.8-1.2 3.9-1.9 5.9-2.3 1.9-.4 4.4-.6 4.4-3.1 0-2.4-3-3.2-5.1-3.1-2.4.1-4.1 1.1-5.3 2.4l-3.4-3.1C98.1 2 101.5.3 105 .2c2.3.4 4.5.8 6.3 1.8M116.6 13.7c-.7.9-1.4 2.1-.8 3.9.5 1 1.2 2 3.4 2h9.5v4h4.9v-4h4v-4.1h-4V3.8c0-1.8-1.4-3.1-3.2-3.2-1.5-.1-3.2.4-4.2 1.6l-9.6 11.5zm12.2-8v9.9h-8.4l8.4-9.9z"
      />
      <path
        className="ast0"
        d="M67.5 10.7H54.9V8.1c0-1.8.7-2.7 2.5-2.7h11V.9H56.5c-4.3 0-6.6 2.5-6.6 6.3v16.5h4.9v-8.9h12.6v-4.1zM93.8 10.8c-.3-2.5-1.6-5.1-3.3-6.7C88.3 2 85.4.6 82.1.6c-6.5 0-11.7 5.3-11.7 11.8 0 4.2 2.2 7.9 5.6 10 1.9 1.2 4 1.7 6.2 1.7 3.2 0 6.3-1.3 8.4-3.5l-3.5-3.1c-1.3 1.2-3 2-4.9 2-1.9 0-3.8-.8-5.1-2.1-.7-.7-1.2-1.6-1.6-2.4h18.1c0-.2.5-1.9.2-4.2m-18.6 0c.3-1.3.9-2.5 1.9-3.5C79.4 4.9 83 4.5 85.6 6c1.9 1.1 3.1 2.9 3.5 4.7H75.2zM40.2.9v2.4C38.8 1.5 36 .5 33.4.5c-6.5 0-11.7 5.3-11.7 11.7 0 6.4 5.2 11.7 11.6 11.7 2.5 0 5.2-.7 6.9-2.7v2.5h4.9V.9h-4.9zm-6.8 18.5c-3.9 0-7.1-3.2-7.1-7.1 0-3.9 3.2-7.1 7.1-7.1 3.9 0 7.1 3.2 7.1 7.1 0 3.9-3.2 7.1-7.1 7.1"
      />
    </svg>
  );
};

export default React.memo(Cafe24Icon);
