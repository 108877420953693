import React from "react";

interface ISixshopIconProps {
  width?: number;
  height?: number;
}

const SixshopIcon: React.FC<ISixshopIconProps> = ({
  width = 112,
  height = 140,
}) => {
  return (
    <img
      src="/images/sixshop_logo.svg"
      width={width}
      height={height}
      alt="sixshop"
    />
  );
};

export default SixshopIcon;
