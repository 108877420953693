import React from "react";

interface IGuideLinkIconProps extends React.SVGAttributes<SVGElement> {
  width?: number;
  height?: number;
  fill?: string;
}

const V2GuideLinkIcon: React.FC<IGuideLinkIconProps> = ({
  width = 23,
  height = 23,
  fill = "#989E96",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.40649 12.75H14.784L11.124 16.41C10.8315 16.7025 10.8315 17.1825 11.124 17.475C11.4165 17.7675 11.889 17.7675 12.1815 17.475L17.124 12.5325C17.4165 12.24 17.4165 11.7675 17.124 11.475L12.189 6.52497C12.0489 6.38453 11.8586 6.3056 11.6602 6.3056C11.4619 6.3056 11.2716 6.38453 11.1315 6.52497C10.839 6.81747 10.839 7.28997 11.1315 7.58247L14.784 11.25H6.40649C5.99399 11.25 5.65649 11.5875 5.65649 12C5.65649 12.4125 5.99399 12.75 6.40649 12.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default React.memo(V2GuideLinkIcon);
