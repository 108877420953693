import { createPosition } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { IFormPrivacyPolicyNode, PartialNode } from "../type";

const createFormPrivacyPolicyNode = (
  data?: PartialNode<IFormPrivacyPolicyNode>
): IFormPrivacyPolicyNode => {
  return {
    type: "FORM_PRIVACY_POLICY",
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    reactions: [],
    position: createPosition(data?.position),

    name: "",

    width: { value: 100, unit: "%" },

    fills: [],
    backgrounds: [],

    locked: false,

    strokes: [],
    strokeWeight: 1,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    visible: true,

    effects: [],

    label: "개인정보 수집 및 이용 동의",
    required: true,
    content: "",

    ...data,
  };
};

export default createFormPrivacyPolicyNode;
