import Cafe24Icon from "@/components/common/icon/cafe24-icon";
import ImwebIcon from "@/components/common/icon/imweb-icon";
import MakeshopIcon from "@/components/common/icon/makeshop-icon";
import NhncommerceIcon from "@/components/common/icon/nhncommerce-icon";
import OopyIcon from "@/components/common/icon/oopy-icon";
import ShopbyIcon from "@/components/common/icon/shopby-icon";
import ShopifyIcon from "@/components/common/icon/shopify-icon";
import SixshopIcon from "@/components/common/icon/sixshop-icon";
import WixIcon from "@/components/common/icon/wix-icon";
import { ExternalSiteType } from "@shared/common/types/site";
import React from "react";
import WordpressIcon from "../common/icon/wordpress-icon";

interface ISiteTypeLabelProps {
  siteType: ExternalSiteType;
  width?: number;
  height?: number;
}

const SiteTypeIcon: React.FC<ISiteTypeLabelProps> = ({
  siteType,
  width,
  height,
}) => {
  switch (siteType) {
    case "OOPY":
      return <OopyIcon width={width ?? 14} height={height ?? 14} />;
    case "SIXSHOP":
      return <SixshopIcon width={width ?? 12} height={height ?? 12} />;
    case "MAKESHOP":
      return <MakeshopIcon width={width ?? 51} height={height ?? 10} />;
    case "IMWEB":
      return <ImwebIcon width={width ?? 32} height={height ?? 10} />;
    case "CAFE24":
      return <Cafe24Icon width={width ?? 42} height={height ?? 13} />;
    case "SHOPIFY":
      return <ShopifyIcon width={width ?? 14} height={height ?? 14} />;
    case "WIX":
      return <WixIcon width={width ?? 22} height={height ?? 8} />;
    case "SHOPBY":
      return <ShopbyIcon width={width ?? 50} height={height ?? 10} />;
    case "SHOPBY_PRO":
      return <ShopbyIcon width={width ?? 50} height={height ?? 10} />;
    case "GODOMALL":
      return <NhncommerceIcon width={width ?? 48} height={height ?? 10} />;
    case "WORDPRESS":
      return <WordpressIcon width={width ?? 14} height={height ?? 14} />;
    default:
      return null;
  }
};

export default React.memo(SiteTypeIcon);
