import { createRecentVersionData } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { ISwiperContainerNode, PartialNode } from "../type";

const createSwiperContainerNode = (
  data?: PartialNode<ISwiperContainerNode>
): ISwiperContainerNode => {
  const fields = createRecentVersionData(data);
  const result: ISwiperContainerNode = {
    type: "SWIPER_CONTAINER",
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    position: { type: "RELATIVE" },
    reactions: [],
    name: "슬라이드 영역",

    width: {
      value: 100,
      unit: "%",
    },

    ...(!fields?.aspectRatio && {
      height: {
        value: 300,
        unit: "px",
      },
    }),
    locked: false,
    visible: true,

    strokes: [],
    strokeWeight: 0,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],
    cornerRadius: {
      bl: { unit: "px", value: 0 },
      br: { unit: "px", value: 0 },
      tl: { unit: "px", value: 0 },
      tr: { unit: "px", value: 0 },
    },

    fills: [],

    effects: [],
    alignHorizontal: "LEFT",
    loop: false,
    speed: 300,
    enabled: true,
    autoHeight: false,
    preventSlideOnVideoEnd: false,
  };

  Object.assign(result, fields);

  return result;
};

export default createSwiperContainerNode;
