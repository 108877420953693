import { createRecentVersionData } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { ISwiperButtonGroupNode, PartialNode } from "../type";

const createSwiperButtonGroupNode = (
  data?: PartialNode<ISwiperButtonGroupNode>
): ISwiperButtonGroupNode => {
  const fields = createRecentVersionData(data);

  const result: ISwiperButtonGroupNode = {
    type: "SWIPER_BUTTON_GROUP",
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    position: { type: "RELATIVE" },
    reactions: [],
    name: "슬라이드 버튼 그룹",

    width: {
      value: 100,
      unit: "%",
    },
    height: {
      value: 76,
      unit: "px",
    },

    locked: false,
    visible: true,
    strokes: [],
    strokeWeight: 0,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    fills: [],

    effects: [],
    alignHorizontal: "LEFT",
    style: {
      type: "CHEVRON",
      cornerRadius: {
        bl: { unit: "px", value: 0 },
        br: { unit: "px", value: 0 },
        tl: { unit: "px", value: 0 },
        tr: { unit: "px", value: 0 },
      },
      padding: {
        top: { unit: "px", value: 20 },
        left: { unit: "px", value: 20 },
        right: { unit: "px", value: 20 },
        bottom: { unit: "px", value: 20 },
      },
      color: { a: 1, b: 201, g: 201, r: 201 },
      effects: [],
      backgrounds: [],
    },
  };

  Object.assign(result, fields);

  return result;
};

export default createSwiperButtonGroupNode;
