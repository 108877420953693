import { createRecentVersionData } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { IInstagramGalleryNode, PartialNode } from "../type";

const createInstagramGalleryNode = (
  data?: PartialNode<IInstagramGalleryNode>
): IInstagramGalleryNode => {
  return {
    // 기본값
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    position: { type: "RELATIVE" },
    reactions: [],
    name: "인스타그램 갤러리",
    width: {
      value: 100,
      unit: "%",
    },
    padding: {},
    locked: false,
    visible: true,
    strokes: [],
    strokeWeight: 0,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],
    fills: [],
    effects: [],
    alignHorizontal: "LEFT",
    cornerRadius: {},
    row: 2,
    col: 5,
    minCellWidth: 150,
    gap: 0,

    // 인자값
    ...createRecentVersionData(data),

    // 필수값
    type: "INSTAGRAM_GALLERY",
  };
};

export default createInstagramGalleryNode;
