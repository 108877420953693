import { createPosition } from "@shared/common/utils/create";
import shortUUID from "short-uuid";
import type { IFormDropdownNode, PartialNode } from "../type";

const createFormDropdownNode = (
  data?: PartialNode<IFormDropdownNode>
): IFormDropdownNode => {
  return {
    type: "FORM_DROPDOWN",
    id: shortUUID.generate(),
    pluginData: {
      contentEditorPropSeq: 0,
      contentMeta: [],
    },
    reactions: [],
    position: createPosition(data?.position),

    name: "",

    width: { value: 100, unit: "%" },

    fills: [],

    locked: false,

    strokes: [],
    strokeWeight: 1,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    visible: true,

    effects: [],

    label: "<p>새 항목</p>",
    required: true,
    placeholder: "선택해주세요",

    ...data,

    items: data?.items
      ? data.items.map((item) => ({
          id: shortUUID.generate(),
          label: "",
          ...item,
        }))
      : [
          {
            id: shortUUID.generate(),
            label: "답변 1",
          },
          {
            id: shortUUID.generate(),
            label: "답변 2",
          },
        ],
  };
};

export default createFormDropdownNode;
