import "@/utils/i18n";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../common/ui/loader";

interface II18nInitializeProps {
  children: ReactNode;
}

const I18n: React.FC<II18nInitializeProps> = ({ children }) => {
  const [loading, setIsLoading] = useState(true);
  const { locale } = useRouter();
  const { i18n } = useTranslation();

  useEffect(() => {
    const changeLanguage = async () => {
      await i18n.changeLanguage(locale);
      setIsLoading(false);
    };
    changeLanguage();
  }, [locale, i18n]);

  if (loading) {
    return <Loader size="large" />;
  }

  return <>{children}</>;
};

export default I18n;
