import { gql } from "@apollo/client";

const CAMPAIGN_TEMPLATE_FIELDS = gql`
  fragment CampaignTemplateFields on CampaignTemplate {
    id
    campaignId
    userId
    organizationId
    campaignType
    name
    tags
    preview
    device
    seq
    isActive
    isRender
    createdAt
    updatedAt
  }
`;

export const GET_CAMPAIGN_TEMPLATE = gql`
  ${CAMPAIGN_TEMPLATE_FIELDS}
  query GetCampaignTemplate($campaignTemplateId: ID!) {
    campaignTemplate(id: $campaignTemplateId) {
      ...CampaignTemplateFields
    }
  }
`;

export const GET_CAMPAIGN_TEMPLATES = gql`
  ${CAMPAIGN_TEMPLATE_FIELDS}
  query GetCampaignTemplates($type: CampaignType!) {
    campaignTemplates(type: $type) {
      ...CampaignTemplateFields
    }
  }
`;

export const GET_CAMPAIGN_TEMPLATES_BY_CAMPAIGN_ID = gql`
  ${CAMPAIGN_TEMPLATE_FIELDS}
  query GetCampaignTemplatesByCampaignId($campaignId: ID!) {
    campaignTemplatesByCampaignId(campaignId: $campaignId) {
      ...CampaignTemplateFields
    }
  }
`;

export const GET_CAMPAIGN_TEMPLATE_DATA = gql`
  query GetCampaignTemplateData($id: ID!) {
    campaignTemplateData(id: $id)
  }
`;

export const UPSERT_CAMPAIGN_TEMPLATE = gql`
  ${CAMPAIGN_TEMPLATE_FIELDS}

  mutation UpsertCampaignTemplate($campaignTemplate: CampaignTemplateInput!) {
    upsertCampaignTemplate(campaignTemplate: $campaignTemplate) {
      ...CampaignTemplateFields
    }
  }
`;
