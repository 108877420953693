export const EMAIL_LOGIN_TOKEN_TYPE = 0;
export const EMAIL_VERIFICATION_TOKEN_TYPE = 1;
export const SMS_VERIFICATION_TOKEN_TYPE = 2;
export const EMAIL_VERIFICATION_CONFIRM_TOKEN_TYPE = 3;
export const SMS_VERIFICATION_CONFIRM_TOKEN_TYPE = 4;
export const INTEGRATION_VERIFICATION_CONFIRM_TOKEN_TYPE = 5;
export const EASY_CANVAS_TOKEN_TYPE = 6;

export type VERIFICATION_TOKEN_TYPE =
  | typeof EMAIL_LOGIN_TOKEN_TYPE
  | typeof EMAIL_VERIFICATION_TOKEN_TYPE
  | typeof SMS_VERIFICATION_TOKEN_TYPE
  | typeof EMAIL_VERIFICATION_CONFIRM_TOKEN_TYPE
  | typeof SMS_VERIFICATION_CONFIRM_TOKEN_TYPE
  | typeof INTEGRATION_VERIFICATION_CONFIRM_TOKEN_TYPE
  | typeof EASY_CANVAS_TOKEN_TYPE;

export enum SMS_VERIFICATION_CONFIRM_TYPE {
  SIGN_UP_EMAIL = 1,
  SIGN_UP_SOCIAL = 2,
  FIND_EMAIL = 3,
  FIND_PASSWORD = 4,
  MIGRATE_EMAIL = 5,
  MIGRATE_SOCIAL = 6,
}

export enum EMAIL_VERIFICATION_CONFIRM_TYPE {
  SIGN_UP_EMAIL = 1,
}

export const SALT_ROUND = 11;

export const AUTH_ERROR_TYPE = {
  SEND_EMAIL_ERROR: "SEND_EMAIL_ERROR",
  SEND_SMS_ERROR: "SEND_SMS_ERROR",
  NOT_ENOUGH_PARAMETERS: "NOT_ENOUGH_PARAMETERS",
  INVALID_TOKEN: "INVALID_TOKEN",
  VERIFY_TOKEN_ERROR: "VERIFY_TOKEN_ERROR",
  SIGNUP_ERROR: "SIGNUP_ERROR",
  DUPLICATED_EMAIL: "DUPLICATED_EMAIL",
  NO_USER: "NO_USER",
  UNAUTHENTICATED: "UNAUTHENTICATED",
} as const;

export const AUTH_ERROR_MESSAGE = {
  [AUTH_ERROR_TYPE.SEND_EMAIL_ERROR]:
    "메일 발송 과정에서 에러가 발생했습니다. 다시 시도해 주세요.",
  [AUTH_ERROR_TYPE.SEND_SMS_ERROR]:
    "문자 발송 과정에서 에러가 발생했습니다. 다시 시도해 주세요.",
  [AUTH_ERROR_TYPE.NOT_ENOUGH_PARAMETERS]:
    "잘못된 요청입니다. 다시 시도해 주세요.",
  [AUTH_ERROR_TYPE.NO_USER]: "일치하는 사용자가 없습니다. 다시 확인해 주세요.",
  [AUTH_ERROR_TYPE.INVALID_TOKEN]: "인증번호가 일치하지 않습니다.",
  [AUTH_ERROR_TYPE.VERIFY_TOKEN_ERROR]:
    "인증 과정에서 에러가 발생했습니다. 다시 시도해 주세요.",
  [AUTH_ERROR_TYPE.SIGNUP_ERROR]:
    "회원가입 과정에서 에러가 발생했습니다. 다시 시도해 주세요.",
  [AUTH_ERROR_TYPE.DUPLICATED_EMAIL]: "이미 가입된 이메일입니다.",
  [AUTH_ERROR_TYPE.UNAUTHENTICATED]: "인증에 실패했습니다.",
};

export const USER_INPUT_STEP = {
  AGREEMENT: "agreement",
  USER_INFO: "user-info",
  ADDITIONAL_INFO: "additional-info",
} as const;

export type USER_INPUT_STEP =
  (typeof USER_INPUT_STEP)[keyof typeof USER_INPUT_STEP];
