import Link from "next/link";
import {
  ComponentProps,
  HtmlHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
} from "react";
import { twMerge } from "tailwind-merge";

interface ITabProps extends PropsWithChildren {
  tabBarExtraContent?: ReactNode;
  type?: "white" | "gray";
  fullWidth?: boolean;
  padding?: "lg" | "sm";
  gap?: "lg" | "sm";
}

const TabPropsContext = createContext<
  Required<Pick<ITabProps, "type" | "padding" | "fullWidth">>
>({ padding: "lg", type: "gray", fullWidth: false });

const Tab = ({
  tabBarExtraContent,
  children,
  fullWidth = false,
  type = "white",
  padding = "sm",
}: ITabProps) => {
  return (
    <div
      className={
        "flex justify-between w-full border-b border-stroke items-center"
      }
    >
      <TabPropsContext.Provider value={{ type, padding, fullWidth }}>
        <div className={twMerge("flex items-end", fullWidth && "w-full")}>
          {children}
        </div>
        <div>{tabBarExtraContent}</div>
      </TabPropsContext.Provider>
    </div>
  );
};

interface IV2TabLabelProps
  extends PropsWithChildren<HtmlHTMLAttributes<HTMLDivElement>> {
  selected?: boolean;
  size?: "large" | "small";
}

const V2TabLabel = ({
  selected = false,
  children,
  size = "large",
  className,
  ...props
}: IV2TabLabelProps) => {
  const { type, fullWidth } = useContext(TabPropsContext);

  return (
    <div
      className={twMerge(
        type === "white" && "-mb-px border-b-2 border-cb-gray-900",
        type === "white" && !selected && "border-transparent",
        fullWidth && "flex-grow text-center"
      )}
      {...props}
    >
      <div
        className={twMerge(
          "text-sm font-semibold leading-6 text-cb-gray-900 cursor-pointer px-4 py-1",
          type === "white" && !selected && "text-gray-500 font-medium",
          size === "small" && "text-base",
          type === "gray" && "bg-neutral-10 rounded-tr-md rounded-tl-md",
          type === "gray" && selected && "font-medium",
          type === "gray" && !selected && "bg-white text-gray-500 font-normal",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

interface IV2TabLinkProps
  extends PropsWithChildren<ComponentProps<typeof Link>> {
  selected?: boolean;
  size?: "large" | "small";
}

const V2TabLink = ({
  selected = false,
  children,
  size = "large",
  className,
  ...props
}: IV2TabLinkProps) => {
  const { type } = useContext(TabPropsContext);

  return (
    <Link
      className={twMerge(
        type === "white" && "-mb-px border-b-2 border-cb-gray-900",
        type === "white" && !selected && "border-transparent"
      )}
      {...props}
    >
      <div
        className={twMerge(
          "text-sm font-semibold leading-6 text-cb-gray-900 cursor-pointer px-4 py-1",
          type === "white" && !selected && "text-gray-500 font-medium",
          size === "small" && "text-base",
          type === "gray" && "bg-neutral-10 rounded-tr-md rounded-tl-md",
          type === "gray" && selected && "font-medium",
          type === "gray" && !selected && "bg-white text-gray-500 font-normal",
          className
        )}
      >
        {children}
      </div>
    </Link>
  );
};

Tab.Label = V2TabLabel;
Tab.Link = V2TabLink;

export default Tab;
