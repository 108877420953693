import { noopFunction } from "@shared/common/utils/helper";
import React, { ReactNode, useContext, useState } from "react";

export interface IBreadcrumb {
  key: string;
  renderLabel: () => ReactNode;
  href?: string;
}

interface IBreadcrumbContext {
  initialized: boolean;
  breadcrumbs: IBreadcrumb[] | null;
  setBreadcrumbs: (breadcrumbs: IBreadcrumb[] | null) => void;
}

const context = React.createContext<IBreadcrumbContext>({
  initialized: false,
  breadcrumbs: null,
  setBreadcrumbs: noopFunction,
});

export function useBreadcrumbContext() {
  const result = useContext(context);
  if (!result.initialized) {
    throw new Error(
      "Breadcrumb context must be used within a BreadcrumbProvider!"
    );
  }
  return result;
}

interface IBreadcrumbProviderProps {
  children: ReactNode;
}

const BreadcrumbProvider: React.FC<IBreadcrumbProviderProps> = ({
  children,
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[] | null>(null);

  return (
    <context.Provider
      value={{
        initialized: true,
        breadcrumbs,
        setBreadcrumbs,
      }}
    >
      {children}
    </context.Provider>
  );
};

export default BreadcrumbProvider;
