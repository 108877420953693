import React from "react";
import { ISupporter } from ".";

interface IApplicationCounselingComplete {
  supporter?: ISupporter | null;
  schedule: number;
}

const ApplicationCounselingComplete: React.FC<
  IApplicationCounselingComplete
> = ({ supporter, schedule }) => {
  const getScheduleToString = (schedule: number) => {
    let description: React.ReactNode = "";
    // 0으로 들어오는 경우 없음
    if (!schedule || schedule === 1) {
      description = "예약이 완료되었습니다.";
    } else {
      if (schedule === 2) {
        description = "09:00~11:00";
      } else if (schedule === 3) {
        description = "11:00~14:00";
      } else {
        description = "14:00~18:00";
      }
      return (
        <>
          <span className="font-bold">{description}</span>시로 예약이
          완료되었습니다.
        </>
      );
    }

    return description;
  };

  return (
    <>
      <p>
        {getScheduleToString(schedule)}
        <br />
        영업일 2~3일 내로 연락드리겠습니다.
        <br />
        감사합니다.
        <br />
        <br />
        상담 시간을 변경하려면 다시 신청해 주시면 됩니다.
      </p>

      <p className="mt-4 font-semibold">{supporter?.name}</p>
    </>
  );
};

export default React.memo(ApplicationCounselingComplete);
