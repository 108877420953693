import type { USER_INPUT_STEP } from "@shared/common/constant/auth";
import type { CampaignType } from "../types/campaign";
import type { TemplateTag } from "../types/template";
export type DataLayerItem = {
  event: string;
  [key: string]: unknown;
};

export type DataLayer = {
  push: (data: DataLayerItem) => void;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    dataLayer?: DataLayer;
  }
}

export const getDataLayer = (): DataLayer => {
  return window.dataLayer || ([] as DataLayerItem[]);
};

export const getGtmIdByUserInputStep = (
  userInputStep: (typeof USER_INPUT_STEP)[keyof typeof USER_INPUT_STEP]
): string => {
  switch (userInputStep) {
    case "agreement":
      return "join_agree";
    case "user-info":
      return "join_main_info";
    case "additional-info":
      return "join_extra_info";
  }
};

export type GTMCategory =
  | "all"
  | "dashboard"
  | "campaign_popup"
  | "campaign_gpopup"
  | "campaign_gbanner"
  | "campaign_insta"
  | "page_eventpage"
  | "form_eventform"
  | "form_survey"
  | "ai_copywriting"
  | "ai_tone"
  | "ai_remove"
  | "statistics"
  | "profile"
  | "team"
  | "payment"
  | "site"
  | "join"
  | "landing_page";

export const getGTMCategoryFromCampaignType = (
  type: CampaignType
): GTMCategory => {
  switch (type) {
    case "INSTAGRAM":
    case "INSTAGRAM_V2":
      return "campaign_insta";
    case "POPUP":
      return "campaign_popup";
    case "BANNER":
      return "campaign_gbanner";
    case "BANNER_GROUP":
      return "campaign_gbanner";
    case "POPUP_GROUP":
      return "campaign_gpopup";
    case "FORM_EVENT":
      return "form_eventform";
    case "PROMOTION":
      return "page_eventpage";
    case "FORM_SURVEY":
      return "form_survey";
  }
};

export const getStatGTMPageIdFromCampaignType = (
  type: CampaignType
): string => {
  switch (type) {
    case "INSTAGRAM":
    case "INSTAGRAM_V2":
      return "insta_statistics";
    case "POPUP":
      return "popup_statistics";
    case "BANNER":
      return "gbanner_statistics";
    case "BANNER_GROUP":
      return "gbanner_statistics";
    case "POPUP_GROUP":
      return "gpopup_statistics";
    case "FORM_EVENT":
      return "eventform_statistics";
    case "PROMOTION":
      return "eventpage_statistics";
    case "FORM_SURVEY":
      return "survey_statistics";
  }
};

export const getGTMIdFromTemplateTag = (
  tag: TemplateTag | "STYLE_ALL" | "POPUP_ALL"
): string => {
  switch (tag) {
    case "notice":
      return "template_sample_design_notice";
    case "delivery":
      return "template_sample_design_delivery";
    case "sale":
      return "template_sample_design_discount";
    case "event":
      return "template_sample_design_event";
    case "mileage":
      return "template_sample_design_point";
    case "signup":
      return "template_sample_design_signup";
    case "season":
      return "template_sample_design_season";
    case "mini":
      return "template_sample_design_mini";
    case "app":
    case "airbridge":
      return "template_sample_design_app";
    case "POPUP_ALL":
      return "template_sample_design_all";
    case "STYLE_ALL":
      return "template_sample_style_all";
    case "best":
      return "template_sample_design_best";
    case "new":
      return "template_sample_design_new";
    case "consult":
      return "template_sample_design_consult";
  }
};
