export const addComma = (num: number) => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ",");
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NullRemoved<T> = T extends null
  ? never
  : T extends object
  ? { [P in keyof T]: NullRemoved<T[P]> }
  : T;

/**
 * 어떤 객체에서 `null` 을 모두 삭제합니다.
 * GraphQL 의 코드젠을 통해 생성된 타입 중 `InputMaybe` 는
 * `T | null | undefined` 등으로 처리되는데,
 * 이를 `T | undefined` 로 바꿀 때 사용됩니다.
 * `null` 혹은 `undefined`가 배열의 요소일 경우 삭제하지 않습니다.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeNullDeep = <T extends { [key: string]: any }>(
  obj: T
): NullRemoved<T> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result = {} as any;
  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (value === null || value === undefined) {
      return;
    }

    if (Array.isArray(value)) {
      result[key] = value.map((child) => {
        if (child === null) {
          return null;
        }

        if (typeof child === "object") {
          return removeNullDeep(child);
        }

        return child;
      });

      return;
    }

    if (typeof value === "object") {
      result[key] = removeNullDeep(value);
      return;
    }

    result[key] = value;
  });

  return result;
};

/**
 * 새로운 셔플된 배열을 반환합니다.
 */
export function shuffle<T>(array: T[]) {
  const result = [...array];
  let currentIndex = result.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [result[currentIndex], result[randomIndex]] = [
      result[randomIndex] as T,
      result[currentIndex] as T,
    ];
  }

  return result;
}

export const isObject = (
  value: unknown
): value is Record<string | number | symbol, unknown> =>
  value === Object(value);

export const assertCondition = <Val>(
  condition: (value: unknown) => value is Val
) => {
  return {
    typeSafeValue: (value: unknown, defaultValue: Val): Val => {
      return condition(value) ? value : defaultValue;
    },
    assert(
      value: unknown,
      message = "타입이 일치하지 않습니다."
    ): asserts value is Val {
      if (!condition(value)) {
        throw new Error(message);
      }
    },
  };
};

/**
 * 한 객체에서 undefined 혹은 null 인 필드를 모두 삭제합니다.
 * 재귀적으로 동작하지 않습니다.
 */
export const removeUndefinedOrNullField = <T>(object: T): T => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result = { ...object } as any;
  Object.keys(result).forEach((key) => {
    if (result[key] === undefined || result[key] === null) {
      delete result[key];
    }
  });
  return result;
};

/** Promise.allSettled 의 결과에서 rejected 된 것만 가져옵니다. */
export function filterPromiseRejectedResult<T extends unknown[]>(promises: {
  [K in keyof T]: PromiseSettledResult<T[K]>;
}): PromiseRejectedResult[] {
  return promises.filter(
    (result): result is PromiseRejectedResult => result.status === "rejected"
  );
}

/** Promise.allSettled 의 결과에서 fulfilled 된 것만 가져옵니다. */
export function filterPromiseFulfilledResult<T extends unknown[]>(promises: {
  [K in keyof T]: PromiseSettledResult<T[K]>;
}): PromiseFulfilledResult<T[number]>[] {
  return promises.filter(
    (result): result is PromiseFulfilledResult<T[number]> =>
      result.status === "fulfilled"
  );
}

/** 문자열의 byte길이를 반환합니다.
 * 각 문자에 대해 유니코드로 변환 후 2^7로 나누었을 때의 몫이 1보다 크면 2byte, 그렇지 않으면 1byte로 계산합니다.
 * 한글은 예외적으로 2byte로 처리합니다 */
export const getStringByteLength = (str: string) => {
  return str.split("").reduce((len: number, char: string) => {
    const charCode = char.charCodeAt(0);
    return len + (charCode >> 7 ? 2 : 1);
  }, 0);
};

export const getGCD = (num1: number, num2: number): number => {
  return num2 > 0 ? getGCD(num2, num1 % num2) : num1;
};

/**
 * 배열의 요소 중 falsy 한 값들을 제거할 때 사용합니다.
 *
 * @example
 * ```js
 * const arr = [1, 2, 3, 0, 4, 5, null, 6, undefined, 7, 8, ""];
 * const filtered = arr.filter(isNotFalsy); // [1, 2, 3, 4, 5, 6, 7, 8]
 * ```
 */
export function isNotFalsy<T>(
  value: T
): value is Exclude<T, null | undefined | 0 | ""> {
  return Boolean(value);
}
