import shortUUID from "short-uuid";
import type {
  IDocumentData,
  IFrameNode,
  IImageNode,
  IVideoNode,
} from "../node/type";
import { findAllNodeByPredicate } from "../node/util";
import type {
  Data,
  DataSource,
  IDataMap,
  IInstagramDataSourceV2,
  IInstagramDataV2,
  ILocalData,
  IVideoData,
  IVideoDataSourceParam,
  PartialData,
  PartialInsagramDataV2,
  PartialLocalData,
  PartialVideoData,
  VideoInfo,
} from "../types/data-source";
import { isObject } from "./value";

type GetDataSourceValueArgs = {
  dataMap: IDataMap;
  keyString: string;
};

export const getDataSourceValue = ({
  dataMap,
  keyString,
}: GetDataSourceValueArgs) => {
  try {
    const [dataSourceName, ...keys] = keyString.split(".");

    if (!dataSourceName) {
      throw new Error("incorrect template syntax");
    }

    const dataSource = dataMap[dataSourceName]?.data;

    // dataSource가 없을 경우 null 반환(프레임과 이미지에서 비디오 데이터를 찾기 때문에, Error 반환시 warning 콘솔이 발생이 이유)
    if (!dataSource) {
      return null;
    }

    return getNestedObjectValue(dataSource, keys);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }
};

export const isKeyOfObject = (obj: object, key: string) =>
  Object.keys(obj).includes(key);

export const getNestedObjectValue = (object: unknown, keys: string[]) => {
  let nestedValue: unknown = object;

  for (const key of keys) {
    if (!isObject(nestedValue) || !isKeyOfObject(nestedValue, key)) {
      throw new Error("invalid key in object");
    }

    nestedValue = nestedValue[key as keyof typeof nestedValue];
  }

  return nestedValue;
};

export const createData = (initialData?: PartialData) => {
  switch (initialData?.name) {
    case "instagram":
      return createInstagramDataV2(initialData);
    case "local":
      return createLocalData(initialData);
    case "video":
      return createVideoData(initialData);
    default:
      return createLocalData();
  }
};

export const createVideoData = (initialData?: PartialVideoData): IVideoData => {
  return {
    id: initialData?.id ?? shortUUID.generate(),
    name: "video",
    data: initialData?.data ?? [],
  };
};

export const createLocalData = (initialData?: PartialLocalData): ILocalData => {
  return {
    id: initialData?.id ?? shortUUID.generate(),
    name: "local",
    data: initialData?.data ?? {},
  };
};

export const createInstagramDataV2 = (
  initialData?: PartialInsagramDataV2
): IInstagramDataV2 => {
  return {
    id: initialData?.id ?? shortUUID.generate(),
    name: "instagram",
    data: initialData?.data ?? { username: "", posts: [] },
  };
};

export const createDataMap = (initialDataMap?: {
  [name: string]: PartialData;
}): IDataMap => {
  return {
    local: createLocalData(),
    ...Object.entries(initialDataMap ?? {}).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: createData(value),
      }),
      {}
    ),
  };
};

export const getData = <T extends Data = ILocalData>(
  dataMap: IDataMap,
  key: string
) => {
  const dataSource = dataMap[key] as T | undefined;
  return dataSource;
};

export const getDataSourceBySourceType = <T extends DataSource>(
  dataSources: DataSource[],
  sourceType: T["sourceType"]
): T | undefined => {
  const dataSourceFromSourceType = dataSources.find(
    (dataSource): dataSource is T => dataSource.sourceType === sourceType
  );

  return dataSourceFromSourceType;
};

export const getDataSourcesBySourceType = <T extends DataSource>(
  dataSources: DataSource[],
  sourceType: T["sourceType"]
): T[] | [] => {
  const dataSourcesFromSourceType = dataSources.filter(
    (dataSource): dataSource is T => dataSource.sourceType === sourceType
  );

  return dataSourcesFromSourceType;
};

export const getInstagramSourceType = (dataSource: IInstagramDataSourceV2) => {
  return dataSource.sourceParam.source.type;
};

export const mergeVideoDataMaps = (
  dataMaps: PartialVideoData[]
): IVideoData => {
  const mergedData: VideoInfo[] = [];

  const id = dataMaps.find((dataMap) => dataMap?.id)?.id ?? "";

  dataMaps.forEach((dataMap) => {
    if (!dataMap || !dataMap.data) {
      return;
    }

    dataMap.data.forEach((video) => {
      const existingIndex = mergedData.findIndex(
        (item) => item.filename === video.filename
      );
      if (existingIndex !== -1) {
        mergedData[existingIndex] = video; // 최신 데이터로 교체
      } else {
        mergedData.push(video);
      }
    });
  });

  return {
    id, // id는 임의로 첫 번째 dataMap의 id를 사용
    name: "video",
    data: mergedData,
  };
};

/**
 * 역할)
 * 1. Temp Campaign 혹은 Template DocumentData를 인자로 받았을 때, VideoDataSource를 세팅합니다.
 * 2. VideoDataSource를 Upsert 하기전에, DocumentData를 기반으로 VideoDataSource를 세팅합니다.
 */
export const convertDocumentDataToVideoSourceParam = (
  documentData: IDocumentData
): IVideoDataSourceParam => {
  const documentNodes = documentData.documents;

  if (!documentNodes || documentNodes.length === 0) {
    return { data: [] };
  }

  const videoNodes = findAllNodeByPredicate(
    documentNodes,
    (node): node is IVideoNode | IImageNode | IFrameNode =>
      node.type === "VIDEO" || node.type === "IMAGE" || node.type === "FRAME"
  );

  const sourceParam: IVideoDataSourceParam["data"] = [];
  videoNodes.forEach((videoNode) => {
    if (videoNode.type === "IMAGE" || videoNode.type === "VIDEO") {
      if (!videoNode || !videoNode.videoInfo || !videoNode.videoInfo.fileName) {
        return;
      }

      const sourceParamData = {
        nodeId: videoNode.id,
        filename: videoNode.videoInfo.fileName,
      };

      sourceParam.push(sourceParamData);
    } else if (videoNode.type === "FRAME") {
      const fill = videoNode.fills.find((fill) => fill.type === "IMAGE");

      if (!videoNode || fill?.type !== "IMAGE" || !fill.video?.fileName) {
        return;
      }

      const sourceParamData = {
        nodeId: videoNode.id,
        filename: fill.video.fileName,
      };
      sourceParam.push(sourceParamData);
    }
  });

  return {
    data: sourceParam,
  };
};
