import useLoading from "@/hooks/use-loading";
import Link from "next/link";
import { ComponentProps, useEffect } from "react";
import { twMerge } from "tailwind-merge";
import Loader from "../ui/loader";
import styles from "./loading-button.module.css";

type ButtonThemeType =
  | "primary"
  | "secondary"
  | "brand"
  | "outline"
  | "ghost"
  | "link"
  | "minimal"
  | "text"
  | "default"
  | "default-new"
  | "danger"
  | "add"
  | "delete"
  | "add-primary"
  | "disabled";

export interface ILoadingButtonCommonProps {
  theme?: ButtonThemeType;
  disabled?: boolean;
  block?: boolean;
  size?: "small" | "default" | "large";
  round?: boolean;
  icon?: React.ReactNode;
  className?: string;
}

export interface IButtonTypeProps
  extends ILoadingButtonCommonProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

interface ILinkTypeProps
  extends ILoadingButtonCommonProps,
    ComponentProps<typeof Link> {
  loading?: boolean;
  type: "link";
}

type LoadingButtonProps = IButtonTypeProps | ILinkTypeProps;

/**
 * @param `type` - 페이지 이동시 `"link"`, form에서 사용시 `"submit"`
 */
const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
  const {
    theme,
    block,
    size,
    round,
    icon,
    className: customClassName,
    children,
    style,
    ...unionTypeProps
  } = props;
  const [loading, setLoading] = useLoading();

  useEffect(() => {
    setLoading(unionTypeProps.loading || false);
  }, [setLoading, unionTypeProps.loading]);

  const className = twMerge(
    "text-base rounded flex justify-center items-center transition-all duration-300", // px-5 제거 -> 기본 width필요
    styles.button,

    // theme
    theme === "primary" &&
      "bg-cb-gray-900 text-white hover:bg-cb-gray-700 hover:text-white disabled:bg-cb-gray-500 disabled:text-cb-disabled",
    theme === "primary" &&
      unionTypeProps.loading &&
      "bg-cb-gray-600 text-white hover:bg-cb-gray-600 hover:text-white disabled:text-white disabled:bg-cb-gray-600",
    theme === "secondary" &&
      "bg-cb-gray-300 text-cb-gray-900 hover:bg-cb-gray-400 hover:text-cb-gray-900 disabled:bg-cb-gray-500 disabled:text-cb-disabled",
    theme === "secondary" &&
      unionTypeProps.loading &&
      "bg-cb-gray-300 text-cb-gray-600 hover:bg-cb-gray-300 hover:text-cb-gray-600 disabled:bg-cb-gray-300 disabled:text-cb-gray-600",
    theme === "brand" &&
      "bg-cb-brand text-cb-gray-900 hover:bg-cb-brand-hover hover:text-cb-gray-900 disabled:bg-cb-gray-500 disabled:text-cb-disabled",
    theme === "brand" &&
      unionTypeProps.loading &&
      "bg-cb-brand text-cb-gray-600 hover:bg-cb-brand hover:text-cb-gray-600 disabled:bg-cb-brand disabled:text-cb-gray-600",
    theme === "outline" &&
      "bg-white text-cb-gray-800 border border-cb-gray-700 hover:text-cb-gray-700 hover:border-cb-gray-600 disabled:border-transparent disabled:bg-cb-gray-500 disabled:text-cb-disabled",
    theme === "outline" &&
      unionTypeProps.loading &&
      "bg-white text-cb-gray-600 border-cb-gray-600 hover:text-cb-gray-600 hover:border-cb-gray-600 disabled:border disabled:text-cb-gray-600 disabled:border-cb-gray-600 disabled:bg-white",
    theme === "ghost" &&
      "bg-white text-cb-gray-900 hover:bg-cb-gray-200 hover:text-cb-gray-800 disabled:bg-white disabled:text-cb-disabled",
    theme === "ghost" &&
      unionTypeProps.loading &&
      "bg-white text-cb-gray-500 hover:bg-white hover:text-cb-gray-500 disabled:bg-white disabled:text-cb-gray-500",
    theme === "link" &&
      "bg-white text-cb-gray-700 hover:text-cb-gray-600 hover:underline disabled:bg-white disabled:text-cb-disabled disabled:hover:no-underline",
    theme === "link" &&
      unionTypeProps.loading &&
      "bg-white text-cb-gray-500 hover:bg-white hover:text-cb-gray-500 disabled:bg-white disabled:text-cb-gray-500",
    theme === "minimal" &&
      "text-cb-gray-900 hover:text-cb-gray-900 hover:bg-cb-gray-100 bg-white",
    theme === "text" && "bg-white text-cb-gray-500 hover:text-cb-gray-900",
    theme === "default" &&
      "bg-white text-primary-700 hover:text-primary-700 border border-white hover:border-primary-600 disabled:bg-white disabled:border-none",
    theme === "default-new" &&
      "bg-white border-stroke text-cb-gray-900 hover:text-cb-gray-900 border hover:bg-cb-gray-100 disabled:bg-cb-gray-200",
    theme === "add" &&
      "text-success-400 hover:text-success-400 hover:bg-success-100",
    theme === "delete" &&
      "text-error-200 hover:text-error-200 hover:bg-error-20",
    theme === "danger" &&
      "text-white hover:text-white bg-error-300 hover:bg-error-200",
    theme === "add-primary" &&
      "bg-primary-400 text-primary-800 hover:bg-primary-800 hover:text-primary-100",
    theme === "disabled" && "cursor-default",

    // options
    block ? "w-full" : "",
    round ? "rounded-full" : "",
    size === "small" ? "h-[8.25rem] text-sm" : "h-9",
    unionTypeProps.type !== "link" && unionTypeProps.loading
      ? "cursor-default"
      : "",
    !props.style?.width ? "px-4" : "",
    customClassName
  );

  if (unionTypeProps.type === "link") {
    const { disabled, ...nativeProps } = unionTypeProps;
    return !disabled ? (
      <Link
        {...nativeProps}
        style={{
          height: size === "small" ? "33px" : "36px",
          ...style,
        }}
        className={className}
      >
        {icon && <div className={disabled ? "grayscale" : ""}>{icon}</div>}

        <div>{children}</div>
      </Link>
    ) : (
      <button
        disabled={disabled}
        className={className}
        style={{
          height: size === "small" ? "33px" : "36px",
          ...style,
        }}
      >
        {icon && <div className="grayscale">{icon}</div>}

        <div>{children}</div>
      </button>
    );
  }

  const { loading: _, ...nativeProps } = unionTypeProps;
  return (
    <button
      {...nativeProps}
      type={nativeProps.type || "button"}
      disabled={nativeProps.disabled || loading}
      className={className}
      style={{
        height: size === "small" ? "33px" : "36px",
        ...style,
      }}
    >
      <Loader
        size="small"
        className={twMerge(
          "transition-all max-w-full duration-100",
          loading ? "opacity-100 mr-2" : "w-0 opacity-0"
        )}
        block
      />

      {icon && !loading && (
        <div className={twMerge("mr-2", nativeProps.disabled && "grayscale")}>
          {icon}
        </div>
      )}

      <div>{props.children}</div>
    </button>
  );
};

export default LoadingButton;
