import { createPosition } from "@shared/common/utils/create";
import dayjs from "dayjs";
import shortUUID from "short-uuid";
import type { ITimerNode, PartialNode } from "../type";

const createTimerNode = (data?: PartialNode<ITimerNode>): ITimerNode => {
  return {
    id: shortUUID.generate(),
    pluginData: {},
    reactions: [],
    position: createPosition(data?.position),

    // 노드트리 등에서 보이는 이름
    name: "",

    width: { value: 100, unit: "%" },
    alignHorizontal: "LEFT",

    fills: [],
    backgrounds: [],

    locked: false,

    paragraphIndent: {
      value: 0,
      unit: "em",
    },

    lineHeight: {
      value: 1.3,
      unit: "",
    },

    paragraphSpacing: {
      value: 0,
      unit: "em",
    },

    strokes: [],
    strokeWeight: 1,
    strokeAlign: "CENTER",
    strokeCap: "SQUARE",
    strokeJoin: "ROUND",
    dashPattern: [],

    textAlignHorizontal: "CENTER",
    textAlignVertical: "CENTER",
    // TODO: 현재 기본값이 HEIGHT일 뿐, 세로 자동 조정은 height 속성 여부에 의해 결정됨. WIDTH_AND_HEIGHT일 때에는 가로 자동 조정됨.
    textAutoResize: "HEIGHT",

    fontSize: {
      value: 16,
      unit: "px",
    },

    visible: true,

    effects: [],
    boxShadowEffects: [],
    fontName: { style: "normal", family: "InterRegular-ascii" },

    padding: {
      top: {
        value: 10,
        unit: "px",
      },
      bottom: {
        value: 10,
        unit: "px",
      },
      left: {
        value: 0,
        unit: "px",
      },
      right: {
        value: 0,
        unit: "px",
      },
    },
    content: "00일 00:00:00",

    timer: {
      type: "COUNTDOWN",
      endAt: dayjs().add(1, "day").toISOString(),
      format: "dd일 hh:mm:ss",
    },

    // 인자값
    ...data,

    // 필수값
    type: "TIMER",
  };
};

export default createTimerNode;
