import {
  CampaignTargetLocOperator,
  CampaignTargetType,
  IPageUIData,
} from "@shared/common/types/campaign";
import shortUUID from "short-uuid";

export const createPageUIData = (page?: Partial<IPageUIData>): IPageUIData => {
  return {
    type: "page",
    list: [
      {
        type: "NOT_SET",
        value: "",
        params: {},
        action: "NOT_SET",
      },
    ],
    ...page,
  };
};

const generatePageTargetOperator = (
  state: "INCLUDE" | "EQUAL" | "STARTS_WITH" | "ENDS_WITH",
  action: "SHOW" | "HIDE"
): CampaignTargetLocOperator => {
  switch (action) {
    case "SHOW": {
      return state;
    }
    case "HIDE": {
      return `NOT_${state}`;
    }
  }
};

export const generatePageTarget = (
  page: IPageUIData
): CampaignTargetType | null => {
  const validPageList = page.list.map((item) => {
    switch (item.type) {
      case "NOT_SET":
        return null;
      case "PRESET":
        if (!item.value || item.action === "NOT_SET") {
          return null;
        }
        if (item.useVisitCount) {
          if (!item.period || !item.count) {
            return null;
          }
        }

        return {
          type: item.type,
          value: item.value,
          action: item.action,
          params: item.params,
          useVisitCount: item.useVisitCount,
          period: item.period,
          count: item.count,
        };
      case "PATHNAME":
        if (
          !item.value ||
          !item.state ||
          item.state === "NOT_SET" ||
          item.action === "NOT_SET"
        ) {
          return null;
        }
        if (item.useVisitCount) {
          if (!item.period || !item.count) {
            return null;
          }
        }
        return {
          type: item.type,
          value: item.value,
          state: item.state,
          action: item.action,
          useVisitCount: item.useVisitCount,
          period: item.period,
          count: item.count,
        };
    }
  });

  if (validPageList.length === 0) {
    return null;
  }

  const or: CampaignTargetType = {
    type: "OR",
    value: [],
  };

  const and: CampaignTargetType = {
    type: "AND",
    value: [],
  };

  const result: CampaignTargetType = {
    type: "AND",
    value: [or, and],
  };

  validPageList.forEach((item) => {
    if (!item) {
      return;
    }

    let target: CampaignTargetType;

    switch (item.type) {
      case "PRESET":
        target =
          item.useVisitCount && item.period && item.count
            ? {
                type: "PAGE_VISIT_COUNT",
                value: item.value,
                params: item.params,
                operator: "EQUAL",
                count: item.count,
                period: item.period,
                id: shortUUID.generate(),
                visitCountOperator: "GREATER_OR_EQUAL",
              }
            : {
                type: "PAGE",
                value: item.value,
                params: item.params,
                operator: item.action === "SHOW" ? "EQUAL" : "NOT_EQUAL",
              };
        break;

      case "PATHNAME":
        target =
          item.useVisitCount && item.period && item.count
            ? {
                type: "URL_VISIT_COUNT",
                value: item.value,
                operator: generatePageTargetOperator(item.state, item.action),
                count: item.count,
                period: item.period,
                id: shortUUID.generate(),
                visitCountOperator: "GREATER_OR_EQUAL",
              }
            : {
                type: "URL",
                value: item.value,
                operator: generatePageTargetOperator(item.state, item.action),
              };
        break;
    }

    if (item.action === "SHOW") {
      or.value.push(target);
    } else if (item.action === "HIDE") {
      and.value.push(target);
    }
  });

  return result;
};
