import { Tooltip, TooltipProps } from "antd";

const V2Tooltip = ({ children, ...props }: TooltipProps) => {
  return (
    <Tooltip
      color="rgba(0, 0, 0, 0.88)"
      getPopupContainer={() => document.querySelector("main") ?? document.body}
      overlayStyle={{ maxWidth: "100%" }}
      arrowPointAtCenter
      overlayInnerStyle={{
        borderRadius: "3px",
        fontSize: 13,
        fontWeight: 400,
        lineHeight: "22px",
        wordBreak: "keep-all",
        padding: "6px 8px",
      }}
      zIndex={50}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default V2Tooltip;
