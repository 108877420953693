import rfdc from "rfdc";
import createDocumentNode from "../node/create/document";
import createFormNode from "../node/create/form";
import createFormChoiceNode from "../node/create/form-choice";
import createFormDividerNode from "../node/create/form-divider";
import createFormDropdownNode from "../node/create/form-dropdown";
import createFormImageChoiceNode from "../node/create/form-image-choice";
import createFormInputNode from "../node/create/form-input";
import createFormPrivacyPolicyNode from "../node/create/form-privacy-policy";
import createFormRatingScaleNode from "../node/create/form-rating-scale";
import createFormTextNode from "../node/create/form-text";
import createFormTextareaNode from "../node/create/form-textarea";
import createFrameNode from "../node/create/frame";
import createImageNode from "../node/create/image";
import createInstagramGalleryNode from "../node/create/instagram-gallery";
import createSwiperButtonGroupNode from "../node/create/swiper-button-group";
import createSwiperContainerNode from "../node/create/swiper-container";
import createSwiperPaginationNode from "../node/create/swiper-pagination";
import createTextNode from "../node/create/text";
import createTimerNode from "../node/create/timer";
import createVideoNode from "../node/create/video";
import {
  getDefaultBannerDocumentNode,
  getDefaultBannerGroupDocumentNode,
  getDefaultFormEventDocumentNode,
  getDefaultFormSurveyDocumentNode,
  getDefaultInstagramDocumentNode,
  getDefaultInstagramTemplate,
  getDefaultPopupDocumentNode,
  getDefaultPopupGroupDocumentNode,
  getDefaultPromotionDocumentNode,
} from "../node/data";
import type {
  FormItemNode,
  IAbsoluteNodePosition,
  IDocumentData,
  INodePosition,
  IPartialDocumentData,
  PartialNode,
  SceneNode,
} from "../node/type";
import type {
  Campaign,
  CampaignType,
  EmbeddedInstagram,
  EmbeddedInstagramData,
  IBannerCampaign,
  IBannerGroupCampaign,
  IEmbeddedInstagramCampaign,
  IEmbeddedInstagramCampaignV2,
  IFormEventCampaign,
  IFormSurveyCampaign,
  IMeta,
  IPartialBannerCampaign,
  IPartialBannerGroupCampaign,
  IPartialEmbeddedInstagramCampaignV1,
  IPartialEmbeddedInstagramCampaignV2,
  IPartialFormEventCampaign,
  IPartialFormSurveyCampaign,
  IPartialPopupCampaign,
  IPartialPopupGroupCampaign,
  IPartialPromotionCampaign,
  IPopupCampaign,
  IPopupGroupCampaign,
  PartialCampaign,
  PartialEmbeddedInstagramCampaign,
} from "../types/campaign";
import type {
  IInstagramDataSourceV2CustomData,
  IInstagramDataSourceV2Param,
  IPartialInstagramDataSourceV2CustomData,
  IPartialInstagramDataSourceV2Param,
} from "../types/data-source";
import { createDataMap } from "./data-source";
import { createPluginData } from "./pluginData";

const deepclone = rfdc();

export const createCampaign = (campaign: PartialCampaign): Campaign => {
  switch (campaign.type) {
    case "INSTAGRAM":
      return createEmbeddedInstagramCampaign(campaign);
    case "INSTAGRAM_V2":
      return createEmbeddedInstagramCampaignV2(campaign);
    case "POPUP":
      return createPopupCampaign(campaign);
    case "BANNER_GROUP":
      return createBannerGroupCampaign(campaign);
    case "BANNER":
      return createBannerCampaign(campaign);
    case "POPUP_GROUP":
      return createPopupGroupCampaign(campaign);
    case "FORM_EVENT":
      return createFormEventCampaign(campaign);
    case "PROMOTION":
      return createPromotionCampaign(campaign);
    case "FORM_SURVEY":
      return createFormSurveyCampaign(campaign);
  }
};

export const createSceneNode = (data: PartialNode<SceneNode>): SceneNode => {
  if (!data.type) {
    throw new Error("노드 타입이 존재하지 않습니다.");
  }

  switch (data.type) {
    case "FRAME": {
      const children = data?.children?.map((child) => createSceneNode(child));
      return createFrameNode({ ...data, children });
    }
    case "IMAGE":
      return createImageNode(data);
    case "TEXT":
      return createTextNode(data);
    case "INSTAGRAM_GALLERY":
      return createInstagramGalleryNode(data);
    case "SLOT":
      return { ...createFrameNode(), ...data, type: "SLOT" };
    case "SWIPER_PAGINATION":
      return createSwiperPaginationNode(data);
    case "SWIPER_BUTTON_GROUP":
      return createSwiperButtonGroupNode(data);
    case "SWIPER_CONTAINER":
      return createSwiperContainerNode(data);
    case "FORM":
      return createFormNode(data);
    case "FORM_INPUT":
      return createFormInputNode(data);
    case "FORM_CHOICE":
      return createFormChoiceNode(data);
    case "FORM_PRIVACY_POLICY":
      return createFormPrivacyPolicyNode(data);
    case "FORM_DIVIDER":
      return createFormDividerNode(data);
    case "FORM_IMAGE_CHOICE":
      return createFormImageChoiceNode(data);
    case "FORM_TEXT":
      return createFormTextNode(data);
    case "FORM_DROPDOWN":
      return createFormDropdownNode(data);
    case "FORM_RATING_SCALE":
      return createFormRatingScaleNode(data);
    case "FORM_TEXTAREA":
      return createFormTextareaNode(data);
    case "VIDEO":
      return createVideoNode(data);
    case "TIMER":
      return createTimerNode(data);
  }
};

export const createFormItemNode = (
  data: PartialNode<FormItemNode>
): FormItemNode => {
  if (!data.type) {
    throw new Error("노드 타입이 존재하지 않습니다.");
  }

  switch (data.type) {
    case "FORM_INPUT":
      return createFormInputNode(data);
    case "FORM_CHOICE":
      return createFormChoiceNode(data);
    case "FORM_PRIVACY_POLICY":
      return createFormPrivacyPolicyNode(data);
    case "FORM_DIVIDER":
      return createFormDividerNode(data);
    case "FORM_IMAGE_CHOICE":
      return createFormImageChoiceNode(data);
    case "FORM_TEXT":
      return createFormTextNode(data);
    case "FORM_DROPDOWN":
      return createFormDropdownNode(data);
    case "FORM_RATING_SCALE":
      return createFormRatingScaleNode(data);
    case "FORM_TEXTAREA":
      return createFormTextareaNode(data);
  }
};

export const createCampaignData = (
  type: CampaignType,
  campaignData: IPartialDocumentData = {
    documents: [],
    version: "0.0.1",
    dataMap: {},
    pluginData: {},
  },
  hasParent?: boolean
) => {
  switch (type) {
    case "INSTAGRAM_V2":
      return createEmbeddedInstagramCampaignData(campaignData);
    case "POPUP":
      return hasParent
        ? createChildPopupCampaignData(campaignData)
        : createPopupCampaignData(campaignData);
    case "BANNER_GROUP":
      return createBannerGroupCampaignData(campaignData);
    case "BANNER":
      return createBannerCampaignData(campaignData);
    case "POPUP_GROUP":
      return createPopupGroupCampaignData(campaignData);
    case "INSTAGRAM":
      return createPopupCampaignData(campaignData);
    case "FORM_EVENT":
      return createFormEventCampaignData(campaignData);
    case "PROMOTION":
      return createPromotionCampaignData(campaignData);
    case "FORM_SURVEY":
      return createFormSurveyCampaignData(campaignData);
  }
};

export const createPopupCampaignData = (
  campaignData: IPartialDocumentData = {
    documents: [],
    version: "0.0.2",
    dataMap: {},
    pluginData: {},
  }
): IDocumentData => {
  if (!campaignData.documents[0]) {
    return {
      ...campaignData,
      documents: [getDefaultPopupDocumentNode("MOBILE")],
      dataMap: createDataMap(),
      pluginData: createPluginData(),
    };
  }

  return {
    ...campaignData,
    documents: campaignData.documents.map((doc) => createDocumentNode(doc)),
    dataMap: createDataMap(campaignData.dataMap),
    pluginData: createPluginData(campaignData.pluginData),
  };
};

export const createChildPopupCampaignData = (
  campaignData: IPartialDocumentData = {
    documents: [],
    version: "0.0.1",
    dataMap: {},
    pluginData: {},
  }
): IDocumentData => {
  if (!campaignData.documents[0]) {
    return {
      ...campaignData,
      documents: [
        {
          ...getDefaultPopupDocumentNode("MOBILE"),
          width: { value: 100, unit: "%" },
          height: { value: 100, unit: "%" },
        },
        {
          ...getDefaultPopupDocumentNode("DESKTOP"),
          width: { value: 100, unit: "%" },
          height: { value: 100, unit: "%" },
        },
      ],
      dataMap: createDataMap(),
      pluginData: createPluginData(),
    };
  }

  return {
    ...campaignData,
    documents: campaignData.documents.map((doc) => ({
      ...createDocumentNode(doc),
      width: { value: 100, unit: "%" },
      height: { value: 100, unit: "%" },
    })),
    dataMap: createDataMap(campaignData.dataMap),
    pluginData: createPluginData(campaignData.pluginData),
  };
};

export const createBannerGroupCampaignData = (
  campaignData: IPartialDocumentData = {
    documents: [],
    version: "0.0.2",
    dataMap: {},
    pluginData: {},
  }
): IDocumentData => {
  if (!campaignData.documents[0]) {
    return {
      ...campaignData,
      documents: [getDefaultBannerGroupDocumentNode()],
      dataMap: createDataMap(),
      pluginData: createPluginData(),
    };
  }

  return {
    ...campaignData,
    documents: campaignData.documents.map((doc) => createDocumentNode(doc)),
    dataMap: createDataMap(campaignData.dataMap),
    pluginData: createPluginData(campaignData.pluginData),
  };
};

export const createBannerCampaignData = (
  campaignData: IPartialDocumentData = {
    documents: [],
    version: "0.0.1",
    dataMap: {},
    pluginData: {},
  }
): IDocumentData => {
  if (!campaignData.documents[0]) {
    return {
      ...campaignData,
      documents: [getDefaultBannerDocumentNode("ALL")],
      dataMap: createDataMap(),
      pluginData: createPluginData(),
    };
  }

  return {
    ...campaignData,
    documents: campaignData.documents.map((doc) => ({
      ...createDocumentNode(doc),
    })),
    dataMap: createDataMap(campaignData.dataMap),
    pluginData: createPluginData(campaignData.pluginData),
  };
};

export const createPopupGroupCampaignData = (
  campaignData: IPartialDocumentData = {
    documents: [],
    version: "0.0.2",
    dataMap: {},
    pluginData: {},
  }
): IDocumentData => {
  if (!campaignData.documents[0]) {
    return {
      ...campaignData,
      documents: [getDefaultPopupGroupDocumentNode()],
      dataMap: createDataMap(),
      pluginData: createPluginData(),
    };
  }

  return {
    ...campaignData,
    documents: campaignData.documents.map((doc) => createDocumentNode(doc)),
    dataMap: createDataMap(campaignData.dataMap),
    pluginData: createPluginData(campaignData.pluginData),
  };
};

export const createFormEventCampaignData = (
  campaignData: IPartialDocumentData = {
    documents: [],
    version: "0.0.1",
    dataMap: {},
    pluginData: {},
  }
): IDocumentData => {
  if (!campaignData.documents[0]) {
    return {
      ...campaignData,
      documents: [getDefaultFormEventDocumentNode()],
      dataMap: createDataMap(),
      pluginData: createPluginData(),
    };
  }

  return {
    ...campaignData,
    documents: campaignData.documents.map((doc) => ({
      ...createDocumentNode(doc),
      device: "ALL",
    })),
    dataMap: createDataMap(campaignData.dataMap),
    pluginData: createPluginData(campaignData.pluginData),
  };
};

export const createFormSurveyCampaignData = (
  campaignData: IPartialDocumentData = {
    documents: [],
    version: "0.0.1",
    dataMap: {},
    pluginData: {},
  }
): IDocumentData => {
  if (!campaignData.documents[0]) {
    return {
      ...campaignData,
      documents: [getDefaultFormSurveyDocumentNode()],
      dataMap: createDataMap(),
      pluginData: createPluginData(),
    };
  }

  return {
    ...campaignData,
    documents: campaignData.documents.map((doc) => ({
      ...createDocumentNode(doc),
      device: "ALL",
    })),
    dataMap: createDataMap(campaignData.dataMap),
    pluginData: createPluginData(campaignData.pluginData),
  };
};

export const createPromotionCampaignData = (
  campaignData: IPartialDocumentData = {
    documents: [],
    version: "0.0.1",
    dataMap: {},
    pluginData: {},
  }
): IDocumentData => {
  if (!campaignData.documents[0]) {
    return {
      ...campaignData,
      documents: [getDefaultPromotionDocumentNode()],
      dataMap: createDataMap(),
      pluginData: createPluginData(),
    };
  }

  return {
    ...campaignData,
    documents: campaignData.documents.map((doc) => ({
      ...createDocumentNode(doc),
      device: "ALL",
    })),
    dataMap: createDataMap(campaignData.dataMap),
    pluginData: createPluginData(campaignData.pluginData),
  };
};

export const createEmbeddedInstagramCampaignData = (
  campaignData: IPartialDocumentData = {
    documents: [],
    version: "0.0.1",
    dataMap: {},
    pluginData: {},
  }
): IDocumentData => {
  if (!campaignData.documents[0]) {
    return {
      ...campaignData,
      documents: [getDefaultInstagramDocumentNode()],
      dataMap: createDataMap(campaignData.dataMap),
      pluginData: createPluginData(campaignData.pluginData),
    };
  }

  return {
    ...campaignData,
    documents: campaignData.documents.map((doc) => ({
      ...getDefaultInstagramDocumentNode(),
      ...createDocumentNode({ ...doc, device: "ALL" }),
    })),
    dataMap: createDataMap(campaignData.dataMap),
    pluginData: createPluginData(campaignData.pluginData),
  };
};

export const convertToInstagramCampaignV2 = (
  campaign?: IPartialEmbeddedInstagramCampaignV1
): IEmbeddedInstagramCampaignV2 => {
  const campaignId = campaign?.id;
  if (!campaignId) {
    throw new Error("invalid campaign");
  }

  const defaultInstagramDocumentNode = getDefaultInstagramTemplate();

  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    isActive: true,
    useGoogleAnalytics: false,
    ...campaign,
    preview: campaign.data?.posts?.[0]?.previewImageUrl
      ? {
          documentImages: [
            {
              id: defaultInstagramDocumentNode.id,
              device: "ALL",
              url: campaign.data?.posts?.[0]?.previewImageUrl ?? "",
            },
          ],
        }
      : {},
    data: createEmbeddedInstagramCampaignData({
      version: "0.0.1",
      documents: campaign.data?.source ? [defaultInstagramDocumentNode] : [],
    }),
    type: "INSTAGRAM_V2",
  };
};

export const isInstagramCampaignV2 = (
  campaign: PartialEmbeddedInstagramCampaign
): campaign is IPartialEmbeddedInstagramCampaignV2 =>
  campaign.type === "INSTAGRAM_V2";

export const createEmbeddedInstagramCampaignV2 = (
  campaign: PartialEmbeddedInstagramCampaign = { type: "INSTAGRAM" }
): IEmbeddedInstagramCampaignV2 => {
  if (!isInstagramCampaignV2(campaign)) {
    return convertToInstagramCampaignV2(campaign);
  }

  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    preview: {},
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    isActive: true,
    useGoogleAnalytics: false,
    ...campaign,
    type: "INSTAGRAM_V2",
    data: createEmbeddedInstagramCampaignData(campaign.data),
  };
};

export const createEmbeddedInstagramCampaign = (
  campaign: IPartialEmbeddedInstagramCampaignV1 = { type: "INSTAGRAM" }
): IEmbeddedInstagramCampaign => {
  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    preview: {},
    isActive: false,
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    useGoogleAnalytics: false,
    ...campaign,
    type: campaign.type,
    data: createEmbeddedInstagramBeta(campaign.data),
  };
};

export const createPopupCampaign = (
  campaign: IPartialPopupCampaign = { type: "POPUP" }
): IPopupCampaign => {
  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    preview: {},
    isActive: false,
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    useGoogleAnalytics: false,
    ...campaign,
    type: "POPUP",
    data: createPopupCampaignData(campaign.data),
  };
};

export const createChildPopupCampaign = (
  campaign: IPartialPopupCampaign = { type: "POPUP" }
): IPopupCampaign => {
  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    preview: {},
    isActive: false,
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    useGoogleAnalytics: false,
    ...campaign,
    type: "POPUP",
    data: createChildPopupCampaignData(campaign.data),
  };
};

export const createPopupGroupCampaign = (
  campaign: IPartialPopupGroupCampaign = { type: "POPUP_GROUP" }
): IPopupGroupCampaign => {
  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    preview: {},
    isActive: false,
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    useGoogleAnalytics: false,
    ...campaign,
    type: "POPUP_GROUP",
    data: createPopupGroupCampaignData(campaign.data),
    meta: createPopupGroupMeta(campaign.meta),
  };
};

export const createBannerGroupCampaign = (
  campaign: IPartialBannerGroupCampaign = { type: "BANNER_GROUP" }
): IBannerGroupCampaign => {
  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    preview: {},
    isActive: false,
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    useGoogleAnalytics: false,
    ...campaign,
    type: "BANNER_GROUP",
    data: createBannerGroupCampaignData(campaign.data),
    meta: createBannerGroupMeta(campaign.meta),
  };
};

export const createBannerCampaign = (
  campaign: IPartialBannerCampaign = { type: "BANNER" }
): IBannerCampaign => {
  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    preview: {},
    isActive: false,
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    useGoogleAnalytics: false,
    ...campaign,
    type: "BANNER",
    data: createBannerCampaignData(campaign.data),
  };
};

export const createFormEventCampaign = (
  campaign: IPartialFormEventCampaign = { type: "FORM_EVENT" }
): IFormEventCampaign => {
  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    preview: {},
    isActive: false,
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    useGoogleAnalytics: false,
    ...campaign,
    type: "FORM_EVENT",
    data: createFormEventCampaignData(campaign.data),
  };
};

export const createFormSurveyCampaign = (
  campaign: IPartialFormSurveyCampaign = { type: "FORM_SURVEY" }
): IFormSurveyCampaign => {
  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    preview: {},
    isActive: false,
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    useGoogleAnalytics: false,
    ...campaign,
    type: "FORM_SURVEY",
    data: createFormSurveyCampaignData(campaign.data),
  };
};

export const createPromotionCampaign = (
  campaign: IPartialPromotionCampaign = { type: "PROMOTION" }
): Campaign => {
  return {
    id: "",
    publicId: "",
    siteId: "",
    name: "",
    description: "",
    schedule: {
      type: "OR",
      value: [],
    },
    target: {
      type: "OR",
      value: [],
    },
    display: {
      type: "OR",
      value: [],
    },
    preview: {},
    isActive: false,
    hasDraft: false,
    createdAt: "",
    updatedAt: "",
    useGoogleAnalytics: false,
    ...campaign,
    type: "PROMOTION",
    data: createPromotionCampaignData(campaign.data),
  };
};

/** @deprecated 직접 필드에 create... 함수를 넣는 식으로 진행 */
export const createRecentVersionData = (data?: PartialNode<SceneNode>) => {
  const clonedData = deepclone(data);
  if (clonedData?.position?.type === "ABSOLUTE") {
    clonedData.position = createAbsolutePosition(clonedData?.position);
  }

  return clonedData;
};

export const createPosition = (
  position?: Partial<INodePosition>
): INodePosition => {
  switch (position?.type) {
    case "ABSOLUTE":
      return createAbsolutePosition(position);
    case "RELATIVE":
    default:
      return {
        type: "RELATIVE",
      };
  }
};

export const createAbsolutePosition = (
  position?: Partial<IAbsoluteNodePosition>
): IAbsoluteNodePosition => {
  return {
    type: "ABSOLUTE",
    constraintX: "LEFT",
    constraintY: "TOP",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    centerX: 0,
    centerY: 0,
    scale: {
      left: 0,
      width: 1,
      right: 1,
      top: 0,
      height: 1,
      bottom: 0,
    },
    ...position,
  };
};

export const createEmbeddedInstagramBeta = (
  args: EmbeddedInstagramData = { type: "INSTAGRAM" }
): EmbeddedInstagram => {
  return {
    filter: {},
    layout: {
      columns: 5,
      rows: 2,
    },
    posts: [],
    ...args,
    version: "0.0.1-beta",
  };
};

export const createInstagramDataSourceV2Param = (
  sourceParam: IPartialInstagramDataSourceV2Param
): IInstagramDataSourceV2Param => {
  return {
    filter: sourceParam.filter ?? {},
    source: sourceParam.source,
  };
};

export const createInstagramDataSourceV2CustomData = (
  customData?: IPartialInstagramDataSourceV2CustomData
): IInstagramDataSourceV2CustomData => {
  return {
    posts: customData?.posts ?? [],
  };
};

export const createHttpDataSourceCustomData = (
  customData?: unknown
): unknown => {
  if (!customData) {
    throw new Error("customData is required");
  }
  return deepclone(customData);
};

export const createBannerGroupMeta = (campaignMeta?: IMeta): IMeta => {
  return {
    area: {
      shape: campaignMeta?.area?.shape ?? { type: "THIN" },
      slide: campaignMeta?.area?.slide ?? { type: "NONE" },
    },
    slot: campaignMeta?.slot ?? [{ device: "ALL", slot: 5 }],
    ...campaignMeta,
  };
};

export const createPopupGroupMeta = (campaignMeta?: IMeta): IMeta => {
  return {
    area: {
      shape: campaignMeta?.area?.shape ?? { type: "AUTO" },
      placement: campaignMeta?.area?.placement ?? { type: "DEFAULT" },
      slide: campaignMeta?.area?.slide ?? { type: "A" },
      button: campaignMeta?.area?.button ?? { type: "A" },
    },
    slot: campaignMeta?.slot ?? [{ device: "ALL", slot: 5 }],
    ...campaignMeta,
  };
};
