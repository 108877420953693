import Modal from "@/components/common/ui/modal";
import { useOrgAndsiteContext } from "@/context";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import V2CindyIcon from "../icon/v2-cindy-icon";
import V2CounselingIcon from "../icon/v2-counseling-icon";
import V2FredIcon from "../icon/v2-fred-icon";
import V2JoyIcon from "../icon/v2-joy-icon";
import V2MichaelIcon from "../icon/v2-michael-icon";
import V2TonyIcon from "../icon/v2-tony-icon";
import V2XIcon from "../icon/v2-x-icon";
import OnboardingScript from "../team/onboarding/onboarding-script";
import ApplicationCounselingComplete from "./application-counseling-complete";
import ApplicationCounselingGuide from "./application-counseling-guide";
import ReservationCounselingGuide from "./reservation-counseling";

export interface ISupporter {
  name: string;
  email: string;
  icon: JSX.Element;
}

const supporterList: ISupporter[] = [
  {
    name: "Cindy",
    email: "support@purple.io",
    icon: <V2CindyIcon />,
  },
  {
    name: "Tony",
    email: "support@purple.io",
    icon: <V2TonyIcon />,
  },
  {
    name: "Fred",
    email: "support@purple.io",
    icon: <V2FredIcon />,
  },
  {
    name: "Michael",
    email: "support@purple.io",
    icon: <V2MichaelIcon />,
  },
  {
    name: "Joy",
    email: "support@purple.io",
    icon: <V2JoyIcon />,
  },
];

const tooltipDismissalKey = "counselingTooltipDismissal";
const ANIMATION_DURATION = "duration-300";
const EXCLUDE_PATHS = ["/editor"];

const ApplicationCounseling = () => {
  const [supporter, setSupporter] = useState<ISupporter | null>();
  const [openModal, setOpenModal] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openCommonScriptGuideModal, setOpenCommonScriptGuideModal] =
    useState(false);
  const [step, setStep] = useState(1);
  const [schedule, setSchedule] = useState<number>(0);
  const router = useRouter();

  const organizationContext = useOrgAndsiteContext();
  const sitesContext = useOrgAndsiteContext();

  const { currentOrganization } = organizationContext;
  const { currentSite } = sitesContext;

  const isOpenTooltip = useCallback(() => {
    // localstorage 참조해서 툴팁이 노출가능한지 체크
    const tooltipDismissal = localStorage.getItem(tooltipDismissalKey);
    if (tooltipDismissal) {
      const oneWeekLaterTimestamp = Number(tooltipDismissal);
      if (new Date().getTime() < oneWeekLaterTimestamp) {
        return false;
      }
    }

    // 에디터 페이지에서는 툴팁 숨김
    if (EXCLUDE_PATHS.some((path) => router.pathname.includes(path))) {
      setOpenTooltip(false);
      return false;
    } else {
      setOpenTooltip(true);
    }

    return true;
  }, [router.pathname]);

  useEffect(() => {
    setSupporter(supporterList[Math.floor(Math.random() * 5)]);
    if (!window.cnb) {
      window.cnb = {};
      window.cnb.openCounseling = function () {
        setOpenModal(true);
      };
    }
  }, []);

  useEffect(() => {
    if (isOpenTooltip()) {
      setOpenTooltip(!openModal);
    }

    // 마지막 스텝에서 모달 닫으면 다시 처음부터 시작
    if ((!openModal && step === 2 && !schedule) || (!openModal && step === 3)) {
      setTimeout(() => {
        setStep(1);
        setSchedule(0);
        setSupporter(supporterList[Math.floor(Math.random() * 5)]);
      }, 300);
    }
  }, [openModal, step, schedule, isOpenTooltip]);

  const handleShowCommonScriptGuideClick = () => {
    setOpenCommonScriptGuideModal(true);
  };

  const handleHideCommonScriptGuideModal = () => {
    setOpenCommonScriptGuideModal(false);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.classList.add("scale-90");
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.classList.remove("scale-90");
    setOpenModal(!openModal);
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLElement>) => {
    e.currentTarget.classList.remove("scale-90");
  };

  const handleTooltipClick = () => {
    // 일주일간 노출하지 않기
    const oneWeekLaterTimestamp =
      new Date().getTime() + 7 * 24 * 60 * 60 * 1000;
    localStorage.setItem(tooltipDismissalKey, oneWeekLaterTimestamp.toString());
    setOpenTooltip(false);
  };

  if (!currentOrganization || !currentSite) {
    return null;
  }

  const ApplicationCounselingModal = () => {
    return (
      <div
        className="v2override rounded-xl"
        style={{
          boxShadow:
            "0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)",
        }}
      >
        <div className="max-w-full max-h-full">
          <div className="relative overflow-x-auto bg-white shadow rounded-xl text-cb-gray-900">
            <div className="flex items-center justify-between px-8 pt-8">
              <h3 className="text-lg font-bold">
                {step === 1
                  ? "전담 상담사가 도와드려요. 맡겨만주세요!"
                  : step === 2
                  ? "연락 가능한 시간을 선택해 주세요."
                  : "상담 예약이 완료되었습니다."}
              </h3>
            </div>
            <div className="p-8 w-104">
              {step === 1 ? (
                <ApplicationCounselingGuide
                  supporter={supporter}
                  onChangeStep={setStep}
                  handleShowCommonScriptGuideClick={
                    handleShowCommonScriptGuideClick
                  }
                />
              ) : step === 2 ? (
                <ReservationCounselingGuide
                  onChangeStep={setStep}
                  schedule={schedule}
                  setSchedule={setSchedule}
                  publicId={currentSite?.publicId || ""}
                />
              ) : (
                <ApplicationCounselingComplete
                  supporter={supporter}
                  schedule={schedule}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="fixed bottom-0 right-0 z-30 printing-hidden">
        <div
          className={twMerge(
            `absolute bottom-[145px] right-4 opacity-0 transition-all ${ANIMATION_DURATION} invisible`,
            openModal && "block bottom-[165px] opacity-100 visible"
          )}
        >
          <ApplicationCounselingModal />
        </div>
        {openTooltip && (
          <div className="fixed bottom-[170px] right-4">
            <div className="relative flex items-center gap-2 py-2 px-4 bg-black opacity-[0.88] rounded-[32px] shadow-lg z-50">
              <p className="text-xs font-bold text-white">
                {step === 2 && schedule
                  ? "언제든지 상담 예약으로 돌아갈 수 있습니다."
                  : "코드앤버터 활용법, 전담 상담사가 도와드려요."}
              </p>
              <V2XIcon
                className="cursor-pointer"
                onClick={handleTooltipClick}
              />
            </div>
            <div className="absolute w-0 h-0 border-t-[10px] border-x-[6px] border-x-transparent border-t-black border-opacity-[0.88] -bottom-[10px] right-[30px]" />
          </div>
        )}
        <button
          className="absolute transition-transform duration-75 ease-out transform shadow-md cursor-pointer rounded-3xl bg-neut-20 w-14 h-14 right-[23px] bottom-24"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          data-gtm-id="consulting_floating_btn"
        >
          <div className="relative flex w-full h-full center-center">
            <V2XIcon
              width={26}
              height={28}
              fill="#fff"
              className={twMerge(
                `absolute opacity-0 transition-opacity ${ANIMATION_DURATION}`,
                openModal && "opacity-100"
              )}
            />
            <V2CounselingIcon
              className={twMerge(
                `absolute opacity-100 transition-opacity ${ANIMATION_DURATION} select-none`,
                openModal && "opacity-0"
              )}
            />
          </div>
        </button>
      </div>
      <Modal
        title="공통 스크립트 설치"
        open={openCommonScriptGuideModal}
        hideFooter
        hideCloseButton
      >
        <OnboardingScript
          prefixGtmId="consulting"
          publicId={currentSite?.publicId || ""}
          siteType={currentSite?.siteType}
          onFinish={handleHideCommonScriptGuideModal}
        />
      </Modal>
    </>
  );
};

export default React.memo(ApplicationCounseling);
